import React, { useState, useEffect, useRef }  from 'react';
import './App.css';
import Modal from 'react-bootstrap/Modal'
import tokenPrime from '../../data/token'
import Tipologia from '../../components/Tipologia'
import Token from '../estrutura-token/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faAngleDoubleRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import {trackPromise} from 'react-promise-tracker';
import {celularMask, cpfMask, toUppercase} from '../../components/InputTextMask/'

import Swal from 'sweetalert2';

import api from '../../data/api.js'
import SelectEmpreendimento from '../../components/selectEmpreendimentos/';

import primemodal from '../../assets/primemodal.png'
import primeModalNovaCompra from '../../assets/primemodalnvcompra.png'

//import api2 from '../../data/message.json'

export default function App(){
  //const history = useHistory();

  const [ show, setShow ] = useState(true) 
  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [exibeCpf, setExibeCpf] = useState(true)
  const [email, setEmail] = useState('')
  const [celular, setCelular] = useState('')
  const [exibeNovaCompra, setExibeNovaCompra] = useState(false)
 
  const [erroNome, setErroNome] = useState('')
  const [erroCpf, setErroCpf] = useState('')
  const [erroEmail, setErroEmail] = useState('')
  const [erroCelular, setErroCelular] = useState('')
  const [checkAceite, setCheckAceite] = useState(false)
  const [erroAceite, setErroAceite] = useState('')
  const [habilitaButtonAvancar, sethabilitaButtonAvancar] = useState(false)
  const [cpfValido, setcpfValido] = useState(false)
  const [escolheToken, setescolheToken] = useState(false)

  const [processos, setProcessos] = useState([])

  const childRef = useRef()

  let headers = { headers: {
    'Authorization': tokenPrime
  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
  // useEffect(async () => {
  //   const response = await fetch('API')

  //   const data = await response.json()

  // }, [])

  function validaForm(){
      if(nome === ''){
          setErroNome('Obrigatório *')
      }else{
          setErroNome('')
      }

      if(celular === ''){
          setErroCelular('Obrigatório *')
      }else{
          setErroCelular('')
      }

      if(!checkAceite){
          setErroAceite('Aceite os termos de uso *')
      }else{
          setErroAceite('')
      }

      if(erroCpf === '' && nome !== '' && email !== '' && erroEmail === '' && celular !== '' && checkAceite){
        return true
      }else{
        return false
      }
  }

  function possueCadastro(e){
    e.preventDefault()

    if(email !== "" && email.includes('@')){

      Swal.fire(
        'Em desenvolvimento',
        '',
        'success'
      )
      setErroEmail('')
    }else{
        setErroEmail('* Digite um email válido já cadastrado para prosseguir')
        Swal.fire(
          'Ocorreu algum erro',
          '',
          'error'
        )
    }


  }

  function limpaMascaraCpf(cpf){
    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');

    //console.log("limpa", cpf)
    setCpf(cpf)
  }

  function validacaoCpf(cpf){

   // console.log("cpf", cpf)

    if (cpf.length == 14) {
      cpf.trim();
     
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;
      
      for (var i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
              aux = true;   
          }
      } 
      
      if (aux == false) {
          return false; 
      } 
      
      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p; 
      } 
      
      v1 = ((v1 * 10) % 11);
      
      if (v1 == 10) {
          v1 = 0; 
      }
      
      if (v1 != cpf[9]) {
          return false; 
      } 
      
      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p; 
      } 
      
      v2 = ((v2 * 10) % 11);
      
      if (v2 == 10) {
          v2 = 0; 
      }
      
      if (v2 != cpf[10]) {
          return false; 
      } else {   
          setcpfValido(true)
          return true; 
      }
    }else if(cpf.length == 11){
      var v1 = 0;
      var v2 = 0;
      var aux = false;
      
      for (var i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
              aux = true;   
          }
      } 
      
      if (aux == false) {
          return false; 
      } 
      
      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p; 
      } 
      
      v1 = ((v1 * 10) % 11);
      
      if (v1 == 10) {
          v1 = 0; 
      }
      
      if (v1 != cpf[9]) {
          return false; 
      } 
      
      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p; 
      } 
      
      v2 = ((v2 * 10) % 11);
      
      if (v2 == 10) {
          v2 = 0; 
      }
      
      if (v2 != cpf[10]) {
          return false; 
      } else {   
          setcpfValido(true)
          return true; 
      }
    }else {
      setcpfValido(false)
      return false;
    }

  }

  function verificaCpfCadastrado(e){

    let cpfNumero = {cpf: ''};
    cpfNumero.cpf = e
    //let resposta;

    trackPromise(api.post('/processos-cpf', cpfNumero, headers).then(response => {
      //console.log("meu retorno response", response, response.data)
      
      if(response.data.length > 0){
        setProcessos(response.data)
        setExibeNovaCompra(false)
        setExibeCpf(false)
      }else{
        setExibeNovaCompra(true)
        setExibeCpf(false)
      }
      
    }) .catch(error => {
      Swal.fire(
        'Erro ao acessar o servidor, tente novamente mais tarde!',
        'Verique sua conexão com a internet',
        'error'
      )
    }))

  }

  function validaEmail(){
    let reg = /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i;
    
      if(!reg.test(email) && email.length > 0)
      setErroEmail('Email Inválido')  
      else if(!reg.test(email) && email.length < 0){
        setErroEmail('informe um e-mail')
      }else{
        setErroEmail('')
      }
  }

  function geraCodigoEmail(){
  
    let cpfNumero = {cpf: cpf};

   // console.log("gera cod", cpfNumero)
    
   trackPromise(api.post('/mail', cpfNumero, headers).then(response => {
     // console.log("meu retorno response", response)
      setescolheToken(true)
      Swal.fire({
        position: 'right',
        icon: 'success',
        title: 'Código de acesso enviado!',
        showConfirmButton: false,
        timer: 1500
      })
      
    }) .catch(error => {
      Swal.fire(
        'Não foi possível gerar código, tente novamente mais tarde',
        '',
        'error'
      )
    }))
    
  }

  function salvaDados(e){

      e.preventDefault()   

      let valida = false;

      valida = childRef.current.refValidaForm()
      
      if(validaForm() && valida){  

        let cpfsemmascara = cpf.replace(/\./g, '');
        cpfsemmascara = cpfsemmascara.replace('-', '');
        
        const data = {
          nome: nome,
          cpf: cpfsemmascara,
          email: email,
          celular: celular,
          uf: localStorage.getItem('uf'),
          cidade: localStorage.getItem('cidade'),
          nomeempreendimento: localStorage.getItem('empreendimento'),
          empreendimento: localStorage.getItem('empreendimento_index'),
          tipo:  localStorage.getItem('tipologia_index'),
          nometipo: localStorage.getItem('tipologia')
        }
        
        localStorage.clear();
        
        localStorage.setItem('nome', data.nome)
        localStorage.setItem('cpf', data.cpf)
        localStorage.setItem('email', data.email)
        localStorage.setItem('celular', data.celular)
        localStorage.setItem('uf', data.uf)
        localStorage.setItem('cidade', data.cidade)
        localStorage.setItem('empreendimento_index', data.empreendimento)
        localStorage.setItem('tipologia_index', data.tipo)
        localStorage.setItem('tipologia', data.nometipo)
        localStorage.setItem('empreendimento', data.nomeempreendimento)

        let a = document.createElement('a')
        
        a.setAttribute('href', '/iniciar');
        
        a.setAttribute('target', '_blank');
        
        document.body.appendChild(a);
        a.click()
        
        //history.push('/iniciar')
      }
  }
  
  return (
    <div id="app" className="App">
    
        <Link to="/iniciar" target="_blank"></Link> 

        <Modal className="efeitoAbreModal" 
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              backdrop="static"
              size="lg"
              aria-labelledby="example-custom-modal-styling-title">
              <div className="contentModal">
                    {
                      exibeCpf ?
                      <img className="img-responsive" src={primemodal} alt="imagem familia feliz prime residencial" />   
                      : 
                      !exibeCpf && exibeNovaCompra ?
                      <div className="divimgModal">
                        
                      </div>
                      // <img className="img-responsive imgModal" src={primeModalNovaCompra} alt="imagem familia feliz prime residencial" />
                      :   <div className="divimgModalProcessoExiste">
                        
                          </div>
                      
                      // <img className="img-responsive imgModal" src={primemodal} alt="imagem familia feliz prime residencial" />
                    }
                  <div className="divprincipalModal">
                    <Modal.Header closeButton>
                    <Modal.Title
                      id="example-custom-modal-styling-title">  
                        Compre seu imóvel online! 
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {
                      exibeCpf  ? 
                      <h5 className="text-center p-modal">
                        Vamos começar? Primeiro nos informe seu CPF:
                      </h5> :
                      
                      <>
                      {
                        exibeNovaCompra ?
                        <h6 className="text-center p-modal">
                        Agora informe os dados abaixo para iniciar o processo de compra:
                        </h6> :
                        <>  
                          {
                            !escolheToken ?
                            <>
                              <h6 className="text-center p-modal">
                                Iremos te enviar um token de acesso, se deseja recebê-lo agora clique no botão abaixo:
                              </h6> 
                              <div className="row">
                                <div className="col-md-5">
                                </div>
                                <div className="col-md-6">
                                  <button style={{float: 'left', marginRight: '20px'}} className="btn avancar"
                                    onClick={e => {
                                      geraCodigoEmail()
                                    }}>
                                    Enviar E-mail
                                  </button>
                                  {/* <p style={{float: 'left'}}>ou</p>
                                  <button style={{cursor: 'no-drop', marginLeft: '20px'}} className="btn avancar"
                                    disabled
                                    //onClick={e => setescolheToken(true)}
                                    >
                                    CEL. SMS
                                  </button> */}
                                </div> 
                              </div>
                              <br/>
                            </> :
                            <>
                              <h6 className="p-modal text-center">
                                <span className="text-center" style={{fontWeight: 'bold', fontSize: '11px'}}>
                                  <FontAwesomeIcon style={{marginRight: '5px'}} icon={faInfoCircle} color="#ff7600" />  
                                </span>
                                Verificamos que há um cadastro para o CPF informado. Enviamos um código de acesso para  seu e-mail,
                                verifique na caixa de entrada/spam e informe-o abaixo:
                              </h6> 
                              <br/>
                            </>
                          }
                        </>
                      }
                      </>
                    }
                      <form className="meuFormModal" onSubmit={salvaDados}>
                      {
                        exibeCpf ?

                        <div className="form-group row">
                            <div className="col-md-3"></div>
                            <div className="col-xs-12 col-md-4">
                              <br/>
                              {/* <label className="text-center">* Cpf: </label>  */}
                                <input className="form-control float-left" 
                                      placeholder="*Informe seu CPF" 
                                      type="text"
                                      required
                                      value={cpfMask(cpf)}
                                      maxLength={14}
                                      onChange={e => {
                                        limpaMascaraCpf(e.target.value)
                                        validacaoCpf(e.target.value)
                                      }}
                                      onBlur={e => {
                                        if(validacaoCpf(e.target.value)){
                                          setErroCpf('')
                                        }else{
                                          if(e.target.value > 1 && e.target.value.length < 11){
                                            setErroCpf('CPF inválido')
                                          }
                                          if(e.target.value < 1){
                                            setErroCpf('')
                                          }                              
                                        }
                                      }}
                                />  
                                <p className="paragrafoErro fonteErro"> {erroCpf} </p>  
                            </div>

                            <div className="col-md-4">
                              <br/>
                              <button 
                                //onClick={ExibeStep} 
                                className="btn avancar"
                                style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if(cpfValido)
                                      verificaCpfCadastrado(cpf)
                                    else{
                                      if(cpf.length >= 1){
                                        setErroCpf('CPF Inválido')
                                      }else{
                                        setErroCpf('')
                                      }
                                    }
                                  }
                                }>
                                <span>
                                    Avançar
                                    <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faAngleDoubleRight} color="white">
                                    </FontAwesomeIcon>
                                </span> 
                              </button>           
                            </div>  

                      
                        </div> : <></>
                      }
                      {
                        exibeNovaCompra ? 
                        <>
                          <hr/>
                          <h6 className="text-center p-modal">
                          SEUS DADOS:
                          </h6>
                          <div className="form-group row">
                              <div className="col-xs-12 col-md-12 col-lg-4">
                                <label className="col-form-label text-center">CPF:<span className="help-text">*</span></label> 
                                <input readOnly className="form-control" 
                                      placeholder="*Informe seu CPF" 
                                      type="text"
                                      required
                                      value={cpfMask(cpf)}
                                      maxLength={14}
                                />  
                              </div>            
                              <div className="col-xs-12 col-md-12 col-lg-8">
                                <label className="col-form-label">Nome Completo: <span className="help-text">*</span> </label> 
                                <input className="form-control uppercase" 
                                      //placeholder="Digite seu nome" 
                                      type="text"
                                      value={nome}
                                      onChange={e => 
                                        setNome(toUppercase(e.target.value))
                                      }
                                        />     
                                <p className="paragrafoErro fonteErro"> {erroNome} </p>  
                              </div>               
                          </div>
                          <div className="form-group row">
                              <div className="col-xs-12 col-md-12 col-lg-4">
                                <label className="col-form-label">Celular: <span className="help-text">*</span></label>
                                <input className="form-control" 
                                      //placeholder="Ex.: (00) 00000-0000" 
                                      type="text"
                                      maxLength="15"
                                      value={celularMask(celular)}
                                      onBlur={validaForm}
                                      onChange={
                                        e =>{
                                          let celsemmascara = e.target.value.replace('(', '');
                                          celsemmascara = celsemmascara.replace(')', '');
                                          celsemmascara = celsemmascara.replace('-', '');
                                          celsemmascara = celsemmascara.replace(/\s/g, '');
                                          celsemmascara = celsemmascara.trim();
                                          setCelular(celsemmascara)
                                        }
                                        }
                                      />
                
                                <p className="paragrafoErro fonteErro"> {erroCelular} </p>
                              </div>
                              <div className="form-group col-xs-12 col-md-12 col-lg-8">
                                <label className="col-form-label">E-mail: <span className="help-text">*</span></label>
                                <input className="form-control lowercase" 
                                      placeholder="Seu melhor e-mail" 
                                      type="email"
                                      required
                                      value={email}
                                      onChange={e => {
                                        
                                        setEmail(e.target.value)}
                                      } 
                                      onBlur={validaEmail}
                                      />
                                <p className="paragrafoErro fonteErro"> {erroEmail} </p>  
                              </div>
                          </div>
                          <hr/>
                          <h6 className="text-center p-modal">
                          REGIÃO DE INTERESSE:
                          </h6>
                          
                          <SelectEmpreendimento ref={childRef} />                      
                        </> : 
                          <>
                            {
                              !exibeNovaCompra && !exibeCpf && escolheToken ?
                              <Token cpf={cpf} processos={processos} /> : <></>
                            }
                          </>
                          
                        }
                      <Modal.Footer>
                            
                          {/* <button onClick={e => { possueCadastro(e)}} className="btn btn-warning btnPossouCadastro">
                              JÁ POSSUO CADASTRO
                              <span>
                                <FontAwesomeIcon style={{ marginLeft: 10 }}  
                                                icon={faSignInAlt} 
                                                color="white">
                                </FontAwesomeIcon>
                              </span> 
                          </button> */}

                          {
                            !exibeCpf && exibeNovaCompra ? 
                            <>
                              <div style={{width: '24rem'}}>
                                
                                  <input style={{margin: '6px 6px 0px 0px'}} className="float-left" type="checkbox" onChange={() => setCheckAceite(!checkAceite)} />
                                  <p className="p-modal">
                                    Aceito os termos da construtora:
                                    <a className="uppercase margemEsqueda5 termoAceite"
                                      href="https://homologacao9.dommusbeta.com.br/termo_aceite.pdf" 
                                      target="_blank">
                                      termo de aceite
                                    </a>
                                  </p>
                                  <p className="paragrafoErro fonteErro"> {erroAceite} </p>  
                              </div>
                                  <button type="submit" className="iniciar float-right">
                                    INICIAR
                                    <span>
                                      <FontAwesomeIcon style={{ marginLeft: 10 }} 
                                                        icon={faAngleDoubleRight} 
                                                        color="white">
                                      </FontAwesomeIcon>
                                    </span> 
                                  </button>
                  
                            </> : 
                            <>
                            </>
                          }
                      
                        {/* <p className="p-modal">
                          Caso já possua cadastro preencha seu email e clique em "JÁ POSSUO CADASTRO" 
                        </p> */}
                      
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </div>
              </div>
        </Modal>
        <footer>
          <button className="btn btn-info bntFooter" onClick={() => setShow(true)}>
            <FontAwesomeIcon icon={faHome} color="white" />
            <br />
            Compre Aqui!
          </button>
        </footer>
    </div>
  );
}
