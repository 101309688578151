import React, {useState, useRef, useEffect} from 'react'
import './style.css'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import Collapse from 'react-bootstrap/Collapse'
import EscolhaUnidade from './escolhaUnidade'
import Negociacao from './negociacao/index'
import DescricaoVenda from './descricaoVenda'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareDown, faPlusCircle, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
//import api from '../../../data/dadosEspelho.json'
import { realMoeda } from '../../../components/InputTextMask'
import CalloutDommus from '../../../components/CalloutDommus'

import ModalDommus from '../../../components/Modal/index'
 
export default function FluxoPagamento(props){

    let vlrUnd = 0;
    let vrTotalfinanciamento = 150000.00;
    let vlrEntrada = 0;


    const [unSelecionado, setUnSelecionado] = useState(false)

    const [openEscolhaUnidade, setOpenEscolhaUnidade] = useState(true);
    //const [openFluxoPgto, setOpenFluxoPgto] = useState(true);
    //const [bloco, setBloco] = useState([])
    const [unidade, setUnidade] = useState([])

    const [totalFinanciamento, setTotalFinanciamento] = useState(150000.00)

    var optionsMensais = [];

    const [totalMensais, setTotalMensais] = useState()

    const [maxMensais, setMaxMensais] = useState(60)

    const [entrada, setEntrada] = useState(0)

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const [sinal, setsinal] = useState()

    const [erroSinal, setErroSinal] = useState('')

    const childRef = useRef()

    const sinalRef = useRef()

    const [colloutSelect, setcolloutSelect] = useState(false)

    const [habilitaSinal, sethabilitaSinal] = useState(false)

    const [habilitaParcelas, sethabilitaParcelas] = useState(false)

    const [dataSinal, setdataSinal] = useState('')

    const [erroDataSinal, seterroDataSinal] = useState('')

    const [vencimentoPrimeiraMensal, setvencimentoPrimeiraMensal] = useState()

    const [habilitaProposta, sethabilitaProposta] = useState(false)

    const [openModalFluxoPgto, setopenModalFluxoPgto] = useState(false)

    useEffect(() => {

        let callout = document.getElementsByClassName('bs-callout');
        for(let i=0; i<callout.length; i++) {
            callout[i].style.borderLeftColor = 'rgb(238, 238, 238)';
        }

        let selectunidade = document.getElementById('callout-1');
        
        if(selectunidade != null)
            selectunidade.style.borderLeftColor = 'var(--dommus-color-2)';

    }, [])


    function validaMinDataSinal(){
        let today = new Date();
        let anoAtual = today.getFullYear();
        let mes = today.getMonth() + 1;
        let dia = today.getDate();

        if(mes < 10){
            mes = '0' + mes
        }
        if(dia < 10){
            dia = '0' + dia
        }

        let d = anoAtual + "-" + mes + '-' + dia;
        
        return d
    }

    function validaMaxDataSinal(){
        let today = new Date();
        let anoAtual = today.getFullYear();
        let mes = today.getMonth() + 2;

        if(mes < 10){
            mes = '0' + mes
        }
        
        if(mes > 12){
            mes = '01'
            anoAtual = Number(anoAtual + 1)
        }
        
        var ultimoDia = new Date(today.getFullYear(), today.getMonth() + 2, 0);

        ultimoDia = ultimoDia.getDate()
   
        let d = anoAtual + "-" + mes + '-' + ultimoDia;

        return d
    }

    function unidadeSelecionada(und){
        window.scrollTo(0, 0);
        
        setUnidade(und)

        sethabilitaSinal(true)
        
        let vlrUnd = (und[0].unidade.infoVenda[0].valorVenda).toFixed(2)
        let vlrEntrada =  (vlrUnd - totalFinanciamento).toFixed(2)
        
        setEntrada(vlrEntrada)
        //setOpenEscolhaUnidade(false)
        
        window.scrollBy(0, 170);

        sinalRef.current.focus()

        let callout = document.getElementsByClassName('bs-callout');
        for(let i=0; i<callout.length; i++) {
            callout[i].style.borderLeftColor = 'rgb(238, 238, 238)';
          }
        let sinal = document.getElementById('callout-2');

        sinal.style.borderLeftColor = 'var(--dommus-color-2)';
        setUnSelecionado(true)
        setsinal("")
       // childRef.current.refFocusSinal()
    }

    function recuperaSinal(){
      
        if(sinal == '' || dataSinal == ''){
            setErroSinal("Preencha todos os campos para prosseguir")
        }else{
            if(entrada != 0 && sinal <= entrada)  {
                //setExibeErroSinal("")
                setTotalMensais((entrada - sinal).toFixed(2))
                sethabilitaParcelas(true)
            }else{
                setErroSinal("Preencha todos os campos para prosseguir")
            }
        }

    }

    const calculaMensais = () => {
        // let optionsMensais = []

        for(let qtdMensais = 1;  qtdMensais  <= maxMensais; qtdMensais++){
            optionsMensais.push(
                <option value={qtdMensais}>
                    {qtdMensais + ' x ' + ' R$ ' + realMoeda((totalMensais / qtdMensais).toFixed(2))}
                </option>
            )
        }

        return optionsMensais

    }

    function btnEnviarProposta(){
        sethabilitaProposta(true)
        setopenModalFluxoPgto(true)
    }
    
    return(
         <div className="TabProponenteFluxo"> 
                {
                    props.processo.evolucao <= 6 ?
                    <>
                        <div className="divCallout" id="divCallout">
                        <CalloutDommus
                            index={0}
                            //onCheck={calloutCheckout.bind(this)}
                            selecionado={colloutSelect}
                            passo="A"
                            titulo="Análise de Crédito"
                            conteudo={
                                <>
                                <br/>
                                <p className="text-left LabelFluxo">
                                    Esses são os valores liberados pelo banco para você:
                                </p>
                                <div className="calloutAnaliseCredito">   
                                    <div className="marginAnalise">
                                        <p>FGTS
                                            <FontAwesomeIcon 
                                                color="#007bff"
                                                style={{display: 'flex', margin: '8px', float: 'right'}} 
                                                icon={faPlusCircle} />
                                        </p>
                                        <p className="">R$ 50.000,00
                                        
                                        </p>
                                    </div>
                                    <div className="marginAnalise">
                                        <p>SUBSÍDIO
                                            <FontAwesomeIcon 
                                                color="#007bff"
                                                style={{display: 'flex', margin: '8px', float: 'right'}} 
                                                icon={faPlusCircle} />
                                        </p>
                                        <p>R$ 10.000,00
                                    
                                        </p>
                                    </div>   
                                    <div className="marginAnalise">
                                        <p>FINANCIAMENTO</p>
                                        <p>R$ 90.000,00
                                        </p>
                                    </div>
                                </div>
                                </>
                            }
                            rodape={
                                <>
                                    <p className="text-center">TOTAL: R$ 150.000,00</p>
                                </>
                            } />
                        <br/>
                        <CalloutDommus
                            index={1}
                            selecionado={colloutSelect}
                            passo="B"
                            titulo="Seleção de Unidade"
                            conteudo={
                                <>
                                    <br/>
                                    <p className="LabelFluxo">Agora, escolha no quadro ao lado, a unidade que você deseja comprar:</p>
                                    <p>Ex.: Unidade selecionada: Bloco X, Unidade: Y.</p>
                                </>
                            } />
                        <br/>
                        {
                            habilitaSinal ?

                            <CalloutDommus
                            index={2}
                            selecionado={colloutSelect}
                            passo="C"
                            titulo="Sinal/Ato"
                            conteudo={
                                <>
                                    <label className="col-xs-4 col-form-label">
                                        Informe qual será o valor ideal para o pagamento do sinal/ato:
                                        <span className="help-text">*</span>
                                    </label>
                                    <input type="text" 
                                            className="form-control" 
                                            placeholder="R$ 0,00"
                                            maxLength="12"
                                            ref={sinalRef}
                                            value={realMoeda(sinal)}
                                            onChange={e => {
                                                let v
                                                v = e.target.value
                                                v = e.target.value.replace(/\D/g, '')
                                                v = v.replace(/([0-9]{2})$/g, ".$1")
                                                
                                                setsinal(v)
                                                //setSinalSemFormatacao(v)
                                            }} />
        
                                    <label className="col-form-label">Data de vencimento: <span className="help-text">*</span></label>
                                    <div className="divNegociacao">
                                        <input type="date" 
                                            min={validaMinDataSinal()}
                                            max={validaMaxDataSinal()}
                                            className="form-control" 
                                            //id={'data_nascimentoProponente' + props.props.index} 
                                            placeholder="data de Nascimento"
                                            value={dataSinal
                                            // props.props.proponente.dados_cadastrais.data_nascimento
                                            }
                                            onChange={e => {
                                                setdataSinal(e.target.value)
                                            }}
                                            onBlur={e => {
                                                let min = validaMinDataSinal()
                                                let max = validaMaxDataSinal()
                                                setdataSinal(e.target.value)
                                                if(dataSinal >= min && dataSinal <= max){  
                                                    seterroDataSinal('')   
                                                }else{
                                                    e.target.value = ""
                                                    setdataSinal(e.target.value)
                                                    seterroDataSinal('data inválida')
                                                }  
                                            }}
                                        />
                                        <button type="button" className="confirmar" onClick={() => recuperaSinal()}>
                                            CONFIRMAR
                                        </button>
                                    </div>
                                    <span className="help-text">
                                        {
                                            erroDataSinal
                                        }
                                    </span>
                                    <span className="help-text">
                                        {
                                            erroSinal
                                        }
                                    </span>
                                </>
                            } /> :

                            <CalloutDommus
                            index={2}
                            selecionado={colloutSelect}
                            passo="C"
                            titulo="Sinal/Ato"
                            conteudo={
                                <>
                                    <label className="col-xs-4 col-form-label">
                                        Informe qual será o valor ideal para o pagamento do sinal/ato:
                                        <span className="help-text">*</span>
                                    </label>
                                    <input disabled type="text" 
                                            className="form-control" 
                                            placeholder="R$ 0,00"
                                            maxLength="12"
                                            ref={sinalRef}
                                    />
        
                                    <label className="col-form-label">Data de vencimento: <span className="help-text">*</span></label>
                                    <div className="divNegociacao">
                                        <input disabled type="date" 
                                            className="form-control" 
                                        />
                                        <button disabled type="button" className="confirmar">
                                            CONFIRMAR
                                        </button>
                                    </div>
                                </>
                            } />

                        }
                        <br/>
                        {
                            habilitaParcelas ? 

                            <CalloutDommus
                            index={3}
                            selecionado={colloutSelect}
                            passo="D"
                            titulo="Parcelamento"
                            conteudo={
                                <>
                                    {/* <br/> */}
                                
                                    <label className="col-xs-4 col-form-label">
                                        Escolha o parcelamento do restante do valor: <span className="help-text">*</span></label>
                                        <select className="form-control custom-select" name="">
                                            <option hidden>--SELECIONE--</option>
                                            {
                                                totalMensais !== undefined ?
                                                calculaMensais() : 
                                                
                                                <>
                                                    {optionsMensais = []}
                                                </>
                                            }
                                        </select>  
                                    <br/> 
                                    <label className="col-form-label">Data de vencimento (1ª mensal): <span className="help-text">*</span></label>
                                    <div className="divNegociacao">
                                        <input type="date" 
                                                min={dataSinal}
                                                className="form-control" 
                                                value={
                                                    vencimentoPrimeiraMensal
                                                // props.props.proponente.dados_cadastrais.data_nascimento
                                                }
                                                onChange={e => {
                                                    setvencimentoPrimeiraMensal(e.target.value)
                                                }}
                                                onBlur={e => {
                                                    if(vencimentoPrimeiraMensal <= dataSinal){
                                                        setvencimentoPrimeiraMensal('')
                                                    }
                                                }}
                                            />    
                                        <button type="button" className="confirmar" onClick={() => btnEnviarProposta()}>
                                            CONFIRMAR
                                        </button>     
                                    </div>
                                </>
                            } /> :

                            <CalloutDommus
                            index={3}
                            selecionado={colloutSelect}
                            passo="D"
                            titulo="Parcelamento"
                            conteudo={
                                <>
                                    <label className="col-xs-4 col-form-label">
                                        Escolha o parcelamento do restante do valor: <span className="help-text">*</span></label>
                                        <select disabled className="form-control custom-select" name="">
                                            <option hidden>--SELECIONE--</option>
                                        </select>  
                                    <br/> 
                                    <label className="col-form-label">Data de vencimento (1ª mensal): <span className="help-text">*</span></label>
                                    <div className="divNegociacao">
                                        <input disabled type="date" 
                                                //max={validaMaxData()}
                                                //min={validaMinData()}
                                                className="form-control" 
                                                //id={'data_nascimentoProponente' + props.props.index} 
                                                placeholder="data de Nascimento"
                                        />    
                                        <button disabled type="button" className="confirmar">
                                            CONFIRMAR
                                        </button>     
                                    </div>
                                </>
                            } />

                        }
                        <br/>
                        {
                            habilitaProposta ?
                            <>
                                <button className="btn btn-primary float-left btnEnviarProposta" 
                                onClick={
                                    () => btnEnviarProposta()
                                }>
                                <span>
                                    Enviar Proposta
                                </span> 
                                </button> 
                                <ModalDommus open={openModalFluxoPgto}
                                    close={() => setopenModalFluxoPgto(false)}
                                    content={
                                    <>
                                    <div style={{width: '100%'}}> 
                                        <DescricaoVenda /> 
                                        <p className="pFluxoPgto">Fluxo de Pagamento: </p>        
                                        <Negociacao />
                                    </div>
                                        <div className="col-xs-12">
                                        <button type="button" 
                                            className="btn btn-info float-right" 
                                            onClick={() =>{
                                                setopenModalFluxoPgto(false)
                                                props.evoluirContrato()
                                            } 
                                            }>
                                            
                                            <span>
                                                CONFIRMAR
                                                <FontAwesomeIcon style={{ marginLeft: 10 }} 
                                                                icon={faAngleDoubleRight} 
                                                                color="white">
                                                </FontAwesomeIcon>    
                                            </span> 
                                        </button>
                                    </div>
                                    </>
                                    }
                                titulo="Tabela de condição de pagamento:"/>
                            </>
                            
                            : 

                            <button disabled 
                                className="btn btn-primary float-left btnEnviarProposta">
                                <span>
                                    Enviar Proposta
                                </span> 
                            </button>

                        }

                    </div>
                    <br />
                    <div className="card selecaoUnidadeDiv col-xs-12 col-md-12 col-lg-8"> 
                        <div className="divLegenda">
                            <div className="input-group">
                                <input readOnly type="text" className="form-control"
                                    value="Selecione a sua unidade:" />
                                <div className="input-group-prepend">
                                    <span 
                                        style={{backgroundColor: 'var(--dommus-color-2)', borderBottomRightRadius: '5px', borderTopRightRadius: '5px'}} 
                                        className="input-group-text legendaLaranja" id="">
                                        <span className='tituloLegenda'>Legenda</span>
                                        <span className='spanInterno' style={{backgroundColor: '#fff'}}>
                                            <div disabled
                                                className="legenda"
                                                style={{backgroundColor: 'grey'}}> 
                                            </div>
                                            <p className="card-title textoLegenda">INDISPONÍVEL</p>

                                            <div 
                                                className="legenda"
                                                style={{backgroundColor: 'white'}}> 
                                            </div>
                                            <p className="card-title textoLegenda">DISPONÍVEL</p>

                                            <div 
                                                className="legenda"
                                                style={{backgroundColor: '#28a745'}}> 
                                            </div>
                                            <p className="card-title textoLegenda">SELECIONADA</p>

                                        </span>
                                    </span>
                                </div>
                                {/* <input type="text" className="form-control" /> */}
                            </div>
                            <p></p>
                        </div>
                        {/* <div className="expansiveSelecaoUnidade">           */}
                            <div style={{borderBottom: 'unset'}} className="card-header" id="headingOne">
                                <h5>
                                <button className="btn btn-link LinkTabCard"
                                        onClick={() => setOpenEscolhaUnidade(!openEscolhaUnidade)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={openEscolhaUnidade}>
                                    {
                                        unidade.length === 0 ? 
                                            <p className="text-left TextoTab">
                                                {/* Selecione a sua unidade: */}
                                                <span>
                                                    <FontAwesomeIcon size="2x" className="iconDown" icon={faCaretSquareDown}  />
                                                </span>
                                            </p> :
                                            <>
                                                <p className="text-left float-left TextoTab">
                                                    Unidade selecionada: &nbsp;          
                                                </p>
                                                <p  className="text-left TextoTab">
                                                    
                                                    {
                                                        vlrUnd = (unidade[0].unidade.infoVenda[0].valorVenda).toFixed(2),
                                                        vlrEntrada =  (vlrUnd - totalFinanciamento).toFixed(2),
                                                        "Bloco:" + "\xa0" + unidade[0].nomeBloco +
                                                        " Unidade:" + "\xa0" +
                                                        unidade[0].unidade.numero  
                                                    }
                                                    <br/>
                                                    <span style={{fontSize: '12px'}}>
                                                        {
                                                            "Cálculo: " +
                                                            `(Valor Unidade: R$ ${realMoeda(vlrUnd)} - Total Financiamento: R$ ${realMoeda(vrTotalfinanciamento.toFixed(2))})`
                                                        } 
                                                    </span>
                                                    <br/>
                                                    <span className="pTotalPg">
                                                        {
                                                            "TOTAL À PAGAR: R$ " +
                                                            realMoeda(vlrEntrada) 
                                                        }
                                                    </span>
                                                    <span style={{marginLeft: '5px'}}>
                                                        <FontAwesomeIcon size="2x" 
                                                                        className="iconDown" 
                                                                        icon={faCaretSquareDown}  />
                                                    </span>
                                                </p>
                                            </>     
                                    }
                                </button>
                                </h5>
                            {/* </div> */}
                            
                            <Collapse in={openEscolhaUnidade}>
                                <div className="Stylebloco" id="example-collapse-text">
                                    <EscolhaUnidade className="float-right" onCheck={unidadeSelecionada.bind(this)} />
                                </div>
                            </Collapse>
                        </div>
                    </div>     
                </> :
                <div style={{width: '100%'}}> 
                    <DescricaoVenda /> 
                    <p className="pFluxoPgto">Fluxo de Pagamento: </p>        
                    <Negociacao />
                </div>
                }
        </div>
    )
}