import React from 'react';
import Routes from './routes'

import DommusPromiseLoading from './components/DommusLoading/DommusPromiseLoading';

function App() {
  return (
    <>
      <DommusPromiseLoading />
      <Routes>
      </Routes>
    </>
  );
}

export default App;


