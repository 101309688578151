export default class PDFJs {
    init = (source, element) => {
      const iframe = document.createElement('iframe');
      iframe.width = '0px';
      iframe.height = '0px';
  
      //iframe.src = `/pdfjs-2.6.347-dist/web/viewer.html?file=${source}`;
      iframe.src = `${source}`;
      // `http://demo.dommusdriver.com.br/cadastros/0002077/38137416056_ficha_cadastral.pdf`;
      iframe.width = '100%';
      iframe.height = '450px';
  
      element.appendChild(iframe);
    }
  }