import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react'

import {cepMask, toUppercase} from '../../../components/InputTextMask' 

import './style.css'

import Swal from 'sweetalert2';

import viaCep from '../../../data/viacep.js'

const DadosEndereco = forwardRef((props, refEndereco) => {
    const [cep, setCep] = useState('')
    const [tipo_logradouro, settipo_logradouro] = useState(props.props.proponente.endereco.tipo_logradouro)
    const [logradouro, setLogradouro] = useState(props.props.proponente.endereco.logradouro)
    const [selectLogradouro, setSelectLogradouro] = useState([])
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState(props.props.proponente.endereco.bairro)
    const [cidade, setCidade] = useState(props.props.proponente.endereco.cidade)
    const [uf, setUf] = useState(props.props.proponente.endereco.uf)

    const [tipoLog, settipoLog] = useState('')
   
    useEffect(() => {
       
        if(props.props.dadosSelect.preencheDadosSelect !== undefined){     
            setSelectLogradouro(props.props.dadosSelect.preencheDadosSelect.tipoLogradouro) 
        }

        props.props.editeProponente(props.props.index, "endereco", "logradouro", toUppercase(logradouro))
        props.props.editeProponente(props.props.index, "endereco", "tipo_logradouro", tipo_logradouro)
        props.props.editeProponente(props.props.index, "endereco", "bairro", toUppercase(bairro))
        props.props.editeProponente(props.props.index, "endereco", "cidade", toUppercase(cidade))
        props.props.editeProponente(props.props.index, "endereco", "uf", uf)

    }, [logradouro, uf, tipo_logradouro, cidade, bairro])

    useImperativeHandle(refEndereco, () => ({

        refValidaDadosCadastrais(dado, estado){
            estado === '' ?
            document.getElementById(dado + 'Proponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
            
            document.getElementById(dado + 'Proponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
        }  
    }));
    
    function handleNumeroValido(e){
          
        let res = 0;
       
        res = Math.abs(e)
    
        if(isNaN(res)){
            res = 0
        }
            
        return res;
    }

    function buscaViaCep(value){

        viaCep.get('ws/' + value + '/json').then( response => {

            document.getElementById('tipo_logradouroProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
            document.getElementById('logradouroProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
            document.getElementById('bairroProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
            document.getElementById('cidadeProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
            document.getElementById('ufProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"


            let data = response.data

            let str = data.logradouro.split(" ", 1)
            
            let nvstr = str[0]

            nvstr = toUppercase(str[0])
            let res = data.logradouro.replace(str,"")
         
            settipoLog(str)
 
            setLogradouro(res)

            setCidade(data.localidade)
            
            setBairro(data.bairro)
            
            setUf(data.uf)

            selectLogradouro.map((item, i) => {
                
                if(item.descricao == nvstr){
                    settipo_logradouro(item.id)
                }
            })    

            //atualizaPropsViaCep()
           
        }).catch(error => {
            Swal.fire(
                'Não foi possível localizar CEP',
                '',
                'error'
            )
        })
    }

    return(
        <>
            <div className="form-group row">
                <div className="col-xs-12 col-md-12 col-lg-3">       
                    <label>CEP: <span className="help-text">*</span></label>
                    <input type="text" 
                           id={'cepProponente' + props.props.index}
                           className="form-control" 
                           placeholder="Ex.: 00000-000"
                           maxLength="9"
                           value={cepMask(props.props.proponente.endereco.cep)}
                           onBlur={e => {
                               let cep = e.target.value.replace('-', '');
                               buscaViaCep(cep)
                            }}
                           onChange={e => {
                            setCep(e.target.value)
                            props.props.editeProponente(props.props.index, "endereco", "cep", e.target.value.replace(/\D/g, ''))
                            // e.target.value === '' ?  document.getElementById('cepProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                            // document.getElementById('cepProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
                            }}
                     />
                </div>
                <div className="col-xs-12 col-md-12 col-lg-2">       
                    <label>Tipo Logradouro: <span className="help-text">*</span></label>

                    <select className="form-control custom-select"
                            id={'tipo_logradouroProponente' + props.props.index}
                            value={tipo_logradouro}
                            onChange={e => {
                                settipo_logradouro(e.target.value)
                                props.props.editeProponente(props.props.index, "endereco", "tipo_logradouro", e.target.value)
                            }}>
                        <option hidden >---SELECIONE---</option>
                        {   
                            selectLogradouro.map((item, i) => (
                                <option
                                    key={i}
                                    value={item.id} >
                                    {item.descricao}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-5">       
                    <label>Logradouro: <span className="help-text">*</span></label>
                    <input type="text"
                           id={'logradouroProponente' + props.props.index} 
                           className="form-control" 
                           placeholder="Digite o logradouro"
                           value={toUppercase(logradouro)}
                           onChange={e => {
                            setLogradouro(toUppercase(e.target.value))
                            props.props.editeProponente(props.props.index, "endereco", "logradouro", toUppercase(e.target.value))}}
                           />
                </div>
                <div className="col-xs-12 col-md-12 col-lg-2">       
                    <label >Número: <span className="help-text">*</span></label>
                    <input type="text"
                           id={'numeroProponente' + props.props.index}
                           className="form-control" 
                           placeholder="número" 
                           maxLength="6"
                           minLength="0"
                           value={props.props.proponente.endereco.numero}
                           onChange={e => {
                            e.target.value = handleNumeroValido(e.target.value)
                            setNumero(e.target.value)
                            e.target.value === '' || e.target.value == 0 ? document.getElementById('numeroProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                            document.getElementById('numeroProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)";
                            props.props.editeProponente(props.props.index, "endereco", "numero", e.target.value)
                           }}
                           />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-xs-12 col-md-12 col-lg-2">   
                    <label> Complemento:</label>
                    <input type="text" 
                           className="form-control uppercase" 
                           placeholder="Ex: apto, casa, etc."
                           value={toUppercase(props.props.proponente.endereco.complemento)}
                           onChange={e => {
                            setComplemento(toUppercase(e.target.value))
                            props.props.editeProponente(props.props.index, "endereco", "complemento", toUppercase(e.target.value))}} />
                </div>
                <div className="col-xs-12 col-md-12 col-lg-4">       
                    <label>Bairro: <span className="help-text">*</span></label>
                    <input type="text" 
                           id={'bairroProponente' + props.props.index}
                           className="form-control uppercase" 
                           placeholder="informe o seu bairro"
                           value={toUppercase(bairro)}
                           onChange={e => {
                            setBairro(toUppercase(e.target.value))
                            props.props.editeProponente(props.props.index, "endereco", "bairro", toUppercase(e.target.value))}}
                           />
                </div>
                <div className="col-xs-12 col-md-12 col-lg-4">       
                    <label>Cidade: <span className="help-text">*</span></label>
                    <input type="text"
                           id={'cidadeProponente' + props.props.index}                             
                           className="form-control uppercase" 
                           placeholder="informe a sua cidade"
                           value={toUppercase(cidade)}
                           onChange={e => {
                            setCidade(toUppercase(e.target.value))
                            props.props.editeProponente(props.props.index, "endereco", "cidade", toUppercase(e.target.value))}}
                           />
                </div>
                <div className="col-xs-12 col-md-12 col-lg-2">       
                    <label>UF: <span className="help-text">*</span></label>
                    <select className="form-control custom-select"
                            id={'ufProponente' + props.props.index}
                            value={toUppercase(uf)}
                            onChange={e => {
                             setUf(e.target.value)
                             props.props.editeProponente(props.props.index, "endereco", "uf", toUppercase(e.target.value))}}
                    >
                    <option hidden >---SELECIONE---</option>
                        {/* <option value={uf}>{uf}</option> */}
                        <option value="RO">RO</option>
                        <option value="AC">AC</option>
                        <option value="AM">AM</option>
                        <option value="RR">RR</option>
                        <option value="PA">PA</option>
                        <option value="AP">AP</option>
                        <option value="TO">TO</option>
                        <option value="MA">MA</option>
                        <option value="PI">PI</option>
                        <option value="CE">CE</option>
                        <option value="RN">RN</option>
                        <option value="PB">PB</option>
                        <option value="PE">PE</option>
                        <option value="AL">AL</option>
                        <option value="SE">SE</option>
                        <option value="BA">BA</option>
                        <option value="MG">MG</option>
                        <option value="ES">ES</option>
                        <option value="RJ">RJ</option>
                        <option value="SP">SP</option>
                        <option value="PR">PR</option>
                        <option value="SC">SC</option>
                        <option value="MG">MG</option>
                        <option value="RS">RS</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="GO">GO</option>
                        <option value="SP">SP</option>
                        <option value="MG">MG</option>
                        <option value="DF">DF</option>
                    </select>
                </div>
            </div>
        </>
    )
})

export default DadosEndereco;