import React, {useState, useRef, forwardRef, useImperativeHandle, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle,  faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import RegimeComunhaoModal from './regimeComunhao/index'
import ModalDommus from '../../../components/Modal/index'
//import Tooltip from 'react-bootstrap/Tooltip'
import './style.css'

import { cpfMask, celularMask, toUppercase } from '../../../components/InputTextMask'

const DadosCadastrais = forwardRef((props, ref) => {

    const inputCpfFocus = useRef() 

    const [cpf, setCpf] = useState('')
    const [erroCpf, setErroCpf] = useState('')
    const [habilitaCpf, setHabilitaCpf] = useState(true)
    const [nome, setNome] = useState(props.props.proponente.dados_cadastrais.nome)
    const [email, setEmail] = useState('')
    const [celular, setCelular] = useState('')
    const [data_nascimento, setdata_nascimento] = useState('')
    const [genero, setGenero] = useState('')
    const [tipo_documento, setTipo_documento] = useState('')
    const [selectTipoDocumento, setSelectTipoDocumento] = useState([])
    const [numero_documento, setNumero_documento] = useState(props.props.proponente.dados_cadastrais.numero_documento)
    const [grau_instrucao, setGrau_escolaridade] = useState(0)
    const [selectGrauEscolaridade, setSelectGrauEscolaridade] = useState([])
    const target = useRef(null)
    const [show, setShow] = useState(false)

    const [erroData, seterroData] = useState('')
    const [erroEmail, setErroEmail] = useState('')

    const [habilitaRegimeComunhao, setHabilitaRegimeComunhao] = useState(false)

    const [openModalRegimeComunhao, setOpenModalRegimeComunhao] = useState(false)

    //let dados = localStorage.getItem('dadosProcesso')

    let contato = props.props.proponente.contato
    
    const [verificaCpf, setVerificaCpf] = useState('')
    
    useEffect(() => {
       let VeioCpf = props.props.proponente.dados_cadastrais.cpf

       if(props.props.dadosSelect.preencheDadosSelect !== undefined){     
           setSelectTipoDocumento(props.props.dadosSelect.preencheDadosSelect.tipoDocumento)
           setSelectGrauEscolaridade(props.props.dadosSelect.preencheDadosSelect.grauInstrucao)
       }

    //    if(VeioCpf !== '' || VeioCpf !== undefined){
    //        setHabilitaCpf(false)
    //    }else{
    //        setHabilitaCpf(true)
    //    }
      //document.getElementById("cpfProponente").focus()
       inputCpfFocus.current.focus()

    }, [])


    useImperativeHandle(ref, () => ({

        refValidaDadosCadastrais(dado, estado){
            estado === '' ?
            document.getElementById(dado + 'Proponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
            
            document.getElementById(dado + 'Proponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
        }  
    }));

    function verificaEstadoCivil(target){
        if(target === '2' || target === '3'){
            //setHabilitaCpf(true)
            setHabilitaRegimeComunhao(true)
        }else{
            setHabilitaRegimeComunhao(false)
        }
    }

    function verificaRegimeComunhao(){
       // props.props.addNovoComprador('')
       //setOpenModalRegimeComunhao(true)
    }

    function validaMaxData(){

        let today = new Date();
        let anoAtual = today.getFullYear();
        let mes = today.getMonth() + 1;
        let dia = today.getDate();

        if(dia <= 9){
            dia = "0" + dia
        }

        
        let year = anoAtual - 18
        
        let d = year + "-" + mes + '-' + dia;

        return d
    }

    function validaMinData(){
        let today = new Date();
        let anoAtual = today.getFullYear();
        let mes = today.getMonth() + 1;
        let dia = today.getDate();
        let anoLimite

        if(dia <= 9){
            dia = "0" + dia
        }

        anoLimite = anoAtual - 100
    
        let d = anoLimite + "-" + mes + '-' + dia;

        return d
    }

    function validacaoCpf(cpf){

        if (cpf.length == 14) {
          cpf.trim();
         
          cpf = cpf.replace(/\./g, '');
          cpf = cpf.replace('-', '');
          cpf = cpf.split('');
    
          var v1 = 0;
          var v2 = 0;
          let aux = false;
          
          for (var i = 1; cpf.length > i; i++) {
              if (cpf[i - 1] != cpf[i]) {
                  aux = true;   
              }
          } 
          
          if (aux == false) {
              return false; 
          } 
          
          for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
              v1 += cpf[i] * p; 
          } 
          
          v1 = ((v1 * 10) % 11);
          
          if (v1 == 10) {
              v1 = 0; 
          }
          
          if (v1 != cpf[9]) {
              return false; 
          } 
          
          for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
              v2 += cpf[i] * p; 
          } 
          
          v2 = ((v2 * 10) % 11);
          
          if (v2 == 10) {
              v2 = 0; 
          }
          
          if (v2 != cpf[10]) {
              return false; 
          } else {   
              return true; 
          }
        }else {
          return false;
        }
    
    }

    function validaEmail(dado){
        let reg = /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i;
        
        if(!reg.test(dado) && dado.length > 0){

            setErroEmail('Email Inválido') 
            return false 
        }
        else if(!reg.test(dado) && dado.length < 0){
            setErroEmail('informe um e-mail')
            return false
        }else{
            setErroEmail('')
            return true
        }
    }

    const montaContato = () => {
        let contato = props.props.proponente.contato

        let auxContato = []

        contato.map( (item, i) => auxContato.push(
            <>
                {
                    item.tipo_contato === 'E' ? 
                    <div className="col-xs-12 col-md-12 col-lg-3">
                        <label className="col-form-label">E-mail: <span className="help-text">*</span></label>
                        <input className="form-control" 
                                placeholder="Seu melhor e-mail"
                                id={'emailProponente' + props.props.index} 
                                type="email"
                                value={item.valor}
                                required
                                onChange={e => {
                                        item.valor = e.target.value
                                        setEmail(e.target.value)
                                        props.props.editeProponenteParametro(props.props.index, "contato", i, 
                                        "valor", e.target.value)
                                        props.props.editeProponenteParametro(props.props.index, "contato", i, 
                                        "tipo_contato", "E")
                                        e.target.value === '' ?  document.getElementById('emailProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                        document.getElementById('emailProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
                                    }
                                }
                                onBlur={e => {
                                   let v = validaEmail(e.target.value)
                                   if(!v){
                                       e.target.value = ''
                                       setEmail('')
                                       item.valor = ''
                                   }
                                }}
                                />
                            <p className="paragrafoErro fonteErro"> {erroEmail} </p>  
                    </div> :

                    item.tipo_contato === '' ?

                    <>

                        <div className="col-xs-12 col-md-12 col-lg-3">
                            <label className="col-form-label">E-mail: <span className="help-text">*</span></label>
                            <input className="form-control" 
                                    placeholder="Seu melhor e-mail" 
                                    type="email"
                                    id={'emailProponente' + props.props.index}
                                    value={item.valor}
                                    required
                                    onChange={e => {
                                            item.valor = e.target.value
                                            setEmail(e.target.value)
                                            props.props.editeProponenteParametro(props.props.index,"contato",  i, "valor", e.target.value)
                                            props.props.editeProponenteParametro(props.props.index,"contato",  i, "tipo_contato", 
                                            "E")
                                            e.target.value === '' ?  document.getElementById('emailProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                            document.getElementById('emailProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
                                        }
                                    }
                                    onBlur={e => {
                                        let v = validaEmail(e.target.value)
                                        if(!v){
                                            e.target.value = ''
                                            setEmail('')
                                            item.valor = ''
                                        }
                                     }}
                                    />
                                <p className="paragrafoErro fonteErro"> {erroEmail} </p>  
                        </div>
                        
                        <div className="col-xs-12 col-md-12 col-lg-2">
                            <label className="col-form-label">Celular: <span className="help-text">*</span></label>
                            <input type="text" 
                                    className="form-control" 
                                    id={"celularProponente" + props.props.index} 
                                    placeholder="Ex.: 000.000.000-00"
                                    maxLength="15"
                                    value={celularMask(item.valor)}
                                    onChange={(e) => {
                                            setCelular(e.target.value)
                                            props.props.editeProponenteParametro(props.props.index, "contato", i, 
                                            "valor", e.target.value)
                                            props.props.editeProponenteParametro(props.props.index, "contato", i, 
                                            "tipo_contato", "S")
                                            e.target.value === '' ?  document.getElementById('celularProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                            document.getElementById('celularProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
                                        }}
                                //.replace(/[^\d]+/g,'')
                            />                                        
                        </div>
                    </> :

                    <div className="col-xs-12 col-md-12 col-lg-2">
                        <label className="col-form-label">Celular: <span className="help-text">*</span></label>
                        <input type="text" 
                                className="form-control" 
                                id={"celularProponente" + props.props.index} 
                                placeholder="Ex.: 000.000.000-00"
                                maxLength="15"
                                value={celularMask(item.valor)}                               
                                onChange={(e) => {
                                        setCelular(e.target.value)
                                        let celsemmascara = e.target.value.replace('-', '');
                                        celsemmascara = celsemmascara.replace('(', '');
                                        celsemmascara = celsemmascara.replace(')', '');
                                        celsemmascara = celsemmascara.replace(/\s/g, '');
                                        celsemmascara = celsemmascara.trim();
                                        props.props.editeProponenteParametro(props.props.index, "contato", i, "valor", 
                                        celsemmascara)
                                        props.props.editeProponenteParametro(props.props.index, "contato", i, "tipo_contato", 
                                        "S")
                                        e.target.value === '' ?  document.getElementById('celularProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                        document.getElementById('celularProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
                                    }}
                        />                                        
                    </div>
                }
            </>
        ))
    
        return auxContato
    }

    return(
        <>    
            <div className="form-group row">
                {
                    !props.props.proponente.flagCpf ? 

                    <div className="col-xs-12 col-md-12 col-lg-3">       
                        <label className="col-form-label">CPF: <span className="help-text">*</span></label>
                        <input type="text" 
                            className="form-control" 
                            id={"cpfProponente" + props.props.index} 
                            placeholder="Ex.: 000.000.000-00"
                            maxLength="14"
                            value={cpfMask(props.props.proponente.dados_cadastrais.cpf)}
                            onChange={(e) => {
                                setCpf(e.target.value)
                                let cpfsemmascara = e.target.value.replace(/\./g, '');
                                cpfsemmascara = cpfsemmascara.replace('-', '');
                                props.props.editeProponente(props.props.index, "dados_cadastrais", "cpf", 
                                cpfsemmascara) 
                                e.target.value === '' ?  document.getElementById('cpfProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                document.getElementById('cpfProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"                              
                            }}
                            onBlur={(e) => {
                                if(validacaoCpf(e.target.value)){
                                  setErroCpf('')
                                }else{
                                  props.props.proponente.dados_cadastrais.cpf = ''
                                  setErroCpf('Cpf inválido')
                                }
                            }}
                        />
                        <p className="paragrafoErro">
                        {erroCpf} 
                        </p>
                    </div> :

                    <div className="col-xs-12 col-md-12 col-lg-3">       
                        <label className="col-form-label">CPF: <span className="help-text">*</span></label>
                        <input type="text" 
                            className="form-control" 
                            //ref={inputCpfFocus}
                            id={"cpfProponente" + props.props.index} 
                            readOnly
                            disabled
                            placeholder="Ex.: 000.000.000-00"
                            maxLength="14"
                            value={cpfMask(props.props.proponente.dados_cadastrais.cpf)}
                            onChange={(e) => {
                                if(e.target.value != ''){
                                    setHabilitaCpf(false)
                                }else{
                                    setHabilitaCpf(true)
                                }
                                    setCpf(e.target.value)
                                    let cpfsemmascara = e.target.value.replace(/\./g, '');
                                    cpfsemmascara = cpfsemmascara.replace('-', '');
                                    props.props.editeProponente(props.props.index, "dados_cadastrais", "cpf", 
                                                    cpfsemmascara)}}
                        />
                        <p className="paragrafoErro">
                        {erroCpf} 
                        </p>
                    </div>
                }
                <div className="col-xs-12 col-md-12 col-lg-5">       
                    <label className="col-form-label">Nome: <span className="help-text">*</span></label>
                    <input type="text" 
                           className="form-control uppercase" 
                           ref={inputCpfFocus}
                           id={'nomeProponente' + props.props.index} 
                           placeholder="Digite seu nome"
                           value={toUppercase(nome)}
                           onChange={e => {
                            setNome(toUppercase(e.target.value))
                            if(e.target.value === ''){
                                document.getElementById('nomeProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)";
                            }else{
                                document.getElementById('nomeProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)";
                            }
                            props.props.editeProponente(props.props.index, "dados_cadastrais", "nome", toUppercase(e.target.value))
                        }}
                        //    onChange={ e => props.props.editeProponente(props.props.index, "nome", e.target.value) }
                            />
                </div>
                <div className="col-xs-12 col-md-12 col-lg-2">       
                    <label className="col-form-label">Data Nascimento: <span className="help-text">*</span></label>
                    <input type="date" 
                           max={validaMaxData()}
                           min={validaMinData()}
                           className="form-control" 
                           id={'data_nascimentoProponente' + props.props.index} 
                           placeholder="data de Nascimento"
                           value={props.props.proponente.dados_cadastrais.data_nascimento}
                           onBlur={e => {
                            let min = validaMinData()
                            let max = validaMaxData()
                            setdata_nascimento(e.target.value)
                            if(data_nascimento <= max && data_nascimento >= min){  
                                seterroData('')   
                                props.props.editeProponente(props.props.index, "dados_cadastrais", "data_nascimento", e.target.value)
                            }else{
                                e.target.value = ""
                                setdata_nascimento(e.target.value)
                                props.props.editeProponente(props.props.index, "dados_cadastrais", "data_nascimento", e.target.value)
                                seterroData('data inválida')
                            }  
                           }}
                           onChange={e => {
                    
                            setdata_nascimento(e.target.value)
                               
                            props.props.editeProponente(props.props.index, "dados_cadastrais", "data_nascimento", e.target.value)
                            e.target.value === '' ?  document.getElementById('emailProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                            document.getElementById('data_nascimentoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
                           }}
                    />
                    <p className="help-text">{erroData}</p>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-2">       
                    <label className="col-form-label">Gênero: <span className="help-text">*</span></label>
                    <select className="form-control custom-select"
                         id={'generoProponente' + props.props.index}
                         value={props.props.proponente.dados_cadastrais.genero}
                         onChange={e => {
                            setGenero(e.target.value)
                            props.props.editeProponente(props.props.index, "dados_cadastrais", "genero", e.target.value)
                            e.target.value === '' ?  document.getElementById('generoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                            document.getElementById('generoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
                        }}
                    >
                        <option hidden >---SELECIONE---</option>
                        <option value="M">MASCULINO</option>
                        <option value="F">FEMININO</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-xs-12 col-md-12 col-lg-2">       
                    <label className="col-form-label">Doc. Identificação: <span className="help-text">*</span></label>
                    <select className="form-control custom-select"
                            id={'tipo_documentoProponente' + props.props.index}
                            value={props.props.proponente.dados_cadastrais.tipo_documento}
                            onChange={e => {
                                setTipo_documento(e.target.value)
                                e.target.value === '' ? document.getElementById('tipo_documentoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                    document.getElementById('tipo_documentoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)";
                                props.props.editeProponente(props.props.index, "dados_cadastrais", "tipo_documento", e.target.value)}}>
                        <option hidden >---SELECIONE---</option>
                        {   
                            selectTipoDocumento.map((item, i) => (
                                <option
                                  key={i}
                                  value={item.id} >
                                  {item.descricao}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-2">       
                    <label className="col-form-label">Número Documento: <span className="help-text">*</span></label>
                    <input type="text" 
                        className="form-control uppercase" 
                        id={"numero_documentoProponente" + props.props.index} 
                        placeholder=""
                        value={toUppercase(numero_documento)}
                        onChange={e => {
                            setNumero_documento(toUppercase(e.target.value))
                            e.target.value === '' ? document.getElementById('numero_documentoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                    document.getElementById('numero_documentoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)";
                            props.props.editeProponente(props.props.index, "dados_cadastrais", "numero_documento", toUppercase(e.target.value))}}
                    />
                </div>
                <div className="col-xs-12 col-md-12 col-lg-3">       
                    <label className="col-form-label">Grau Escolaridade: <span className="help-text">*</span></label>
                    <select className="form-control custom-select"
                            id={'grau_instrucaoProponente' + props.props.index}
                            value={props.props.proponente.info.grau_instrucao}
                            onChange={e => {
                                setGrau_escolaridade(e.target.value)
                                e.target.value === '' ? document.getElementById('grau_instrucaoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
                                    document.getElementById('grau_instrucaoProponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)";
                                props.props.editeProponente(props.props.index, "info", "grau_instrucao", e.target.value)}}
                            >
                        <option hidden >---SELECIONE---</option>
                        {   
                            selectGrauEscolaridade.map((item, i) => (
                                <option
                                  key={i}
                                  value={item.id} >
                                  {item.descricao}
                                </option>
                            ))
                        }
                    </select>
                </div>
                {
                    montaContato()
                }
            </div>
        </>
    )
})

export default DadosCadastrais;