import React from 'react'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

export default function Tipologia({ emp, tipo, index, quantidade, onCheck }) {

    function cliqueCheck(index) {
      
        emp.forEach(function(value, index){
            let div = document.getElementById('card-' + index);
            div.style.backgroundColor = 'white'
        })
        let div = document.getElementById('card-' + index);
        if (div.style.backgroundColor === 'white') {
            div.style.backgroundColor = 'grey'
        } else {
            div.style.backgroundColor = 'white'
        } 
    
        onCheck(index)
    }

    return (
        <div className="tipologia">
            { emp.length === 1 ?

                <div id={'card-' + index} 
                     onClick={() => cliqueCheck(index)} 
                     className="card card-checkboxes card-item1">
                    <FontAwesomeIcon style={{ margin: '10% 0% 0% 41%' }} 
                                     size='4x' icon={faBuilding} 
                                     color="orange">
                    </FontAwesomeIcon>
                    <span className="text-center tipoFonte">{tipo}</span>
                </div> : 

                emp.length === 2 ?

                <div id={'card-' + index} 
                     onClick={() => cliqueCheck(index)} 
                     className="card card-checkboxes card-item2">
                    <FontAwesomeIcon style={{ margin: '10% 0% 0% 36%' }} 
                                     size='4x' 
                                     icon={faBuilding} 
                                     color="orange">
                    </FontAwesomeIcon>
                    <span className="text-center tipoFonte">{tipo}</span>
                </div> :

                <div id={'card-' + index} onClick={() => cliqueCheck(index)} 
                     className="card card-checkboxes card-item">
                    <FontAwesomeIcon style={{ margin: '25% 0% 10% 28%' }} 
                                     size='4x' 
                                     icon={faBuilding} 
                                     color="orange">
                    </FontAwesomeIcon>
                    <span className="text-center tipoFonte">{tipo}</span>
                </div>
            }
        </div>
    )
}