import React,  { Component }  from 'react';
import './style.css';
import { Nav, Tab, Col } from 'react-bootstrap';
import Messages from './Chat/messages';
import Input from './Chat/input';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import crmpos from '../../services/crmpos';

import passo1 from '../../assets/icon-passo1.svg'
import passo2 from '../../assets/icon-passo2.svg'

class ChatDommus extends Component {

    onSendMessage = (message) => {
        this.setState({ isLoggedIn: true })
        const messages = this.state.messages
        const id = this.props.id
    
        const fd = new FormData();
        fd.append('mensagem', message);
        fd.append('id_ticket', id);
        fd.append('usuario', localStorage.getItem('userDommusCrm'));
    
        crmpos.post('ticket/message', fd)
        .then((response) => {
          messages.push({
            text: message,
            member: {
              username: "Nome do Atendente",
              color: "#4CAF50"
            }
          })
          this.setState({ messages: messages, isLoggedIn: false })
        }); 
    }

    constructor(props) {
        super(props);
        this.state = {
          leftOpen: true,
          rightOpen: true,
          isLoggedIn: true,
          messages: [{
            text: "Olá, envie sua mensagem para iniciar uma conversa com nosso atendimento virtual",
            member: {
              id: 1,
              color: "#0A71b2",
              username: "Atendente"
            }
          }],
          member: {}
        }
      }
    
      async messagens(id) {
        if (id) {
          const response = await crmpos.get('ticket/message/' + id);
          const data = await response.data;
          const messages = this.state.messages
    
          for (let i = 0; i < data.length; i++) {
            messages.push({
              text: data[i].mensagem,
              member: {
                username: "Nome do Atendente",
                color: "#4CAF50"
              }
            })
          }   
          this.setState({ messages: messages, isLoggedIn: false })
        }
        this.setState({isLoggedIn: false })  
      }
    
      componentDidMount(){
        this.messagens(this.props.id);
      }
    render() {
        let isLoggedIn = this.state.isLoggedIn;
        return(
            <div id='main'>
              <Tab.Container id="left-tabs" defaultActiveKey="mensagens">
                <div className='content'>
                  <div className="header">
                    <Nav variant="pills" className="chat">
                      <Col>
                        <Nav.Item>
                          <Nav.Link eventKey="mensagens">Mensagens - Suporte</Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col>
                        <Nav.Item>
                          <Nav.Link eventKey="passo_a_passo">Passo-a-Passo</Nav.Link>
                        </Nav.Item>
                      </Col>
                    </Nav>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="mensagens">
                      {isLoggedIn ? 
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50%' }}>
                          <FontAwesomeIcon icon={faSync} size="lg" spin />
                        </div>
                        :
                        <div className="listaMensagens">
                        <Messages
                           messages={this.state.messages}
                           currentMember={this.state.member}
                        />
                        <Input
                          onSendMessage={this.onSendMessage}
                        />
                        </div>
                    }
                    </Tab.Pane>
                    <Tab.Pane eventKey="passo_a_passo">
                      <br/>  
                      <div className="passo1 row">
                        <div className="passoTexto col-md-5">  
                          <h5 className="colorTextoPasso1 text-center">1º PASSO:</h5>
                          <h6 className="colorTextoPasso1 text-center">Insira todos os seus dados pessoais 
                            para iniciar o processo de compra.</h6>
                        </div> 
                        <div className="col-md-2">
                          <img src={passo1} alt=""/>
                          {/* <FontAwesomeIcon icon={faAddressCard} size="lg" color="#fff" /> */}
                        </div>
                        <div className="alert alert-dark col-md-5" role="alert">
                          <ul>
                            <li>texto aqui</li>
                            <li>texto aqui</li>
                            <li>texto aqui</li>
                          </ul>
                        </div>
                      </div>
                      <div className="passo2 row">
                        <br/>   
                        <div className="passoTexto col-md-5">
                          <h5 className="colorTextoPasso2">2º PASSO:</h5>
                          <h6 className="colorTextoPasso2">Agora anexe seus documentos para validação dos 
                          seus dados pessoais, endereço e comprovação de renda.</h6>
                        </div> 
                        <div className="col-md-2">
                          <img src={passo2} alt=""/>
                        </div>
                      </div>
                      <div className="passo3 row">
                        <br/>     
                        <div className="passoTexto col-md-5">  
                          <h5 className="colorTextoPasso3">3º PASSO:</h5>
                          <h6 className="colorTextoPasso3">Tudo certo com sua análise! Hora de selecionar a sua 
                          nova moradia e a forma de pagamento.</h6>
                        </div>
                        <div className="col-md-2">
                          <img src={passo2} alt=""/>
                        </div>
                      </div>
                      <div className="passo4 row">
                        <br/>     
                        <div className="passoTexto col-md-5">  
                          <h5 className="colorTextoPasso4">4º PASSO:</h5>
                          <h6 className="colorTextoPasso4">Viu como é fácil? Agora basta assinar 
                            o contrato eletronicamente e curtir seu novo imóvel.</h6>
                        </div>
                        <div className="col-md-2">
                          <img src={passo2} alt=""/>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
        )
    }
}

export default ChatDommus;