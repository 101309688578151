import React, {useRef, useState, forwardRef, useImperativeHandle} from 'react'
import './style.css'
import Collapse from 'react-bootstrap/Collapse'
import DadosCadastrais from '../../view/steps/dadosCadastrais'
import DadosEndereco from '../../view/steps/endereco'
import InfoRenda from '../../view/steps/infoRenda'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareDown, faAddressCard, faMapMarkedAlt, faCommentsDollar } from '@fortawesome/free-solid-svg-icons'


const Proponente = forwardRef((props, ref) => {

    const [openDadosCadastrais, setOpenDadosCadastrais] = useState(true);
    const [openEndereco, setOpenEndereco] = useState(false);
    const [openInfoRenda, setOpenInfoRenda] = useState(false);

    const dadosCadRef = useRef()

    //const enderecoRef = useRef()

    //console.log('props proponente', props);
    function scrollAuto() {
       
        //document.getElementById('collapse-renda').scrollBy(0, 1500)
       // window.scrollBy(0, 300);
    }

    useImperativeHandle(ref, () => ({
        refVericaDadosCadastrais(dado, estado){
            if(dado !== 'dados_cadastrais' && dado !== 'endereco'){
                dadosCadRef.current.refValidaDadosCadastrais(dado, estado)
            }else{
                if(dado === 'dados_cadastrais'){
                    setOpenDadosCadastrais(true)
                    setOpenEndereco(false)
                    setOpenInfoRenda(false)
                }
                else {
                    setOpenEndereco(true)
                    setOpenDadosCadastrais(false)
                    setOpenInfoRenda(false)
                }
            }

        }
    }));

    // useImperativeHandle(refEndereco, () => ({
    //     refVericaEndereco(dado, estado){
    //         enderecoRef.current.refValidaEndereco(dado, estado)
    //     }
    // }));

    return(
        <div className="TabProponente">
            <div className="card DadosCads col-xs-12">
                {/* <div className="card-header" id="headingOne"> */}
                    <h5>
                        <button className="btn btn-link LinkTabCard"
                                onClick={() => { setOpenDadosCadastrais(!openDadosCadastrais); 
                                    setOpenEndereco(false); setOpenInfoRenda(false) 
                                }}
                                aria-controls="example-collapse-text"
                                aria-expanded={openDadosCadastrais}>
                            <FontAwesomeIcon style={{float: 'left', marginRight: '5px'}} size="2x" className="iconDown" icon={faAddressCard}  />
                            <p className="text-left TextoTab">
                                O primeiro passo é sabermos alguns de seus dados cadastrais:
                                <span>
                                    <FontAwesomeIcon size="2x" className="iconDown" icon={faCaretSquareDown}  />
                                </span>
                            </p> 
                        </button>
                    </h5>
                {/* </div> */}
                <Collapse in={openDadosCadastrais}>
                    <div className="expansaoCollapse" id="example-collapse-text">
                        <DadosCadastrais ref={dadosCadRef} key={0} props={props} />
                    </div>
                </Collapse>
            </div>
            {/* dados de endereço */}
            <br/>
            <div className="card DadosCads col-xs-12">
                {/* <div className="card-header" id="headingOne"> */}
                    <h5>
                        <button className="btn btn-link LinkTabCard"
                                onClick={() => {setOpenEndereco(!openEndereco);
                                    setOpenDadosCadastrais(false);
                                    setOpenInfoRenda(false)
                                }}
                                aria-controls="example-collapse-text"
                                aria-expanded={openEndereco}>
                            <FontAwesomeIcon style={{float: 'left', marginRight: '5px'}} 
                                             size="2x" 
                                             className="iconDown" 
                                             icon={faMapMarkedAlt}  />
                            <p className="text-left TextoTab">
                                <span>
                                    Informe o seu endereço residencial:
                                    <FontAwesomeIcon size="2x" className="iconDown" icon={faCaretSquareDown} />
                                </span>   
                            </p>      
                        </button>
                    </h5>
                {/* </div> */}
                <Collapse in={openEndereco} >
                    <div className="expansaoCollapse" id="example-collapse-text">
                        <DadosEndereco 
                            ref={dadosCadRef}
                            //refEndereco={enderecoRef} 
                            key={0} props={props} />
                    </div>
                </Collapse>
            </div>
            {/* dados de renda  */}
            <br/>
            <div className="card DadosCads col-xs-12">
                {/* <div className="card-header" id="headingOne"> */}
                    <h5>
                        <a href="#collapse" className="btn btn-link LinkTabCard "
                                onClick={() => {setOpenInfoRenda(!openInfoRenda);
                                    setOpenDadosCadastrais(false);
                                    setOpenEndereco(false)
                                }}
                                aria-controls="example-collapse-text"
                                aria-expanded={openInfoRenda}>
                            <FontAwesomeIcon style={{float: 'left', marginRight: '5px'}} 
                                             size="2x" className="iconDown" 
                                             icon={faCommentsDollar}  />
                            <p className="text-left TextoTab">
                                <span className="spanTextDecoration">
                                    Informe as suas fontes de renda:
                                </span>   
                                <FontAwesomeIcon size="2x" className="iconDown" icon={faCaretSquareDown} />
                            </p>      
                        </a>
                    </h5>
                {/* </div> */}

                <Collapse in={openInfoRenda} onClick={() => scrollAuto()}>
                    <div className="expansaoCollapse" id="collapse-renda">
                        <InfoRenda
                            //ref={dadosCadRef}
                            key={0}
                            props={props} />
                    </div>
                </Collapse>
            </div>
        
        </div>
    )
})

export default Proponente;