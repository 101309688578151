import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PrivateRouter from '../src/components/PrivateRoute'

import App from './view/modal'
import CardInicial from './view/card-inicial'
import Step from './view/steps'

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={App} />
                <Route path="/iniciar" component={CardInicial} />
                <Route path="/stepInicio" component={Step} />
            </Switch>
        </BrowserRouter>
    )
}