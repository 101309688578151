import React, { useState, useEffect } from 'react';
import { Card, Form, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faFilePdf, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {CircularProgressbar} from 'react-circular-progressbar'
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2';
import api from '../../../data/api'
import 'react-circular-progressbar/dist/styles.css'
import {trackPromise} from 'react-promise-tracker';
import { toUppercase } from '../../../components/InputTextMask'
import {Button} from 'react-bootstrap';
import tokenPrime from '../../../data/token'
import ModalDommus from '../../../components/Modal/index'
import './newanexo.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import  {  Document ,  Page, pdfjs  }  from  'react-pdf';
import { Base64 } from 'js-base64';
//import samplePDF from '../../../pdf/myPDF.pdf';

import PDFViewer from '../../../components/PDFViewer'
import PDFJSBackend from '../../../backends/pdfjs';

import azulSeta from '../../../assets/azul-seta.svg' 

const NewAnexo = (props) => {

  const [file, setfile] = useState('');
  const [nomeAnexo, setNomeAnexo] = useState('')

  const [porcentagem, setPorcentagem] = useState(0)

  const [show, setShow] = useState(false)

  const [controlaAnexo, setcontrolaAnexo] = useState(false)

  const [colorStatus, setcolorStatus] = useState('primary')

  const [tipoArquivo, settipoArquivo] = useState('')

  const [colorIconAnexo, setcolorIconAnexo] = useState('#007bff')

  const [msgAnexo, setmsgAnexo] = useState("")

  const [openModalVisualizacaoPDF, setopenModalVisualizacaoPDF] = useState(false)

  let headers = { headers: {
      'Authorization': tokenPrime
  }}

  let documentos = props.documentos

  const [content, setcontent] = useState()
  const [urlFile, seturlFile] = useState()
  let fileReader;
  let nomeArquivo;

  let aprovacaoDocs = 'aprovacao_prop' + props.propX
  // const [tipo, setTipo] = useState("");

  //pdf
  const [visualizarPDF, setvisualizarPDF] = useState(false)
  const  [ numPages ,  setNumPages ]  =  useState ( null ) ; 
  const  [ pageNumber ,  setPageNumber ]  =  useState ( 1 ) ;
  
  useEffect(() => {
        evolucaoDocumento()
        if(documentos[aprovacaoDocs] != 'AGUARDANDO UPLOAD'){
            setcontrolaAnexo(true)
            setPorcentagem(100)
            if(documentos[aprovacaoDocs] == 'APROVAÇÃO PENDENTE' && file == ''){
                let colorAnexo = "colorAnexo" + props.i + props.propX
                let div = document.getElementById(colorAnexo).style.border = '2px solid var(--dommus-color-2)'
                setcolorStatus('warning')
                setcolorIconAnexo('var(--dommus-color-2)')
                let divCardHeaderId = "divCardHeader"  + props.i + props.propX

                let statusDocStyle = "statusDocStyle" + props.i + props.propX
                statusDocStyle = document.getElementById(statusDocStyle).style.backgroundColor = 'var(--dommus-color-orange)'
                //let divCardHeader = document.getElementById(divCardHeaderId).style.color = 'var(--dommus-color-2)'
            }

            if(documentos[aprovacaoDocs] == 'APROVAÇÃO PENDENTE' && file != ''){
                let colorAnexo = "colorAnexo" + props.i + props.propX
                let div = document.getElementById(colorAnexo).style.border = '2px solid #17a2b8'
                setcolorStatus('info')
                setcolorIconAnexo('#17a2b8')
                let divCardHeaderId = "divCardHeader"  + props.i + props.propX

                let statusDocStyle = "statusDocStyle" + props.i + props.propX
                statusDocStyle = document.getElementById(statusDocStyle).style.backgroundColor = '#17a2b8'
                //let divCardHeader = document.getElementById(divCardHeaderId).style.color = 'var(--dommus-color-2)'
                documentos[aprovacaoDocs] = 'DOCUMENTO CARREGADO'
            }

            if(documentos[aprovacaoDocs] == 'REPROVADO'){
                let colorAnexo = "colorAnexo" + props.i + props.propX
                let div = document.getElementById(colorAnexo).style.border = '2px solid var(--dommus-color-danger)'
                setcolorStatus('danger')
                setcolorIconAnexo('var(--dommus-color-danger)')
                let statusDocStyle = "statusDocStyle" + props.i + props.propX
                statusDocStyle = document.getElementById(statusDocStyle).style.backgroundColor = 'var(--dommus-color-danger)'
                let divCardHeaderId = "divCardHeader"  + props.i + props.propX
            }

            if(documentos[aprovacaoDocs] == 'APROVADO'){
                let colorAnexo = "colorAnexo" + props.i + props.propX
                let div = document.getElementById(colorAnexo).style.border = '2px solid var(--dommus-color-verde)'
                setcolorStatus('success')
                setcolorIconAnexo('var(--dommus-color-verde)')
                let statusDocStyle = "statusDocStyle" + props.i + props.propX
                statusDocStyle = document.getElementById(statusDocStyle).style.backgroundColor = 'var(--dommus-color-verde)'
                let divCardHeaderId = "divCardHeader"  + props.i + props.propX
            }
        }else{
            let colorAnexo = "colorAnexo" + props.i + props.propX
            let div = document.getElementById(colorAnexo).style.border = '2px solid var(--dommus-color)'
            let statusDocStyle = "statusDocStyle" + props.i + props.propX
            statusDocStyle = document.getElementById(statusDocStyle).style.backgroundColor = 'var(--dommus-color)'
        }
  }, [documentos[aprovacaoDocs]])
   
    function alertaAnexos(res){
        let colorAnexo = "colorAnexo" + props.i + props.propX
        let exibeAlert = "exibeAlert"
        if(res.status == 200){
            
            let div = document.getElementById(colorAnexo).style.border = '2px solid var(--dommus-color-2)'
            
            props.editeDocumentos(props.processo.id)
            //rgb(124 245 104) a verdinha 1 real
            
            setShow(true)
            
            
            setTimeout(() => {setShow(false)}, 1000)
                
        }else{
            let exibe = document.getElementById(exibeAlert).innerHTML = <SweetAlert>{
                Swal.fire({
                    icon: 'error',
                    title: 'Falha ao carregar arquivo!',
                    showConfirmButton: false,
                    timer: 1500
                    })
                }
            </SweetAlert>
            
            exibe = document.getElementById(exibeAlert).innerHTML = ""
            
        }
    }

    function handleFileRead(e){     
        setcontent(Base64.encode(fileReader.result))
        //seturlFile(fileReader.readAsDataURL(file))
    }

    function handleFileChosen(file, idDocumento, posicao){

        let extensao = toUppercase(file.name.split('.').pop());
        
        settipoArquivo(extensao);

        
        if(extensao != 'JPEG' && extensao != 'GIF' && extensao != 'JPG' && extensao != 'PNG' 
        && extensao != 'PDF' && extensao != 'X-EPS'){
            Swal.fire(
                'Insira  um arquivo PDF ou uma imagem.',
                '',
                'error'
                )  
            return false
        }
            
        setfile(file)
        setcontrolaAnexo(true)

        //documentos[posicao].anexado = true

        fileReader = new FileReader()
        let url = fileReader.readAsDataURL(file)
        fileReader.onloadend = handleFileRead
        //fileReader.readAsText(file)
       // seturlFile(fileReader.readAsDataURL(file))
        
        //console.log(fileReader);
        setTimeout(() => { handleAnexo(file, idDocumento) }, 500)
    }

    function handleAnexo(file, idDocumento) {

        let contents = fileReader.result
        nomeArquivo = file.name
        setNomeAnexo(nomeArquivo)

    // contents =  toBase64(contents)
    // let contents = document.getElementById(props.item.id).innerHTML = fileReader.result;
    
    //let contents = document.getElementById(props.item.id).value;

        const data = new FormData()

        const options = {
            headers: {
                'Authorization': tokenPrime
            },
            onUploadProgress: (ProgressEvent) => { 
                const {loaded, total} = ProgressEvent
                setPorcentagem(Math.floor( loaded * 100 / total))
            }
        }

        let idProcesso = props.processo.id

        let proponente = props.id_proponente

        data.append('proponente', proponente)
        data.append('contents', contents)
        data.append('processo', idProcesso)
        data.append('id_documento', idDocumento)
        
        
        var files = document.getElementById("file").files;
        var formData = new FormData();
        formData.append('proponente', proponente);
        formData.append("contents", file);
        formData.append('processo', idProcesso);
        formData.append('id_documento', idDocumento);

        trackPromise( api.post("/file-vendas", formData, options).then(res => {
            alertaAnexos(res, idDocumento)
        }) )
        
    }

    const ImageThumb = ({ image }) => {
        return <img className="thumbImg" src={URL.createObjectURL(image)} alt={image.name} />;
    };

    function evolucaoDocumento(){
        if(documentos[aprovacaoDocs] == 'AGUARDANDO UPLOAD'){
            setmsgAnexo("CARREGAR DOCUMENTO")
        }else if(documentos[aprovacaoDocs] == 'DOCUMENTO CARREGADO' || documentos[aprovacaoDocs] == 'APROVAÇÃO PENDENTE' || documentos[aprovacaoDocs] == 'REPROVADO'){
            setmsgAnexo("REENVIAR DOCUMENTO")
        }else{
            setmsgAnexo("")
        }
    }

    function visualizarDocumento(){
        let dados = {
            "processo": props.processo.id,
            "proponente": props.id_proponente,
            "documento": documentos.id
        }

        trackPromise(api.post("/file-url", dados, headers).then(res => {
            let retorno = res.data.url
            retorno = retorno.replace("view?usp=sharing", "preview")
           
            seturlFile(retorno)
            criaDocumentoPdf()
            setvisualizarPDF(true)
            setopenModalVisualizacaoPDF(true)
        }) .catch(error => {
            Swal.fire(
              'Não foi possível visualizar o arquivo',
              '',
              'error'
            )
          }))
    }
    
    function criaDocumentoPdf(){
        let pdf = [];

        //console.log("url", urlFile)

        // fileReader = new FileReader()
        // let url = fileReader.readAsDataURL(file)

        // var formData = new FormData();
        // formData.append('contents', file);

          pdf.push( 
            <>
            <ModalDommus open={openModalVisualizacaoPDF}
                close={() => setopenModalVisualizacaoPDF(false)}
                content={
                // <iframe src="https://drive.google.com/file/d/CODIGO_DO_ARQUIVO/preview" width="640" height="480"></iframe>
                <PDFViewer 
                    backend={PDFJSBackend}
                    src={urlFile}
                    //src='/myPDF.pdf'
                />
                }
            titulo="Visualização do arquivo carregado:"/>
            {/* <Document 
            file={samplePDF}
            onLoadSuccess = { onDocumentLoadSuccess } 
            > 
                <Page  pageNumber = { pageNumber } /> 
            </Document> 
            <p> Página { pageNumber } de { numPages } </p> 
             */}
            </>
        )

        return pdf;

    }

    function  onDocumentLoadSuccess ( {  numPages  } )  { 
        setNumPages ( numPages ) ; 
    }

  return (
    <>
        {
            visualizarPDF ?
                criaDocumentoPdf()  : <></>
        }
        <Form>
            {/* <div style={{width: '100%', height: '100%'}}>
            </div> */}
            <Card id={"colorAnexo" +  props.i + props.propX} style={{ width: '15rem', height: '24rem', marginBottom: '25px' }}>
            <Card.Header style={{height: '130px', fontWeight: '500'}} className={"text-center alert alert-" + colorStatus} role="alert"> 
                {documentos.descricao} 
                {/* <OverlayTrigger trigger="click"  
                    key={'top'} placement={'top'} overlay={
                        <Popover id="popover-basic">
                            <Popover.Title as="h3">Popover right</Popover.Title>
                            <Popover.Content>
                            And here's some <strong>amazing</strong> content. It's very engaging.
                            right?
                            </Popover.Content>
                        </Popover>
                    }>
                    <FontAwesomeIcon className="infoDocs" icon={faInfoCircle} />
                    
                </OverlayTrigger> */}

            </Card.Header>
            <Card.Body className="card_arquivo_body">
                {
                    !controlaAnexo ?
                    <>
                        <p className="text-center">
                            <img src={azulSeta} style={{height: '3.5em'}} alt=""/>
                            {/* <FontAwesomeIcon className="tamIcon" icon={faUpload} size="3x" color="#007bff" /> */}
                            <br/>
                        </p>
                        <p className="paragrafoAnexar text-center">ANEXAR</p>  
                    </> :
                    <>
                        <span className="text-center">
                        {
            
                            controlaAnexo && porcentagem != 100 ?

                            <p className="text-center">
                                <CircularProgressbar
                                    className="tamIcon text-center"
                                    styles={{
                                        root: {width: 60},
                                        path: { stroke: '#3e98c7'}
                                    }}
                                    value={porcentagem}
                                    text={`${porcentagem}%`}
                                /> 
                            </p> :                  
                            <>
                                <p style={{marginTop: '10px'}} className="paragrafoAnexar text-center">
                                    {
                                        tipoArquivo == '' || tipoArquivo == 'PDF' ?
                                        <>
                                            <FontAwesomeIcon className="text-center" 
                                                        icon={faFilePdf} 
                                                        size="3x" 
                                                        color={colorIconAnexo}
                                                        />
                                            <br/>
                                            DOCUMENTO ANEXADO
                                        </> :

                                        file && <> <ImageThumb image={file} /> 
                                        <br/> 
                                            DOCUMENTO ANEXADO
                                        </>
                                    }
                                </p>
                            </>
                        }
                        </span>
                    </>
                }
            </Card.Body>
                <ListGroup variant="flush">  
                {
                    documentos[aprovacaoDocs] == 'APROVADO' ?
                    <></> :
                    // <div className="custom custom-file">
                    //     <input id="file" type="file" 
                    //         className="custom-file-input"
                    //         accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    //         onChange={
                    //             e =>  { 
                    //                 handleFileChosen(e.target.files[0], documentos.id, props.i)
                    //             }
                    //         }
                    //     />
                    //     <label data-browse={msgAnexo} 
                    //     className="custom-file-label"></label>
                    // </div>
                    <label className="custom-file-upload">
                        <input id="file" className="newFileStyle" 
                            type="file"
                            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                            onChange={
                                e =>  { 
                                    handleFileChosen(e.target.files[0], documentos.id, props.i)
                                }
                            }
                        />
                        {msgAnexo}
                    </label>
                }       
                {
                    file != '' ?
                    <>
                        <Button style={{marginTop: '5px'}} 
                            variant="light" onClick={() => visualizarDocumento()}>
                            VISUALIZAR DOCUMENTO</Button>
                    </>
                    : <></>
                }
                    <ListGroup.Item id={"statusDocStyle" + props.i + props.propX} 
                        style={{textAlign: 'center', color: 'var(--dommus-color-white)'}}> {documentos[aprovacaoDocs] } </ListGroup.Item>
                </ListGroup>
                {/* <Button style={{marginTop: '5px'}} variant="primary" onClick={onDelete}>Excluir</Button>
                <Button style={{marginTop: '5px'}} variant="danger" onClick={onDelete}>Excluir</Button> */}
            </Card>
        </Form>
    </>
  );

};

export default NewAnexo;