import React, {useState, useEffect} from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import InputCpf from '../../../../components/InputCpf'
import {toUppercase, cpfMask} from '../../../../components/InputTextMask'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Swal from 'sweetalert2'

export default function RegimeComunhaoModal(props){
    const [cpf, setCpf] = useState('')
    const [nome, setNome] = useState('')

    const [estado_civil, setEstado_civil] = useState('')
    const [selectEstadoCivil, setSelectEstadoCivil] = useState([])
    const [regime_comunhao, setRegime_comunhao] = useState('')
    const [selectRegimeComunhao, setSelectRegimeComunhao] = useState([])

    const [habilitaRegimeComunhao, setHabilitaRegimeComunhao] = useState(false)
    //const habilitaRegimeComunhao = []


    const [erro, seterro] = useState('')

    useEffect(() => {

        if(props.dadosSelect.preencheDadosSelect !== undefined){     
            setSelectEstadoCivil(props.dadosSelect.preencheDadosSelect.estadoCivil) 
            setSelectRegimeComunhao(props.dadosSelect.preencheDadosSelect.regimeComunhao)
        }

     }, [])

    function editarCpf(cpfNumber){
        setCpf(cpfNumber)
    }

    function addConjuge(){
        let conjuge = {
            cpf: cpf,
            nome: nome
        }
        props.closeModal()
       // props.props.props.addNovoComprador(conjuge)
    }

    function verificaEstadoCivil(target, posicao){
        if(target === '2' || target === '3'){
            //setHabilitaCpf(true)
            setHabilitaRegimeComunhao(true)
        }else{
            props.proponentes.map((item, i) => {
                props.editeProponente(posicao, "conjuge", "regime_comunhao", 0)
            })
            setHabilitaRegimeComunhao(false)
        }
    }

    function verificaRegimeComunhao(){
        let contRegimeComunhao = 0;

        // props.proponentes.map((item, i) => {
        //     if(item.conjuge.estado_civil === 2 || item.conjuge.estado_civil === 3){
        //         if( item.conjuge.regime_comunhao == 1){
        //             contRegimeComunhao ++
        //         }else{
    
        //             contRegimeComunhao = 0
        //         }
        //         if( item.conjuge.regime_comunhao == 2){
        //             contRegimeComunhao ++
        //         }else{
    
        //             contRegimeComunhao = 0
        //         }
    
        //         if( item.conjuge.regime_comunhao == 3){
        //             contRegimeComunhao ++
        //         }else{
    
        //             contRegimeComunhao = 0
        //         }
    
        //         if( item.conjuge.regime_comunhao == 4){
        //             contRegimeComunhao ++
        //         }else{
    
        //             contRegimeComunhao = 0
        //         }
        //     }
        // })

        if(((contRegimeComunhao % 2)  === 0)){
            return true
        }else{
            return false
        }
    }

    function salvaEstadoCivil(){
        let verifica = false;

        let verificaRegime = verificaRegimeComunhao()

        verifica = props.validaRegimeComunhao()

        // if(!verificaRegime){
        //     Swal.fire(
        //         'O sistema não permite a compra de uma pessoa em regime de comunhão sem a inclusão do seu cônjuge.',
        //         '',
        //         'error'
        //       )
        //     this.setState({
        //       erro: 'Erro no regime de comunhão dos compradores.'
        //     })
        //   }

        if(verifica){
            props.closeModal()
            props.salvarDadosProponente()
        }else{
            // if(!verificaRegime){
                Swal.fire(
                    'O sistema não permite a compra de uma pessoa em regime de comunhão sem a inclusão do seu cônjuge.',
                    'Verifique se adicionou os compradores cônjuges com as informações corretas.',
                    'error'
                ).then(response => {

                    if(props.proponentes.length >= 2){
                        Swal.fire(
                            'Verifique o estado civil e regime de comunhão dos compradores.',
                            "",
                            'warning'
                        )
                    }else{
                        props.closeModal()

                        Swal.fire({
                            title: 'Deseja adicionar um novo comprador ?',
                            text: "",
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                props.addNovoComprador()
                            }
                        })
                    }
                })

            //}
            seterro('obrigatório*')
        }
    }

    return(
    <>    
                    <div className="form-group row"> 
    
                        {/* <InputCpf col={'col-xs-12 col-md-4'} 
                            editeCpf={editarCpf.bind(this)} 
                        />           */}
                        <div className="col-xs-12 col-md-12 col-lg-6">
                            
                        <label className="col-form-label">{props.index +1}º Comprador: </label>
                            <input type="text" 
                                className="form-control uppercase" 
                                id={'nomeProponente'} 
                                placeholder="Digite seu nome"
                                disabled
                                value={toUppercase(props.proponente.dados_cadastrais.nome) + ' - ' + cpfMask(props.proponente.dados_cadastrais.cpf)}
                            />
                        </div>
                        <div className="col-xs-12 col-md-12 col-lg-3">   
                            <label className="col-form-label">Estado Civil: <span className="help-text">*</span></label>   
                            <select className="form-control custom-select"
                                id={'estado_civilProponente'}
                                value={props.proponente.conjuge.estado_civil}
                                onChange={e => {
                                    setEstado_civil(e.target.value)
                                    props.editeProponente(props.index, "conjuge", "estado_civil", e.target.value)
                                    verificaEstadoCivil(e.target.value, props.index)
                                
                                }}
                            >
                                <option hidden >---SELECIONE---</option>
                                {   
                                    selectEstadoCivil.map((item, index) => (
                                        <option
                                        key={index}
                                        value={item.id} >
                                        {item.descricao}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        { habilitaRegimeComunhao || props.proponente.conjuge.regime_comunhao != 0 ? 
                            <>
                                <div className="col-xs-12 col-md-12 col-lg-3">       
                                    <label className="col-form-label">Regime Comunhão: <span className="help-text">*</span>
                                    </label>
                                    <OverlayTrigger
                                    trigger="click"
                                    key={'top'}
                                    placement={'top'}
                                    overlay={
                                    // <Tooltip>
                                            <Popover id={`popover-positioned-${'top'}`}>
                                            <Popover.Title as="h3" style={{backgroundColor: '#007bff', color: '#fff'}}>{`Fique por dentro`}</Popover.Title>
                                            <Popover.Content>
                                                O sistema permite a inclusão de um casal 
                                                que pode adicionar até mais 1(um) comprador desde que esse não possua regime de comunhão! 
                                                Do contrário todos os compradores não podem ter regime de comunhão.
                                                <br/>
                                                obs: Não é possível fazer a aquisição de um imóvel sem o seu conjuge. 
                                            </Popover.Content>
                                            </Popover>
                                        //</Tooltip>
                                    }
                                    >
                                    <FontAwesomeIcon className="margemEsqueda5 cursorPointer" variant="secondary" 
                                                    size={'lg'} color={'#007bff'} icon={faInfoCircle} />
                                    </OverlayTrigger>
                                    <select className="form-control custom-select"
                                            id={'regime_comunhaoProponente' }
                                            value={props.proponente.conjuge.regime_comunhao}
                                            onChange={e => {
                                                props.editeProponente(props.index, "conjuge", "regime_comunhao", e.target.value)                       
                                            }}>
                                    <option hidden >---SELECIONE---</option>
                                    {   
                                        selectRegimeComunhao.map((item, indice) => (
                                            <option
                                            key={indice}
                                            value={item.id} >
                                            {item.descricao}
                                            </option>
                                        ))
                                    }
                                    </select>
                                </div>
                            </> :

                            <div className="col-xs-12 col-md-12 col-lg-3">       
                                <label className="col-form-label"> Regime Comunhão:</label>
                                <select className="form-control custom-select"
                                        disabled
                                        value={props.proponente.conjuge.regime_comunhao}
                                        >
                                    <option hidden >---SELECIONE---</option>
                                </select>
                            </div>
                        }
                    </div>
        {
            props.index == props.proponentes.length -1 ? 
            <div className="form-group row">
                <div className="col-md-8 col-xs-12">
                    <span>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={faInfoCircle} color="#007bff" />
                        Selecione o regime de comunhão caso tenha cônjuge
                    </span>
                </div>      
                <div className="col-xs-12 col-md-4">
                    <button type="button" className="btn btn-primary float-right" onClick={() => salvaEstadoCivil()}>
                        <span>
                            Salvar
                            <FontAwesomeIcon style={{ marginLeft: 10 }} 
                                            icon={faAngleDoubleRight} 
                                            color="white">
                            </FontAwesomeIcon>    
                        </span> 
                    </button>
                </div>
            </div> : <></>
        }
    </>
    )
}