import React, { useEffect, useRef } from 'react';
import './style.css';
import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faCartPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import api from '../../data/api'
import SelectEmpreendimento from '../../components/selectEmpreendimentos'
import { formatarData } from '../../components/InputTextMask/'
import tokenPrime from '../../data/token'
import {trackPromise} from 'react-promise-tracker'

export default function Token(props){

    const [token, setToken] = useState('')  
    const [processos, setprocessos] = useState([]) 
    const [erroToken, setErroToken] = useState('')
    const [exibirToken, setexibirToken] = useState(true)
    const [verificatoken, setverificatoken] = useState(false)
    const [exibeSolicitarToken, setexibeSolicitarToken] = useState(true)
    const [openNovacompraProponenteExistente, setOpenNovacompraProponenteExistente] = useState(false);
    const [nomeEmprendimento, setnomeEmprendimento] = useState('')

    let headers = { headers: {
        'Authorization': tokenPrime
    }}

    const childRefToken = useRef()

    useEffect(() => {
        setprocessos(props.processos)


    }, [])

    function geraCodigoEmail(){
  
        let cpfNumero = {cpf: props.cpf};
        
        trackPromise(
            api.post('/mail', cpfNumero, headers).then(response => {
            Swal.fire({
                position: 'right',
                icon: 'success',
                title: 'Código de acesso enviado!',
                showConfirmButton: false,
                timer: 1500
            })
            
            }) .catch(error => {
            Swal.fire(
                'Não foi possível gerar código, tente novamente mais tarde',
                '',
                'error'
            )
            })
        )
    }

    function verificaToken(codigo){

        let parametros = {
            cpf: props.cpf,
            token: codigo
        };
        
        trackPromise(api.post('/mail/usertoken', parametros, headers).then(response => {
            setverificatoken(true)
            setexibirToken(false)
        //   Swal.fire({
        //     position: 'center',
        //     icon: 'success',
        //     title: 'Código de acesso enviado!',
        //     showConfirmButton: false,
        //     timer: 1500
        //   })
          
        }) .catch(error => {
          Swal.fire(
            'Código de acesso inválido',
            '',
            'error'
          )
        }))
        
    }

    function acessarProcesso(e, id){
        e.preventDefault()
        
        let arrayId = {id: 0};
        arrayId.id = id
       
        trackPromise(api.post('/processo-completo', arrayId, headers).then(response => {
            
            const data = response.data
          
            localStorage.setItem('dadosProcesso', JSON.stringify(data))

            let a = document.createElement('a')
        
            a.setAttribute('href', '/iniciar');
            
            a.setAttribute('target', '_blank');
            
            document.body.appendChild(a);

            a.click();
            
        }) .catch(error => {
            Swal.fire(
                'Não foi possível carregar o processo',
                '',
                'error'
            )
        }))
    }

    function showEmpreendimento(id){
        let idEmpreendimento ={
            id: id
        }
        trackPromise(api.post('/empreendimento', idEmpreendimento, headers).then(response => {
            setnomeEmprendimento(response.data[1].nome)
        }))
    }

    const carregaProcessos = () => {
       
        let montaProcessos = []
    
        processos.map( (processo, i) =>{
            showEmpreendimento(processo.empreendimento)
            montaProcessos.push(  
                <div key={i} className="col-md-12 text-center">          
                    <span className="p-modal p-listaProcessos">
                        <p>
                            Atualizado em:
                        </p>
                        {
                            formatarData(processo.cadastrado_em)
                        }
                        <p>
                            Empreendimento:
                        </p>
                        {
                            nomeEmprendimento
                        }
                        <p>
                            Unidade:
                        </p>
                        {
                            processo.unidade
                        }
                        <button 
                            className="btn avancar"
                            onClick={ (e) => acessarProcesso(e, processo.id) }>
                            <span>
                                Acessar Processo 
                                <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faAngleDoubleRight} color="white">
                                </FontAwesomeIcon>
                            </span> 
                        </button>           
                    </span>
                    <br/>
                </div>
            )
        })

        return montaProcessos
    }

    function valida(e){
        return e;
    }

    function novaCompraProcessoExistente(e){

        e.preventDefault()
        
        let cpfNumero = {cpf: ''};
        cpfNumero.cpf = props.cpf.replace(/[^\d]+/g,'')
        
        let valida = false;

        valida = childRefToken.current.refValidaForm()

        if(valida){

            trackPromise(api.post('/proponente-cpf', cpfNumero, headers).then(response => {

                //console.log("cara caramba", response)
                
                let ArrayProcesso = {
                    id: 0,
                    proponente1: 0,
                    proponente2: 0,
                    proponente3: 0,
                    empreendimento: 0 || localStorage.getItem('empreendimento_index'),
                    tipo_unidade: 0 || localStorage.getItem('tipologia_index'),
                    unidade: 0,
                    evolucao: 0,
                    etapa_workflow: 0,
                    workflow: 0 
              
                } 
          
                  let ArrayProponentes = [{
                    dados_cadastrais: {   
                      id: 0,
                      cpf: "" || response.data[0].cpf,
                      nome: "" || response.data[0].nome,
                      data_nascimento: "",
                      genero: "",
                      tipo_documento: 0,
                      numero_documento: "",
                    },
                    endereco: {
                      id: 0,
                      numero: "",
                      cep: "",
                      tipo_logradouro: 0,
                      logradouro: "",
                      complemento: "",
                      bairro: "",
                      cidade: "" || localStorage.getItem('cidade'),
                      uf: "" || localStorage.getItem('uf'),
                    },
          
                    info: {
                      id: 0,
                      proponente: 0 ,
                      grau_instrucao: 0,
                      utilizacao_fgts: ""
                    },
          
                    conjuge: {
                      id: 0, // || dadosProcesso.proponente[i].conjuge.id,
                      proponente1: 0, // || dadosProcesso.proponente[i].conjuge.proponente1,
                      proponente2: 0, // || dadosProcesso.proponente[i].conjuge.proponente2,
                      estado_civil: 0, //|| dadosProcesso.proponente[i].conjuge.estado_civil,
                      regime_comunhao: 0, // || dadosProcesso.proponente[i].conjuge.regime_comunhao,
                    },
          
                    contato: [{
                      id: 0,
                      proponente: 0,
                      tipo_contato: "E" || response.data[0].email,
                      valor: ""
                    },
                    {
                        id: 0,
                        proponente: 0,
                        tipo_contato: "S",
                        valor: "" || response.data[0].celular
                    }
                    ],
                    
                    renda: [
                        {
                            id: 0,
                            proponente: 0,
                            tipo_renda: 0,
                            cargo: "",
                            outra_profissao: "",
                            data_inicio: "",
                            valor: ""
                        },
                        {
                            id: 0,
                            proponente: 0,
                            tipo_renda: 5,
                            cargo: "",
                            outra_profissao: "",
                            data_inicio: "",
                            valor: ""
                        },
                    ]
              
                  }]
                  
                let dadosProcesso = {
                   
                    "processo": ArrayProcesso,
                    "proponente": ArrayProponentes
                    
                }

                dadosProcesso = JSON.stringify(dadosProcesso)
                

                localStorage.clear();
                    
                localStorage.setItem('dadosProcesso', dadosProcesso)

                let a = document.createElement('a')
            
                a.setAttribute('href', '/iniciar');
            
                a.setAttribute('target', '_blank');
            
                document.body.appendChild(a);
                a.click()
            
                //history.push('/iniciar')

            }) .catch(error => {
                Swal.fire(
                'Não foi possível verificar o cadastro do comprador',
                '',
                'error'
                )
            }))
        }
    }

    function abreCollapse(){
        setOpenNovacompraProponenteExistente(!openNovacompraProponenteExistente)
    }

    return (
        <>
            {
                exibirToken ?

                <div className="form-group row">
                        <div className="col-xs-12 col-md-12 col-lg-3"></div>
                        <div className="col-xs-12 col-md-12 col-lg-4">
                            
                            <input className="form-control" 
                                required
                                type="text"
                                maxLength="4"
                                value={token}
                                onChange={e => {
                                    let v = e.target.value.toUpperCase()
                                    setToken(v)
                                }} />

                        </div>
                        <div className="col-md-12 col-lg-2">
                            <button  
                                className="btn avancar"
                                onClick={(e) => {
                                    e.preventDefault()
                                    if(token.length === 4){
                                        verificaToken(token)
                                        setErroToken('')
                                    }else{
                                        setErroToken('Código Inválido')
                                    }
                                }
                                }>
                                    <span>
                                        Avançar
                                        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faAngleDoubleRight} color="white">
                                        </FontAwesomeIcon>
                                    </span> 
                            </button>           
                        </div>  
                        <div className="col-md-12 col-lg-2">
                            <p className="help-text">{erroToken}</p>
                        </div>
                        <div className="col-md-12">
                            <br/>
                        </div>
                        <div className="col-xs-12 col-md-12 col-lg-3"></div> 
                       
                        <div className="col-xs-12 col-md-12 col-lg-5">
                            <span style={{marginRight: '10px'}}>Enviar novo código para o e-mail cadastrado:</span>
                            {
                                exibeSolicitarToken ?
                                <>
                                <button className="btn btn-sm estiloNvCod"
                                        onClick={e => {
                                            geraCodigoEmail()
                                            setexibeSolicitarToken(false)
                                            setTimeout(() => {
                                                setexibeSolicitarToken(true)
                                            }, 120000);
                                        }}>
                                    Enviar
                                </button> </> :
                                <>
                                    <button className="btn btn-sm estiloNvCod"
                                            disabled
                                            style={{cursor: 'no-drop'}}>
                                        Enviar
                                    </button>   
                                    {/* <div className="col-md-1 float-right">
                                        <FontAwesomeIcon style={{ marginTop: 5, marginLeft: -30}} color="white" size={'2x'} icon={faSpinner} spin></FontAwesomeIcon>
                                    </div> */}
                                </>
                            }
                           
                        </div>
                </div> : <></>
            }

            <div className="form-group row">
                <br/>
                {
                    verificatoken ? 
                    <>
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                            <p className="p-modal text-center">
                                Você tem o(s) seguinte(s) processo(s) em andamento: 
                            </p>
                        </div>
                        <br/> <br/>
                        {carregaProcessos()}
                        <br/> <br/> 
                        <div className="col-md-6">
                            <hr/>
                            <button type="button" className="btn btn-primary" onClick={abreCollapse}>
                                    <span>
                                        Nova Compra 
                                        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCartPlus} color="white">
                                        </FontAwesomeIcon>
                                    </span> 
                            </button>                             
                        </div>  
                        <div className="col-md-12">
                            <Collapse in={openNovacompraProponenteExistente}>
                                <div className="expansaoCollapse" id="example-collapse-text">
                                    <SelectEmpreendimento ref={childRefToken} />
                                    <button type="button" onClick={novaCompraProcessoExistente} className="iniciarNovaCompra float-right">
                                        INICIAR NOVA COMPRA
                                        <span>
                                        <FontAwesomeIcon style={{ marginLeft: 10 }} 
                                                            icon={faAngleDoubleRight} 
                                                            color="white">
                                        </FontAwesomeIcon>
                                        </span> 
                                    </button>
                                </div>
                            </Collapse>
                        </div>
                    </>
                    
                    : <></>
                }
            </div>
        </>      
    )
}