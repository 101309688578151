import React, {useEffect} from 'react'
import './style.css'
import CalloutDommus from '../../../components/CalloutDommus'
import { toUppercase } from '../../../components/InputTextMask'

export default function Contrato(){

    useEffect(() => {

        let callout = document.getElementsByClassName('bs-callout');
        let div = document.getElementsByClassName('bs-callout');

        for(let i=4; i<callout.length; i++) {
            callout[i].style.borderLeftColor = 'var(--dommus-color-2)';
        }

        for(let i=5; i<callout.length; i++) {
            div[i].style.width = '49%'
            //div[i].style.padding = "50px";
        }


        // let selectunidade = document.getElementById('callout-1');
        
        // if(selectunidade != null)
        //     selectunidade.style.borderLeftColor = 'var(--dommus-color-2)';

    }, [])

    return(
        <div className="divContentContrato">
            <CalloutDommus
                index={5}
                //selecionado={colloutSelect}
                //passo="B"
                //titulo="Estamos quase lá"
                conteudo={
                    <>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <p className="tituloContrato">Estamos quase lá...</p>
                                {/* <br/> */}
                                <p> Gere o contrato e solicite a assinatura
                                    <br/>
                                    eletrônica para finalizar a compra do imóvel!
                                </p>
                            </div>
                            <div style={{backgroundColor: 'var(--dommus-color)'}} className="passos-contrato">
                                <p className="">
                                    1º Clique aqui para 
                                </p> 
                                <p className="font-weight-bold">
                                    Visualisar contrato
                                </p> 
                            </div>

                            <div style={{backgroundColor: 'var(--dommus-color-orange)'}} className="passos-contrato">
                                <p className="">
                                    2º Clique aqui para 
                                </p> 
                                <p className="font-weight-bold">
                                    Assinar contrato
                                </p> 
                            </div>

                            <div style={{backgroundColor: 'var(--dommus-color-verde)'}} className="passos-contrato">
                                <p className="">
                                    3º Clique aqui para 
                                </p> 
                                <p className="font-weight-bold">
                                    Imprimir contrato
                                </p> 
                            </div>
                        </div>
                    </>
                } />
            <br/>
            <p className="pConteudoContrato pInfoCompra text-left">Informações sobre a compra:</p> 
            <div className="divInfoContrato">
                <CalloutDommus 
                    className="calloutContrato"
                    index={6}
                    titulo="Promitente Vendedora"
                    conteudo={
                        <div className="pConteudoContrato">
                            <strong>{toUppercase('villa empório natanael condomínio empreendiments ltda')}</strong>
                            <p> <strong>CNPJ:</strong> 37.702.589/0001-94</p>
                            <p> <strong>Endereço:</strong> Avenida Paulo Moreira, 144.</p>
                            <p> <strong>Bairro:</strong> Santo Agostinho</p>
                            <p> <strong>Cidade:</strong> Belo Horizonte - MG</p>
                            <p> <strong>CEP:</strong> 30.150-001</p>
                        </div>
                    }
                />

                <CalloutDommus 
                    className="calloutContrato"
                    index={7}
                    titulo="Promissário Comprador"
                    conteudo={
                        <div className="pConteudoContrato">
                            <strong>{toUppercase('Isabela Letícia Silva de Aquino')}</strong>
                            <p> <strong>CPF:</strong> 37.702.589/0001-94</p>
                            <p> <strong>Endereço:</strong> Avenida Paulo Moreira, 144.</p>
                            <p> <strong>Bairro:</strong> Santo Agostinho</p>
                            <p> <strong>Cidade:</strong> Belo Horizonte - MG</p>
                            <p> <strong>CEP:</strong> 30.150-001</p>
                        </div>
                    }
                />
            </div>
            <br/>
            <div className="divInfoContrato">
                <CalloutDommus 
                        className="calloutContrato"
                        index={8}
                        titulo="Segundo Promissário Comprador"
                        conteudo={
                            <div className="pConteudoContrato">
                                <strong>{toUppercase('Caio Castro')}</strong>
                                <p> <strong>CPF:</strong> 37.702.589/0001-94</p>
                                <p> <strong>Endereço:</strong> Avenida Paulo Moreira, 144.</p>
                                <p> <strong>Bairro:</strong> Santo Agostinho</p>
                                <p> <strong>Cidade:</strong> Belo Horizonte - MG</p>
                                <p> <strong>CEP:</strong> 30.150-001</p>
                            </div>
                        }
                    />

                    <CalloutDommus 
                        className="calloutContrato"
                        index={9}
                        titulo="Financiamento"
                        conteudo={
                            <div className="pConteudoContrato">
                                <p> <strong>Valor da unidade:</strong> R$ 128.000,00</p>
                                <p> <strong>FGTS:</strong> R$ 0,00</p>
                                <p> <strong>Subsídio:</strong> R$ 22.500,00</p>
                                <p> <strong>Parcela:</strong> R$ 450,00</p>
                                <p> <strong>Prazo Financiamento:</strong> X meses</p>
                            </div>
                        }
                    />
            </div>
        </div>
    )
}