import axios from 'axios';

const crmpos = axios.create({
    baseURL: process.env.REACT_APP_ENV === 'production' ? 'https://api.crmpos.dommus2.com.br' : 'https://api.crmpos.dommusbeta.com.br',
    headers: {
        'Authorization': '60e8acac8fa97183ae72b95be1a20d00745be47241adcec250869f65e077b250',
        'content-type': 'multipart/form-data'
    }
});

export default crmpos;
