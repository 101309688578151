import React, {useState, useRef, useEffect, forwardRef, useImperativeHandle} from 'react'
import './style.css'
import { realMoeda, toUppercase } from '../../../components/InputTextMask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faTools } from '@fortawesome/free-solid-svg-icons'

const InfoRenda = forwardRef((props, refRenda) => {

    const scrollRendaF = useRef()
    const scrollRendaI = useRef()
    const [exibeRendaInformal, setExibeRendaInformal] = useState(false)
    const [selectTipoRenda, setSelectTipoRenda] = useState([])
    const [retiraObrigatoriedade, setRetiraObrigatoriedade] = useState(false)
    const [tipoRendaFormal, setTipoRendaFormal] = useState(0)
    const [errodata, seterrodata] = useState('')

    const [renda_formal, setRenda_formal] = useState({
        value: "",
        tipo: 0,
        profissao: "",
        data_inicio: "",
        valor: ""
    })
    const [renda_informal, setRenda_informal] = useState({
        value: "",
        tipo: 5,
        profissao: "",
        data_inicio: "",
        valor: ""
    })
    let renda = props.props.proponente.renda

    useEffect(() => {
        
        props.props.proponente.renda.map((item, i) => {
            
            if(item.tipo_renda === 5){
                if(item.outra_profissao !== '')
                    setExibeRendaInformal(true)
                else setExibeRendaInformal(false)
            }
        })

 
        if(props.props.dadosSelect.preencheDadosSelect !== undefined){     
            setSelectTipoRenda(props.props.dadosSelect.preencheDadosSelect.tipoRenda) 
        }

    }, [])

    useImperativeHandle(refRenda, () => ({

        // refValidaDadosCadastrais(dado, estado){
        //     estado === '' ?
        //     document.getElementById(dado + 'Proponente' + props.props.index).style.backgroundColor = "var(--dommus-color-warning)" :
            
        //     document.getElementById(dado + 'Proponente' + props.props.index).style.backgroundColor = "var(--dommus-color-white)"
        // }  
    }));

    function scrollFormal(){
        setTimeout(() => {

            // window.scrollTo(0, 300);
            //scrollRendaF.scrollIntoView({ behavior: 'smooth', block: 'start' })
            //scrollRendaF.window.scrollBy(0, 100)
            scrollRendaI.current.style.transition = "all 5s";
            scrollRendaI.current.focus()
            scrollRendaF.current.focus()
            //document.getElementById('naoPRendaF').style.transitionDelay = "5s";
        }, 800);
    }

    function ExibeInformal(controle){
      
        if(controle === 'sim'){
            setExibeRendaInformal(true)
        }else{
            setExibeRendaInformal(false)
        }

        props.props.exibeRendaInformal(controle, props.props.index)
    }

    function tipoRendaSemRenda(target){
        
        let tipo;
        
        selectTipoRenda.map((item, i) => (
            item.id == target ? tipo = item : tipo = []
        ))

        if(tipo !== []){
           if(tipo.descricao === 'SEM RENDA'){
               setRetiraObrigatoriedade(true)
           }else{
               setRetiraObrigatoriedade(false)
           }
        }
    }

    function dataAtual(){
        let today = new Date();
        let anoAtual = today.getFullYear();
        let mes = today.getMonth() + 1;
        let dia = today.getDate();

        if(dia <= 9){
            dia = "0" + dia
        }
    
        let d = anoAtual + "-" + mes + '-' + dia;

        return d
    }

    function validaMinData(){
        let today = new Date();
        let anoAtual = today.getFullYear();
        let mes = today.getMonth() + 1;
        let dia = today.getDate();
        let anoLimite

        if(dia <= 9){
            dia = "0" + dia
        }

        anoLimite = anoAtual - 50
    
        let d = anoLimite + "-" + mes + '-' + dia;

        return d
    }

    return(
        <>
            {
                renda.map((rendaItem, index) => (

                    rendaItem.tipo_renda === 0 ?
                    <> 
                        <div className="form-group row">
                            <div id="inputRadioValidation" className="col-xs-12 col-md-12 text-center">
                                <p className="pRenda">
                                    <FontAwesomeIcon style={{marginRight: '5px'}}
                                        size="2x"
                                        icon={faUserTie}
                                        color="#f19d1f"  />
                                        RENDA FORMAL - INFORME SUA RENDA 
                                </p>
                                <br/>
                            </div>
                                <>

                                    <div className="col-xs-12 col-md-12 col-lg-3">       
                                        <label>Tipo: <span className="help-text">*</span></label>
                                        <select className="form-control custom-select"
                                                ref={scrollRendaF}
                                                value={tipoRendaFormal}
                                                onChange={e => {
                                                setTipoRendaFormal(e.target.value)
                                                rendaItem.tipo_renda = tipoRendaFormal
                                                tipoRendaSemRenda(e.target.value)
                                                setRenda_formal(e.target.value)
                                                props.props.editeProponenteParametro(props.props.index, "renda", 
                                                    index, "tipo_renda", e.target.value)}}
                                        >
                                            <option hidden >---SELECIONE---</option>
                                            {
                                                selectTipoRenda.map((item, i) => (
                                                
                                                    item.descricao !== 'INFORMAL' ? 
                                                    <option
                                                        key={i}
                                                        value={item.id}
                                                    >
                                                        { 
                                                            item.descricao
                                                        }
                                                    </option> :
                                                <option hidden ></option> 
                                                    
                                                ))
                                            }
                                        </select>
                                    </div>

                                    {  retiraObrigatoriedade ? 
                                        <>
                                            <div className="col-xs-12 col-md-12 col-lg-3">       
                                                <label> Profissão / Cargo:</label>
                                                <input type="text"
                                                    className="form-control uppercase" 
                                                    placeholder="Qual é a sua profissão ?"
                                                    value={toUppercase(rendaItem.cargo)}
                                                    onChange={e => {
                                                    rendaItem.cargo = e.target.value
                                                    setRenda_formal(e.target.value)
                                                    props.props.editeProponenteParametro(props.props.index, 
                                                        "renda", index, "cargo", toUppercase(e.target.value))}} />
                                            </div> 
                                            <div className="col-xs-12 col-md-12 col-lg-2">       
                                                <label > Início da Renda:</label>
                                                <input type="date" 
                                                    max={dataAtual()}
                                                    min={validaMinData()}
                                                    className="form-control" 
                                                    placeholder="Data de ínicio da renda"
                                                    value={rendaItem.data_inicio}
                                                    onBlur={e => {
                                                        let max = dataAtual
                                                        let min = validaMinData()
                                                        if(e.target.value <= max && e.target.value >= min){
                                                            setRenda_formal(e.target.value)
                                                            seterrodata('')
                                                            props.props.editeProponenteParametro(props.props.index, 
                                                                "renda", index, "data_inicio", e.target.value)   
                                                        }else{
                                                            e.target.value = ''
                                                            setRenda_formal(e.target.value)
                                                            seterrodata('data inválida')
                                                        }
                                                    }}
                                                    onChange={e => {
                                                    setRenda_formal(e.target.value)
                                                    props.props.editeProponenteParametro(props.props.index, 
                                                        "renda", index, "data_inicio", e.target.value)}} />
                                            </div> 
                                            <p className="help-text">{errodata}</p>
                                            <div className="col-xs-12 col-md-12 col-lg-2">       
                                                <label > Valor da Renda:</label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    placeholder="R$ 0,00"
                                                    maxLength="12"
                                                    value={realMoeda(rendaItem.valor)}
                                                    onChange={e => {
                                                        rendaItem.valor = e.target.value
                                                        setRenda_formal(e.target.value)
                                                        let nvRenda = e.target.value.replace(/\D/g, '')
                                                        nvRenda = nvRenda.replace(/([0-9]{2})$/g, ".$1")
                                                        props.props.editeProponenteParametro(
                                                            props.props.index, "renda", index, "valor", nvRenda) }} />
                                            </div>
                                            <div className="col-xs-12 col-md-12 col-lg-2">
                                                <label>Pretende usar FGTS? <span className="help-text">*</span></label>                   
                                                <select className="form-control custom-select"
                                                    value={props.props.proponente.info.utilizacao_fgts}
                                                    onChange={e => {
                                                        props.props.editeProponente(
                                                            props.props.index, "info", 
                                                            "utilizacao_fgts", e.target.value
                                                        )
                                                    }}
                                                >
                                                    <option hidden>---SELECIONE---</option>
                                                    <option value="S">SIM</option>
                                                    <option value="N">NÃO</option>
                                                </select>
                                            </div>
                                        
                                        </> : 
                                        
                                        <>
                                            <div className="col-xs-12 col-md-12 col-lg-3">       
                                                <label >Profissão / Cargo: <span className="help-text">*</span></label>
                                                <input type="text"
                                                    className="form-control uppercase" 
                                                    placeholder="Qual é a sua profissão ?"
                                                    value={toUppercase(rendaItem.cargo)}
                                                    onChange={e => {
                                                    rendaItem.cargo = e.target.value
                                                    setRenda_formal(e.target.value)
                                                    props.props.editeProponenteParametro(props.props.index, 
                                                        "renda", 
                                                        index,
                                                        "cargo", toUppercase(e.target.value))}} />
                                            </div> 
                                            <div className="col-xs-12 col-md-12 col-lg-2">       
                                                <label >Início da Renda: <span className="help-text">*</span></label>
                                                <input type="date" 
                                                    max={dataAtual()}
                                                    min={validaMinData()}
                                                    className="form-control" 
                                                    placeholder="Data de ínicio da renda"
                                                    defaultValue={rendaItem.data_inicio}
                                                    onBlur={e => {
                                                        let max = dataAtual
                                                        let min = validaMinData()
                                                        if(e.target.value <= max && e.target.value >= min){
                                                            setRenda_formal(e.target.value)
                                                            props.props.editeProponenteParametro(props.props.index, 
                                                                "renda", index, "data_inicio", e.target.value)   
                                                            seterrodata('')
                                                        }else{
                                                            e.target.value = ''
                                                            setRenda_formal(e.target.value)
                                                            seterrodata('data inválida')
                                                        }
                                                    }}
                                                    onChange={e => {
                                                    setRenda_formal(e.target.value)
                                                    props.props.editeProponenteParametro(props.props.index, "renda", 
                                                        index,"data_inicio", e.target.value)}} />
                                                    <p className="help-text">
                                                        {errodata}
                                                    </p>
                                            </div> 
                                            <div className="col-xs-12 col-md-12 col-lg-2">       
                                                <label >Valor da Renda: <span className="help-text">*</span></label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    placeholder="R$ 0,00"
                                                    maxLength="12"
                                                    value={realMoeda(rendaItem.valor)}
                                                    onChange={e => {
                                                        rendaItem.valor = e.target.value
                                                        setRenda_formal(e.target.value)
                                                        let nvRenda = e.target.value.replace(/\D/g, '')
                                                        nvRenda = nvRenda.replace(/([0-9]{2})$/g, ".$1")
                                                        props.props.editeProponenteParametro(props.props.index, 
                                                            "renda", index, "valor", nvRenda) }} />
                                            </div>
                                            <div className="col-xs-12 col-md-12 col-lg-2">
                                                <label>Pretende usar FGTS? <span className="help-text">*</span></label>                   
                                                <select className="form-control custom-select"
                                                    value={props.props.proponente.info.utilizacao_fgts}
                                                    onChange={e => {props.props.editeProponente(
                                                        props.props.index, "info", "utilizacao_fgts", e.target.value
                                                    )}}>
                                                    <option hidden>---SELECIONE---</option>
                                                    <option value="S">SIM</option>
                                                    <option value="N">NÃO</option>
                                                </select>
                                            </div>
                                        </>
                                    }     
                                </>
                        </div>
                        <br/>  
                    </>  : 
                    
                    
                    
                    
                    
                    <> 
                        {    
                            rendaItem.tipo_renda === 5 ?
                    
                                <div className="form-group row">
                                    <div className="col-xs-12 col-md-12 text-center">
                                        <p className="pRenda">
                                            <FontAwesomeIcon style={{marginRight: '5px'}}
                                                    size="2x"
                                                    icon={faTools}
                                                    color="#f19d1f"  />
                                            VOCÊ POSSUI RENDA INFORMAL?</p>
                                        <input type="radio"
                                            id="RIsim" 
                                            name={"informal" + props.props.index}
                                            value={renda_informal.value}
                                            onChange={e => {
                                                setRenda_informal(e.target.value)
                                            }}
                                            onClick={() => ExibeInformal('sim')} />
                                        <label className="spaceRenda">Sim</label>
                                        <input type="radio" 
                                            id="RInao"
                                            ref={scrollRendaI} 
                                            name={"informal" + props.props.index}
                                            value={ renda_informal.value }
                                            onChange={e => setRenda_informal(e.target.value)}
                                            onClick={() => ExibeInformal('nao')} 
                                            defaultChecked/>
                                        <label className="spaceRenda">Não</label>    
                                    </div>
                                    {
                                        exibeRendaInformal ?
                                        <>
                                        <div className="col-xs-12 col-md-12 col-lg-3"> 
                                            <label>Tipo: <span className="help-text">*</span></label>
                                            <select className="form-control custom-select"
                                                    ref={scrollRendaF}
                                                    value={rendaItem.tipo_renda}
                                                    onChange={e => {
                                                    setRenda_formal(e.target.value)
                                                    props.props.editeProponenteParametro(props.props.index, "renda", 
                                                        index, "tipo", e.target.value)}}
                                                    disabled
                                            >
                                                {
                                                    selectTipoRenda.map((item, i) => (
                                                        item.descricao !== 'INFORMAL' ? 
                                                        <></> 
                                                        : 
                                                        <option
                                                            key={i}
                                                            value={item.id}
                                                        >
                                                            { 
                                                                item.descricao
                                                            }
                                                        </option>
                                                        
                                                    ))
                                                }
                                            </select>
                                        </div>
                                            <div className="col-xs-12 col-md-12 col-lg-3">       
                                                <label >Outra Profissão / Ocupação: <span className="help-text">*</span></label>
                                                <input type="text"
                                                    id="profissaoRendaI"
                                                    className="form-control uppercase" 
                                                    placeholder=""
                                                    value={toUppercase(rendaItem.outra_profissao)}
                                                    onChange={e => {
                                                    rendaItem.outra_profissao = e.target.value
                                                    setRenda_informal(e.target.value)
                                                    props.props.editeProponenteParametro(props.props.index, "renda", 
                                                        index, "outra_profissao", toUppercase(e.target.value))}} />
                                            </div> 
                                            <div className="col-xs-12 col-md-12 col-lg-2">   
                                                <label >Início da Renda: <span className="help-text">*</span></label>
                                                <input type="date"  
                                                    max={dataAtual()}
                                                    min={validaMinData()}
                                                    className="form-control" 
                                                    placeholder="Data de ínicio da renda"
                                                    value={rendaItem.data_inicio}
                                                    onBlur={e => {
                                                        let max = dataAtual
                                                        let min = validaMinData()
                                                        if(e.target.value <= max && e.target.value >= min){
                                                            setRenda_informal(e.target.value)
                                                            props.props.editeProponenteParametro(props.props.index, 
                                                                "renda", index, "data_inicio", e.target.value)   
                                                            seterrodata('')
                                                        }else{
                                                            e.target.value = ''
                                                            setRenda_informal(e.target.value)
                                                            seterrodata('data inválida')
                                                        }
                                                    }}
                                                    onChange={e => {
                                                    setRenda_informal(e.target.value)
                                                    props.props.editeProponenteParametro(props.props.index, "renda", 
                                                        index, "data_inicio", e.target.value)}} />
                                                    <p className="help-text">
                                                        {errodata}
                                                    </p>
                                            </div> 
                                            <div className="col-xs-12 col-md-12 col-lg-2">       
                                                <label >Valor da Renda: <span className="help-text">*</span></label>
                                                <input type="text" 
                                                    className="form-control" 
                                                    placeholder="R$ 0,00"
                                                    value={realMoeda(rendaItem.valor)}
                                                    onChange={e => {
                                                        rendaItem.valor = e.target.valor
                                                        setRenda_informal(e.target.value)
                                                        let nvRenda = e.target.value.replace(/\D/g, '')
                                                        nvRenda = nvRenda.replace(/([0-9]{2})$/g, ".$1")
                                                        props.props.editeProponenteParametro(props.props.index, 
                                                            "renda", index, "valor", nvRenda)}} />
                                            </div> 
                                        </>  : <></>
                                    }
                                </div>
                                
                            :

                            <>
                                
                                <div className="form-group row">
                                    <div id="inputRadioValidation" className="col-xs-12 col-md-12 text-center">
                                        <p className="pRenda">
                                            <FontAwesomeIcon style={{marginRight: '5px'}}
                                                size="2x"
                                                icon={faUserTie}
                                                color="#f19d1f"  />
                                                RENDA FORMAL - INFORME SUA RENDA 
                                        </p>
                                        <br/>
                                    </div>
                                        <>
                                            <div className="col-xs-12 col-md-12 col-lg-3">       
                                                <label>Tipo: <span className="help-text">*</span></label>
                                                <select className="form-control custom-select"
                                                        ref={scrollRendaF}
                                                        value={rendaItem.tipo_renda}
                                                        onChange={e => { 
                                                        setTipoRendaFormal(e.target.value)
                                                        rendaItem.tipo_renda = tipoRendaFormal
                                                        tipoRendaSemRenda(e.target.value)
                                                        setRenda_formal(e.target.value)
                                                        props.props.editeProponenteParametro(props.props.index, 
                                                            "renda", index, "tipo_renda", e.target.value)}}
                                                >
                                                    {/* <option hidden >
                                                        {rendaItem.tipoRenda !== 0 ? 
                                                           rendaItem.tipo_renda :
                                                           <>Selecione</> 
                                                        }
                                                    </option> */}
                                                    {/* {
                                                        selectTipoRenda.map((item, i) => (
                                                            
                                                            item.descricao !== 'INFORMAL' ? 
                                                            <>
                                                            
                                                                {rendaItem.tipo_renda !== 0 ? 
                                                                    <option value={}>
                                                                       {item.descricao}
                                                                    </option>
                                                                    :
                                                                    <>
                                                                        <option hidden >Selecione</option> 
                                                                        <option
                                                                            key={i}
                                                                            value={item.id}>
                                                                            { 
                                                                                item.descricao
                                                                            }
                                                                        </option> 
                                                                    </> 
                                                                }
                                                                
                                                            </> :

                                                            <option hidden ></option> 
                                                            
                                                        ))
                                                    } */}
                                                    <option hidden >---SELECIONE---</option>
                                                        {
                                                            selectTipoRenda.map((item, i) => (
                                                                 
                                                                item.descricao !== 'INFORMAL' ? 
                                                                <option
                                                                    key={i}
                                                                    value={item.id}
                                                                >
                                                                    { 
                                                                        item.descricao
                                                                    }
                                                                </option> :
                                                                <option hidden ></option> 
                                                                                                                                  
                                                            ))
                                                        }
                                                </select>
                                            </div>

                                            {  retiraObrigatoriedade ? 
                                                <>
                                                    <div className="col-xs-12 col-md-12 col-lg-3">       
                                                        <label > Profissão / Cargo:</label>
                                                        <input type="text"
                                                            className="form-control uppercase" 
                                                            placeholder="Qual é a sua profissão ?"
                                                            value={toUppercase(rendaItem.cargo)}
                                                            onChange={e => {
                                                            rendaItem.cargo = e.target.value
                                                            setRenda_formal(e.target.value)
                                                            props.props.editeProponenteParametro(props.props.index, 
                                                                "renda", index, "cargo", toUppercase(e.target.value))}} />
                                                    </div> 
                                                    <div className="col-xs-12 col-md-12 col-lg-2">       
                                                        <label > Início da Renda:</label>
                                                        <input type="date"
                                                            max={dataAtual()}
                                                            min={validaMinData()} 
                                                            className="form-control" 
                                                            placeholder="Data de ínicio da renda"
                                                            value={rendaItem.data_inicio}
                                                            onBlur={e => {
                                                                let max = dataAtual
                                                                let min = validaMinData()
                                                                if(e.target.value <= max && e.target.value >= min){
                                                                    setRenda_formal(e.target.value)
                                                                    props.props.editeProponenteParametro(props.props.index, 
                                                                        "renda", index, "data_inicio", e.target.value)
                                                                    seterrodata('')   
                                                                }else{
                                                                    e.target.value = ''
                                                                    setRenda_formal(e.target.value)
                                                                    seterrodata('data inválida')
                                                                }
                                                            }}
                                                            onChange={e => {
                                                            setRenda_formal(e.target.value)
                                                            props.props.editeProponenteParametro(props.props.index, 
                                                                "renda", index, "data_inicio", e.target.value)}} />
                                                        <p className="help-text">
                                                            {errodata}
                                                        </p>
                                                    </div> 
                                                    <div className="col-xs-12 col-md-12 col-lg-2">       
                                                        <label > Valor da Renda:</label>
                                                        <input type="text" 
                                                            className="form-control" 
                                                            placeholder="R$ 0,00"
                                                            maxLength="12"
                                                            value={realMoeda(rendaItem.valor)}
                                                            onChange={e => {
                                                                rendaItem.valor = e.target.value
                                                                setRenda_formal(e.target.value)
                                                                let nvRenda = e.target.value.replace(/\D/g, '')
                                                                nvRenda = nvRenda.replace(/([0-9]{2})$/g, ".$1")
                                                                props.props.editeProponenteParametro(
                                                                    props.props.index, "renda", 
                                                                    index, "valor", nvRenda) }} />
                                                    </div>

                                                    <div className="col-xs-12 col-md-12 col-lg-2">
                                                        <label>Pretende usar FGTS? <span className="help-text">*</span></label>                   
                                                        <select className="form-control custom-select"
                                                            value={props.props.proponente.info.utilizacao_fgts}
                                                            onChange={e => {
                                                                props.props.editeProponente(
                                                                    props.props.index, "info", 
                                                                    "utilizacao_fgts", e.target.value
                                                                )
                                                            }}
                                                        >
                                                            <option hidden>---SELECIONE---</option>
                                                            <option value="S">SIM</option>
                                                            <option value="N">NÃO</option>
                                                        </select>
                                                    </div>
                                                
                                                </> : 
                                                
                                                <>
                                                    <div className="col-xs-12 col-md-12 col-lg-3">       
                                                        <label >Profissão / Cargo: <span className="help-text">*</span></label>
                                                        <input type="text"
                                                            className="form-control uppercase" 
                                                            placeholder="Qual é a sua profissão ?"
                                                            value={toUppercase(rendaItem.cargo)}
                                                            onChange={e => {
                                                            rendaItem.cargo = e.target.value
                                                            setRenda_formal(e.target.value)
                                                            props.props.editeProponenteParametro(props.props.index, 
                                                                "renda", index, "cargo", toUppercase(e.target.value))}} />
                                                    </div> 
                                                    <div className="col-xs-12 col-md-12 col-lg-2">       
                                                        <label >Início da Renda: <span className="help-text">*</span></label>
                                                        <input type="date" 
                                                            max={dataAtual()}
                                                            min={validaMinData()}
                                                            className="form-control" 
                                                            placeholder="Data de ínicio da renda"
                                                            value={rendaItem.data_inicio}
                                                            onBlur={e => {
                                                                let max = dataAtual
                                                                let min = validaMinData()
                                                                if(e.target.value <= max && e.target.value >= min){
                                                                    setRenda_formal(e.target.value)
                                                                    props.props.editeProponenteParametro(props.props.index, 
                                                                        "renda", index, "data_inicio", e.target.value)  
                                                                    seterrodata('') 
                                                                }else{
                                                                    e.target.value = ''
                                                                    setRenda_formal(e.target.value)
                                                                    seterrodata('Data inválida')
                                                                }
                                                            }}
                                                            onChange={e => {
                                                            setRenda_formal(e.target.value)
                                                            props.props.editeProponenteParametro(props.props.index, 
                                                                "renda", index, "data_inicio", e.target.value)}} />
                                                        <p className="help-text">
                                                            {errodata}
                                                        </p>
                                                    </div> 
                                                    <div className="col-xs-12 col-md-12 col-lg-2">       
                                                        <label >Valor da Renda: <span className="help-text">*</span></label>
                                                        <input type="text" 
                                                            className="form-control" 
                                                            placeholder="R$ 0,00"
                                                            maxLength="12"
                                                            minLength="0"
                                                            value={realMoeda(rendaItem.valor)}
                                                            onChange={e => {
                                                                rendaItem.valor = e.target.value
                                                                setRenda_formal(e.target.value)
                                                                let nvRenda = e.target.value.replace(/\D/g, '')
                                                                nvRenda = nvRenda.replace(/([0-9]{2})$/g, ".$1")
                                                                props.props.editeProponenteParametro(
                                                                    props.props.index, "renda", index,
                                                                    "valor", nvRenda) }} />
                                                    </div>
                                                    <div className="col-xs-12 col-md-12 col-lg-2">
                                                        <label>Pretende usar FGTS? <span className="help-text">*</span></label>                   
                                                        <select className="form-control custom-select"
                                                            value={props.props.proponente.info.utilizacao_fgts}
                                                            onChange={e=> {
                                                                props.props.editeProponente(
                                                                    props.props.index, "info", "utilizacao_fgts",
                                                                    e.target.value
                                                                )
                                                            }}>
                                                            <option hidden>---SELECIONE---</option>
                                                            <option value="S">SIM</option>
                                                            <option value="N">NÃO</option>
                                                        </select>
                                                    </div>

                                                </>
                                            }     
                                        </>
                                </div>
                                <br/>
                            </>

                        }
                    
                    </>
                ))
            }
        </>
    )
})

export default InfoRenda;