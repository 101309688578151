import React, {useState, useRef} from 'react'
import './style.css'
import DommusTabelaPaginada from '../../../../components/DommusTabelaPaginada'

export default function DescricaoVenda(){

    const thead = (
        <>
            <tr>
                <th className="text-center" colspan="7">DESCRITIVO DA VENDA</th>
            </tr>
            <tr>
                <th className="text-center" rowspan="2" colspan="1">
                    Unidade: ANGUN103
                </th>
                <th className="text-center" rowspan="2" colspan="1">
                    Total do Contrato
                </th>

                <th className="text-center" rowspan="2" colspan="1">
                    Recursos Bancários
                </th>
                <th className="text-center" rowspan="2" colspan="1">
                    Entrada - Recursos Próprios
                </th>
            </tr>
        </>
    );

    const linhas = (
        <tr>
            <td className="text-center" colspan="1">R$ 187.900,00</td>
            <td className="text-center" colspan="1">R$ 187.900,00</td>
            <td className="text-center" colspan="1">R$ 156.600,00</td>
            <td className="text-center" colspan="1">
                R$ 31.300,00
            </td>
        </tr>
    );

    return(
        <>  
        <DommusTabelaPaginada 
                linhasCabecalho={thead} optionsTabela={{}} 
                linhasCorpo={linhas} customClasses={'min-height-350'}
            />
        </>
    )
}

