import React, {useEffect, useState} from 'react'
import Anexo from './anexo.js'
import NewAnexo from './newanexo.js' 

export default function EstruturaAnexo(props){

    let item = props.item

    let id_proponente

    let obj = props.obj

    return (
      
        item.map((doc, i) => (
            doc.id_proponente != undefined ? id_proponente = doc.id_proponente : id_proponente = 0,
            doc.documentos != undefined ?
            doc.documentos.map((documentos, indice) => (
                <NewAnexo editeDocumentos={props.editeDocumentos} id_proponente={id_proponente} processo={props.processo} item={props.item} propX={obj} documentos={documentos} i={indice} />
                // <Anexo editeDocumentos={props.editeDocumentos} id_proponente={id_proponente} processo={props.processo} item={props.item} propX={obj} documentos={documentos} i={indice} />    
            ))
            : <></>          
        ))
             
    )
}