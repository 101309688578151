import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import './style.css'
import tokenPrime from '../../data/token'
import logo from '../../assets/logomarca.png'
import Step from '../steps'
import api from  '../../data/api'
import Swal from 'sweetalert2';
import {trackPromise} from 'react-promise-tracker'
import fundo from '../../assets/fundo.webp'

export default function CardInical() {
    const [exibeStep, setExibeStep] = useState(false);
    const [dadosSelect, setDadosSelect] = useState();
    let dados;
    let headers = { headers: {
        'Authorization': tokenPrime
    }}

    useEffect(() => {
        trackPromise( api.get('/dadosSelect', headers).then(response => {
           // console.log("DADOS DO SELECT", response)
            dados = response.data
        }) .catch(error => {
            Swal.fire(
                'Não foi possível carregar os selects',
                '',
                'error'
            )
        }) )
        
    }, [exibeStep])

    // useEffect(() => {
    //    debugger
    //    if( localStorage.getItem('dadosProponentes') ){
    //        ExibeStep()
    //    }
        
    // }, [])

    
    function ExibeStep(){
        
        setExibeStep(!exibeStep)

        setDadosSelect(dados)
    }

    const montaStep = () => {

        let montaStep = []
    
        montaStep.push(
            <div key={0} className="card cardStep">
                <div className="card-body">
                    <button onClick={ExibeStep} 
                            className="btn btn-small btn-danger float-right FecharStep">X
                    </button>
                    <Step preencheDadosSelect={dadosSelect} />
                </div>
            </div>
        )
        return montaStep
        
    }

    return(
        <div className="todo">      
            <img className="img-responsive logo" src={logo} alt="logomarca dommus"/>
            { exibeStep ? 
                montaStep()   : 
 
                // <div className="card cardVamosComecar">
                //     <div className="card-body bodycardVamosComecar">
                //         <h5 className="card-title h5Color text-center">Vamos iniciar sua venda?</h5>
                //         <p className="card-text text-justify">
                //             O processo de venda online é simples e rápido. 
                //             Após preenchidos os seus dados cadastrais e carregados os documentos solicitados, 
                //             em aproximadamente 30 minutos você receberá uma mensagem via SMS e via E-mail 
                //             informando os valores de financiamento aprovados junto à instituição financeira. 
                //             <br />  <br />
                //             Em seguida será necessário você informar a proposta de pagamento do valor da ENTRADA 
                //             e caso ela seja aceita, serão emitidos imediatamente o CONTRATO DE COMPRA E VENDA e o 
                //             BOLETO para pagamento da parcela de sinal. 
                //             <br /> <br />
                //             Após carregado o CONTRATO, por você assinado, a compra será efetivada e nossa 
                //             Central de Relacionamento irá entrar em contato lhe dando as boas-vindas.
                //         </p>
                //         <br/>
                //         <button onClick={ExibeStep} className="iniciar btnVamosComecar float-right">
                //             <span>
                //                 VAMOS COMEÇAR? 
                //                 <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faAngleDoubleRight} color="white">
                //                 </FontAwesomeIcon>
                //             </span> 
                //         </button>
                //     </div>
                // </div>
                <>
                    <img style={{height: '100vh', width: '75%'}} className="img-responsive" src={fundo} alt="familia feliz"/>
                    <div className="benefits-card">
                        <div className="benefits-card__text">
                            <h5 className="card-title h5Color">Vamos iniciar sua venda ?</h5>
                            <br/>
                            <p className="text-card-vamoscomecar">
                            O processo de venda online é simples e rápido. 
                            Após preenchidos os seus dados cadastrais e carregados os documentos solicitados, 
                            em aproximadamente 30 minutos você receberá uma mensagem via SMS e via E-mail 
                            informando os valores de financiamento aprovados junto à instituição financeira. 
                            <br />  <br />
                            Em seguida será necessário você informar a proposta de pagamento do valor da ENTRADA 
                            e caso ela seja aceita, serão emitidos imediatamente o CONTRATO DE COMPRA E VENDA e o 
                            BOLETO para pagamento da parcela de sinal. 
                            <br /> <br />
                            Após carregado o CONTRATO, por você assinado, a compra será efetivada e nossa 
                            Central de Relacionamento irá entrar em contato lhe dando as boas-vindas.
                            </p>
                            <br/><br/>
                        </div>
                        <div className="divbtnvamoscomecar">
                            <button onClick={ExibeStep} className="btnVamosComecar float-right">
                                <span>
                                    VAMOS COMEÇAR? 
                                    <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faAngleDoubleRight} color="white">
                                    </FontAwesomeIcon>
                                </span> 
                            </button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}