import React, {useState, useRef, forwardRef, useImperativeHandle, useEffect} from 'react'
import api from '../../data/api'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import Tipologia from '../../components/Tipologia/index'
import tokenPrime from '../../data/token'
import {trackPromise} from 'react-promise-tracker';

const SelectEmpreendimento = forwardRef((props, ref) => {

    const [uf, setUf] = useState('') //estado selecionado
    const [cidades, setCidades] = useState([])
    const [infos, setInfos] = useState([])
    const [cidade, setCidade] = useState('')
    const [empreendimento, setEmpreendimento] = useState('')
    const [empreendimentos, setEmpreendimentos] = useState([])
    const [tipo, setTipo] = useState('')

    const [erroUf, setErroUf] = useState('')
    const [erroCidade, setErroCidade] = useState('')
    const [erroEmpreendimento, setErroEmpreendimento] = useState('')
    const [erroTipo, setErroTipo] = useState('')

    const [indice, setIndice] = useState(); //cidades
    const [index, setIndex] = useState(); //empreendimentos
    const [dados, setDados] = useState([])

    const [processos, setProcessos] = useState([])

    var tipologias;

    let headers = { headers: {
        'Authorization': tokenPrime
      }}

    useEffect(() => {
        // carregaDados()

        trackPromise(
            api.get('/listEnt', headers).then(response => {
                setDados(response.data.dados)
            // console.log(response.data)
            }) .catch(error => {
                Swal.fire(
                'Não foi possível carregar os estados',
                '',
                'error'
                )
            })
        )
            //setDados(api.dados)
        
        }, [])

    useImperativeHandle(ref, () => ({

        refValidaForm(){
            if(uf === ''){
                setErroUf('Obrigatório *')
            }else{
                setErroUf('')
                localStorage.setItem('uf', uf)
            }
    
            if(cidade === ''){
                setErroCidade('Obrigatório *')
            }else{
                setErroCidade('')
                localStorage.setItem('cidade', cidade)
            }
    
            if(empreendimento === ''){
                setErroEmpreendimento('Obrigatório *')
            }else{
                setErroEmpreendimento('')
                localStorage.setItem('empreendimento', empreendimento)
            }
    
            if(tipo === '' && infos.length >= 1){
                setErroTipo('Obrigatório *')
            }else{
                setErroTipo('')
                localStorage.setItem('tipologia', tipo)
            }

            if(uf !== '' && cidade !== '' && empreendimento !== '' && tipo !== ''){
                return true
            }else{
                return false
            }
        }  
    }));

    const carregaCidades = () => {
        let auxCidade = []
        
        auxCidade.push(
            <option key={""} hidden>---SELECIONE---</option>
        )
        
        cidades.map( (city, i) => auxCidade.push(
            <option 
                key={i}
                value={i}
                >
                {city.cidade}
            </option>
        ))

        return auxCidade
    } 

    const tipoCheckout = (v) => {
        let vrl = v+1;
        //console.log('teste: '+ v)
        localStorage.setItem('tipologia_index', vrl)
        setTipo(tipologias[v])
    }
    const carregaTipologias = (emp, i) => {
      let tipo = []
      let v = parseInt(i)
      let vlr = v+1;

      localStorage.setItem('empreendimento_index', vlr)
  
      tipologias = emp
  
      emp.map((item, index) => 
        tipo.push(
          <Tipologia
              key={index}
              emp={emp}  
              tipo={item} 
              index={index}  
              quantidade="4"
              onCheck={tipoCheckout.bind(this)}
              />
        )
      )
      setInfos(tipo)
  
    }  
    
    return <>
        <div className="form-group row">
            <div className="col-xs-2 col-md-12 col-lg-2">
                <label className="col-form-label">Estado: <span className="help-text">*</span></label>
                <select 
                className="form-control custom-select"
                onChange={e =>{ 
                    setIndice(e.target.value)
                    let i = e.target.value
                    if(i !== ''){
                        setUf(dados[i].estado)
                        setCidades([])
                        setEmpreendimentos([])
                        setInfos([])
                    }else{
                        setCidades([])
                        setEmpreendimentos([])
                        setInfos([])
                    }
                }}
                >
                <option hidden >---UF---</option>
                {
                    dados.map((item, i) => (
                    <option
                        key={i}
                        value={i} >
                        {item.estado}
                    </option>
                    ))
                }
            
                </select>
                
                <p className="paragrafoErro fonteErro"> {erroUf} </p>
            </div>
            <div className="col-xs-5 col-md-12 col-lg-5">
                <label className="col-form-label">Cidade: <span className="help-text">*</span></label>
                <select 
                className="form-control custom-select"
                // DafaultValue={cidade}
                onFocus={e => {
                    if(indice !== undefined)
                        setCidades(dados[indice].itens)
                }}
                onChange={e => {
                    setIndex(e.target.value)
                    let i = e.target.value
                    if(i !== '---SELECIONE---'){
                    setCidade(cidades[i].cidade)
                    setEmpreendimentos([])  
                    setInfos([])
                    }else{
                    setEmpreendimentos([])
                    setInfos([])
                    }
                }}
                >  
                { carregaCidades() }                   
                </select>
                
                <p className="paragrafoErro fonteErro"> {erroCidade} </p>
            </div>
            <div className="col-xs-5  col-md-12 col-lg-5">
                <label className="col-form-label">Empreendimento: <span className="help-text">*</span></label>
                <select 
                    className="form-control custom-select"
                    // value={empreendimento}
                    onFocus={e => 
                    {if(index !== undefined)
                        setEmpreendimentos(cidades[index].itens)}
                    }
                    onChange={e => {
                    let i = e.target.value
                    if(i !== '---SELECIONE---'){
                        let emp = empreendimentos[i].itens
                        setEmpreendimento(empreendimentos[i].empreendimento)
                        setInfos(empreendimentos[i].itens)
                        carregaTipologias(emp, i)  
                    }else{
                        setEmpreendimentos([])
                        setInfos([])
                    }
                    }}
                >
                <option key={""} hidden>---SELECIONE---</option>                   
                {     
                    empreendimentos.map((item, index) =>(
                    <option 
                        key={index}
                        value={index}>
                        {item.empreendimento}
                    </option>
                    ))
                }
                </select>
                <p className="paragrafoErro fonteErro">{ erroEmpreendimento }</p> 
            </div>
        </div>
        <div className="form-group row  tipologiaStyle">
            {
                empreendimento === '' || infos.length === 0 ? 

                <></> :

                infos.length === 1 ?

                <div className="card bg-light mb-4 cardInfosTipos1">
                    <div className="card-header text-center infosTipos">
                    Unidade de Interesse:
                    </div>
                    <div className="card-body cardInfosTiposBody">  
                    <p className="card-title cardInfosTiposTitle">
                        Qual o tipo de unidade que você deseja?
                    </p>
                    <FontAwesomeIcon 
                        className="iconIfonsTipos" 
                        icon={faAngleDoubleRight} 
                        size='3x'
                        color='#ff7600'>
                    </FontAwesomeIcon>
                    
                    <p className="card-text cardInfosTiposText">
                        (Ex.): Apartamento padrão (TIPO), Apartamento com Área Privativa (GARDEN), 
                        Casa PNE (para portadores de necessidades especiais), etc.
                    </p>                             
                    </div>
                </div> :

                infos.length === 2 ?

                <div className="card bg-light mb-4 cardInfosTipos2">
                    <div className="card-header text-center infosTipos">
                    Unidade de Interesse:
                    </div>
                    <div className="card-body cardInfosTiposBody">  
                    <p className="card-title cardInfosTiposTitle">
                        Qual o tipo de unidade que você deseja?
                    </p>
                    <FontAwesomeIcon 
                        className="iconIfonsTipos" 
                        icon={faAngleDoubleRight} 
                        size='3x'
                        color='#ff7600'>
                    </FontAwesomeIcon>
                    
                    <p className="card-text cardInfosTiposText">
                        (Ex.): Apartamento padrão (TIPO), Apartamento com Área Privativa (GARDEN), 
                        Casa PNE (para portadores de necessidades especiais), etc.
                    </p>                             
                    </div>
                </div> :

                <div className="card bg-light mb-4 cardInfosTipos">
                    <div className="card-header text-center infosTipos">
                    Unidade de Interesse:
                    </div>
                    <div className="card-body cardInfosTiposBody">  
                    <p className="card-title cardInfosTiposTitle">
                        Qual o tipo de unidade que você deseja?
                    </p>
                    <FontAwesomeIcon 
                        className="iconIfonsTipos" 
                        icon={faAngleDoubleRight} 
                        size='3x'
                        color='#ff7600'>
                    </FontAwesomeIcon>
                    
                    <p className="card-text cardInfosTiposText">
                        (Ex.): Apartamento padrão (TIPO), Apartamento com Área Privativa (GARDEN), 
                        Casa PNE (para portadores de necessidades especiais), etc.
                    </p>                             
                    </div>
                </div>
            
            }
            {
                infos
            } 
            <p className="paragrafoErro fonteErro"> {erroTipo} </p>
        </div>
    </>;
});

export default SelectEmpreendimento;
