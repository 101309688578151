import React, {useEffect, useState} from 'react'
import './style.css'
import EstruturaAnexo from './estruturaAnexo.js'

export default function Documentacao(props){
    return(
        <>
            <br/>
            <div className="row aprovDocs">
            {   props.processo.id !== 0 ?  
                <EstruturaAnexo editeDocumentos={props.editeDocumentos} processo={props.processo} item={props.item} obj={props.obj} /> : <></>
            }
            </div>

        </>
    )
}