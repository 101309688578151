import React, { Component, useRef, createRef } from 'react';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import tokenPrime from '../../data/token'
import Stepper from 'bs-stepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faPlus, faLevelUpAlt, faTimesCircle, faPaperPlane, faHeadset } from '@fortawesome/free-solid-svg-icons'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Proponente from '../../components/Proponente'
import Documentacao from '../../view/steps/documentacao'
import FluxoPagamento from '../../view/steps/fluxoPagamento'
import ModalDommus from '../../components/Modal/index'
import RegimeComunhaoModal from './dadosCadastrais/regimeComunhao/index'
import {trackPromise} from 'react-promise-tracker';
import Negociacao from '../steps/fluxoPagamento/negociacao'
import DescricaoVenda from '../steps/fluxoPagamento/descricaoVenda'
import Contrato from '../steps/contrato'

import ChatDommus from '../../components/ChatDommus/'

import api from '../../data/api'

import Swal from 'sweetalert2';

import './style.css';

class Step extends Component {
  
  constructor(props) {
    super();
    this.childRefDadosCadastrais = createRef();

    //this.childRefEndereco = createRef();

    this.state = {
      name: 'React',
      value: '',
      openModalRegimeComunhao: false,
      openModalChat: false,
      openModalFluxoPgto: false,

      ArrayProcesso: {
        id: 0,
        proponente1: 0,
        proponente2: 0,
        proponente3: 0,
        empreendimento: 0,
        tipo_unidade: 0,
        unidade: 0,
        evolucao: 0,
        etapa_workflow: 0,
        workflow: 0
      },

      ArrayAux: [{}],

      ArrayProponentes: [
      ],

      ArrayDocumentos: [],

    AnexosValidados : [{
      proponente: 0,
      rgCpf: false,
      certidao: false,
      residencia: false,
      ctps: false,
      rendaFormal: false,
      rendaInformal: false,
      iR: false,
      fgts: false
    }],

    erro: '',
    IndiceProxPasso: 0,
    habilitaProximoPasso: false,
    exibeRendaInformal: [],
    exibeDocumentacao: false,
    msgDocumentacao : false,
    elementoFoco: false,
    nvKey: 0,
    controlHandle: true,
    msgSwal: '',

  };
  
    //vincula a função para ser executada na classe step pois do contrario ela seria 
    //executada no componente de proponente
    this.handleSelect = this.handleSelect.bind(this);
    this.addNovoComprador = this.addNovoComprador.bind(this)
    this.editeProponente = this.editeProponente.bind(this) 
    this.editeProponenteParametro = this.editeProponenteParametro.bind(this)
    this.exibeRendaInformal = this.exibeRendaInformal.bind(this)
    this.validaRegimeComunhao = this.validaRegimeComunhao.bind(this)
    this.salvarDadosProponente = this.salvarDadosProponente.bind(this)
    this.editeDocumentos = this.editeDocumentos.bind(this)
    this.evoluirContrato = this.evoluirContrato.bind(this)

    this.validaAnexos = this.validaAnexos.bind(this)

  }

  //carregar os dados do localStorage
  componentDidMount() {
   
    let dadosProcesso = null;
    let dadosLocal = JSON.parse(localStorage.getItem('dadosProcesso')) 
    let ArrayProcesso = []
    let ArrayProponentes = []
    let AnexosValidados = []
    let empreendimentoIndex = {
      'empreendimento' : localStorage.getItem('empreendimento_index')
    }
     

    if(dadosLocal != null)
      dadosProcesso = [dadosLocal]
   
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })

   
    
    if(dadosProcesso != null){
    
      this.setState({ exibeDocumentacao: true })

      dadosProcesso.map( (itemProcesso, i) => {
        ArrayProcesso = {
          id: 0 || itemProcesso.processo.id,
          proponente1: 0 || itemProcesso.processo.proponente1,
          proponente2: 0 || itemProcesso.processo.proponente2,
          proponente3: 0 || itemProcesso.processo.proponente3,
          empreendimento: 0 || itemProcesso.processo.empreendimento,
          tipo_unidade: 0 || itemProcesso.processo.tipo_unidade,
          unidade: 0 || itemProcesso.processo.unidade,
          evolucao: 0 || itemProcesso.processo.evolucao,
          etapa_workflow: 0 || itemProcesso.processo.etapa_workflow,
          workflow: 0 || itemProcesso.processo.workflow
    
        }

        let process = {
          processo: itemProcesso.processo.id
        }

        let headers = { headers: {
          'Authorization': tokenPrime
        }}
        

        trackPromise( api.post('/documentos', process, headers).then(response => {
           //console.log("DADOS DO DOCUMENTO", response)

           this.setState({ ArrayDocumentos : response.data})
            
        }) .catch(error => {
            Swal.fire(
                'Não foi possível carregar os documentos',
                '',
                'error'
            )
        }))

      })
      
      dadosProcesso[0].proponente.map( (item, i) => {
    
        ArrayProponentes.push(
          {
              dados_cadastrais: {   
              id: 0 || item.dados_cadastrais.id,
              cpf: item.dados_cadastrais.cpf,
              nome: item.dados_cadastrais.nome,
              data_nascimento: "" || item.dados_cadastrais.data_nascimento,
              genero: "" || item.dados_cadastrais.genero,
              tipo_documento: 0 || item.dados_cadastrais.tipo_documento,
              numero_documento: "" || item.dados_cadastrais.numero_documento,
            },
            endereco: {
              id: 0 || item.endereco.id,
              numero: "" || item.endereco.numero,
              cep: "" || item.endereco.cep,
              tipo_logradouro: 0 || item.endereco.tipo_logradouro,
              logradouro: "" || item.endereco.logradouro,
              complemento: "" || item.endereco.complemento,
              bairro: "" || item.endereco.bairro,
              cidade: "" || item.endereco.cidade,
              uf: "" || item.endereco.uf,
            },

            info: {
              id: 0 || item.info.id,
              proponente: 0 || item.info.proponente,
              grau_instrucao: 0 || item.info.grau_instrucao,
              utilizacao_fgts: "" || item.info.utilizacao_fgts
            },

            conjuge: {
              id: 0 || item.conjuge.id,
              proponente1: 0 || item.conjuge.proponente1,
              proponente2: 0 || item.conjuge.proponente2,
              estado_civil: 0 || item.conjuge.estado_civil,
              regime_comunhao: 0 || item.conjuge.regime_comunhao,
            },

            contato: [{
              id: 0 || item.contato[0].id,
              proponente: 0 || item.contato[0].proponente,
              tipo_contato: "E" || item.contato[0].tipo_contato,
              valor: "" || item.contato[0].valor
            },
            {
              id: 0 || item.contato[1].id,
              proponente: 0 || item.contato[1].proponente,
              tipo_contato: "S" || item.contato[1].tipo_contato,
              valor: "" || item.contato[1].valor
            }],
            
            renda: [{
              id: 0 || item.renda[0].id,
              proponente: 0 || item.renda[0].proponente,
              tipo_renda: item.renda[0].tipo_renda,
              cargo: "" || item.renda[0].cargo,
              data_inicio: "" || item.renda[0].data_inicio,
              valor: "" || item.renda[0].valor
            },
            {
              id: 0 || item.renda[1].id,
              proponente: 0 || item.renda[1].proponente,
              tipo_renda: 5,
              outra_profissao: "" || item.renda[1].outra_profissao,
              data_inicio: "" || item.renda[1].data_inicio,
              valor: "" || item.renda[1].valor
            }],

            flagCpf: true
      
          }
        )
        
        /*if(item.renda !== null && item.renda !== []){

          ArrayProponentes[i].renda = item.renda
        }

        if(item.contato !== null && item.contato !== []){
          ArrayProponentes[i].contato = item.contato
        }*/
        AnexosValidados.push({
          proponente: item.dados_cadastrais.id,
          rgCpf: false,
          certidao: false,
          residencia: false,
          ctps: false,
          rendaFormal: false,
          rendaInformal: false,
          iR: false,
          fgts: false
        })
      
      })


      this.setState({ AnexosValidados })

      this.setState({ ArrayProcesso, ArrayProponentes })
      
    }else{
   
      let ArrayProcesso = {
        id: 0,
        proponente1: 0,
        proponente2: 0,
        proponente3: 0,
        empreendimento: 0 || localStorage.getItem('empreendimento_index'),
        tipo_unidade: 0 || localStorage.getItem('tipologia_index'),
        unidade: 0 ,
        evolucao: 0,
        etapa_workflow: 0,
        workflow: 0
  
      } 

      let ArrayProponentes = [{
        dados_cadastrais: {   
          id: 0,
          cpf: "" || localStorage.getItem('cpf'),
          nome: "" || localStorage.getItem('nome'),
          data_nascimento: "",
          genero: "",
          tipo_documento: 0,
          numero_documento: "",
        },
        endereco: {
          id: 0,
          numero: "",
          cep: "",
          tipo_logradouro: 0,
          logradouro: "",
          complemento: "",
          bairro: "",
          cidade: "" || localStorage.getItem('cidade'),
          uf: "" || localStorage.getItem('uf'),
        },

        info: {
          id: 0,
          proponente: 0,
          grau_instrucao: 0,
          utilizacao_fgts: ""
        },

        conjuge: {
          id: 0,
          proponente1: 0,
          proponente2: 0,
          estado_civil: 0,
          regime_comunhao: 0,
        },

        contato: [{
          id: 0,
          proponente: 0,
          tipo_contato: "E",
          valor: "" || localStorage.getItem('email')
        },
        {
          id: 0,
          proponente: 0,
          tipo_contato: "S",
          valor: "" || localStorage.getItem('celular')
        }],

        renda: [{
          id: 0,
          proponente: 0,
          tipo_renda: 0,
          cargo: "",
          data_inicio: "",
          valor: ""
        },
        {
          id: 0,
          proponente: 0,
          tipo_renda: 5,
          outra_profissao: "",
          data_inicio: "",
          valor: ""
        }], 

        flagCpf: true
      }]

      this.setState({ ArrayProcesso, ArrayProponentes })
      
    }
  
    //handle select aqui
  }
  
  // componentDidUpdate(){
  // }

  // componentWillMount(){
  //   //destruir as minhas listenner (como se fosse um observer)
  // }

  onSubmit = (e) => {
    e.preventDefault()
  }

  confereEstadoCivil(){
    let verifica;

    this.state.ArrayProponentes.map((item, i) => {
      if(item.conjuge.estado_civil === 0){
        verifica = true
      }else{
        verifica = false  
      }
    })

    if(verifica){
      return true
    }else{
      return false
    }
  }

  evoluirContrato(){
    this.stepper.next()
  }

  hasDuplicates(proponentes) {
    let cpfs = []
    for (let index = 0; index < proponentes.length; index++) {
      cpfs.push(proponentes[index].dados_cadastrais.cpf);     
    }

    return (new Set(cpfs)).size !== cpfs.length;
  }

  proximoPasso = (e) => {
    e.preventDefault()
    
    let verifica
    let verificaRegime;
    let cpfDuplicado;

    cpfDuplicado = this.hasDuplicates(this.state.ArrayProponentes)

    this.setState({msgSwal : 'Dados Inseridos com sucesso!'})

    verifica = this.validaProponente() 

      if(cpfDuplicado){
        Swal.fire(
          'Os CPFs estão duplicados, insira-os corretamente!',
          '',
          'error'
        )
      }else{

        if(verifica){
  
          verificaRegime = this.validaRegimeComunhao()         
                
          //if(verificaRegime){
            this.setState({openModalRegimeComunhao : true})
          //}
  
              
        }else{   
          this.setState({
            erro: '* Preencha todos os campos obrigatórios (*) para prosseguir.'
          })
        }
      }

  }

  enviarDocumentacao = (e) => {
    e.preventDefault()

    let headers = { headers: {
      'Authorization': tokenPrime
    }}

    let verificaProponentes = this.validaProponente()


    let passou = false;
    for (let index = 0; index < this.state.ArrayProponentes.length; index++) {
        let propX = 'prop' + Number(index+1);
        let propUpload = 'upload_prop' +  Number(index+1);

        for (let i = 0; i < this.state.ArrayDocumentos[propX].documentos.length; i++) {   
          if(this.state.ArrayDocumentos[propX].documentos[i][propUpload] == 1){
            passou = true
          }else{
            passou = false
          }
        }
      
    }

    if(passou && verificaProponentes){
      let process = {
        'processo': this.state.ArrayProcesso.id
      }

      trackPromise(api.post("/documentacao-analise", process, headers).then(response => {
          this.setState({erro: ''})
          Swal.fire({
            icon: 'success',
            title: 'Documentação enviada com sucesso!',
            showConfirmButton: false,
            timer: 1500
          }) 
          this.setState({ msgDocumentacao: true })


          //debugger
          this.processoCompleto(this.state.ArrayProcesso.id)
          //this.stepper.next()
      }).catch(error => {
          Swal.fire(
              'Falha interna no servidor, tente novamente mais tarde.',
              '',
              'error'
          )
      }))
  
    }else{
      this.setState({
        erro: '* Carregue todos os documentos para prosseguir, certifique-se que os dados cadastrais também estão todos preenchidos.'
      })
    }
  }

  validacaoCpf(cpf){
  
    if (cpf.length == 11) {
      cpf.trim();
     
      // cpf = cpf.replace(/\./g, '');
      // cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      let aux = false;
      
      for (var i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
              aux = true;   
          }
      } 
      
      if (aux == false) {
          return false; 
      } 
      
      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p; 
      } 
      
      v1 = ((v1 * 10) % 11);
      
      if (v1 == 10) {
          v1 = 0; 
      }
      
      if (v1 != cpf[9]) {
          return false; 
      } 
      
      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p; 
      } 
      
      v2 = ((v2 * 10) % 11);
      
      if (v2 == 10) {
          v2 = 0; 
      }
      
      if (v2 != cpf[10]) {
          return false; 
      } else {   
          return true; 
      }
    }else {
      return false;
    }

  }

  validaRegimeComunhao = () => {
  
    let proponente = this.state.ArrayProponentes
    let contEstadoCivil2 = 0;
    let contEstadoCivil3 = 0;
    let contRegimeComunhao1 = 0;
    let contRegimeComunhao2 = 0;
    let contRegimeComunhao3 = 0;
    let contRegimeComunhao4 = 0;

    let estadocivil = []
    let regimecomunhao = []

    proponente.map((item, index) => {
        if(item.conjuge.estado_civil == 0){
          return false
        }
        if( item.conjuge.estado_civil == 2)
          contEstadoCivil2 ++
        if( item.conjuge.estado_civil == 3 )
          contEstadoCivil3 ++

        if(item.conjuge.regime_comunhao == 1){
          contRegimeComunhao1 ++
        }

        if(item.conjuge.regime_comunhao == 2){
          contRegimeComunhao2 ++
        }

        if(item.conjuge.regime_comunhao == 3){
          contRegimeComunhao3 ++
        }
        if(item.conjuge.regime_comunhao == 4){
          contRegimeComunhao4 ++
        }

        estadocivil.push(item.conjuge.estado_civil)

        regimecomunhao.push(item.conjuge.regime_comunhao)
    })
   
    if( contEstadoCivil2 % 2 == 0 && contEstadoCivil3 % 2 == 0 && contRegimeComunhao1 % 2 == 0 &&
      contRegimeComunhao2 % 2 == 0 && contRegimeComunhao3 % 2 == 0 && contRegimeComunhao4 % 2 == 0
    ){     
      return true
    }else{
      return false
    }

    // props.proponentes.map((item, i) => {
    //     if(item.conjuge.estado_civil === 2 || item.conjuge.estado_civil === 3){
    //         if( item.conjuge.regime_comunhao == 1){
    //             contRegimeComunhao ++
    //         }else{

    //             contRegimeComunhao = 0
    //         }
    //         if( item.conjuge.regime_comunhao == 2){
    //             contRegimeComunhao ++
    //         }else{

    //             contRegimeComunhao = 0
    //         }

    //         if( item.conjuge.regime_comunhao == 3){
    //             contRegimeComunhao ++
    //         }else{

    //             contRegimeComunhao = 0
    //         }

    //         if( item.conjuge.regime_comunhao == 4){
    //             contRegimeComunhao ++
    //         }else{

    //             contRegimeComunhao = 0
    //         }
    //     }
    // })


  }

  salvarDadosProponente(){

    let temId = this.state.ArrayProcesso.id

    this.state.ArrayProponentes.map((item, indice) => (
      
      item.flagCpf = true

    ))
    
       
    let auxArray = {                   
      "processo": this.state.ArrayProcesso,
      "proponente": this.state.ArrayProponentes
    }

    let headers = { headers: {
      'Authorization': tokenPrime
    }}

    //console.log("estou lhe enviando", auxArray)

    if (temId == 0) temId = null
    
    trackPromise(api.post("/processo-completo/request", auxArray, headers).then(response => {
        
       //console.log("Response", response)

        if(response.status === 200){
          
          if(response.data.original === undefined || response.data.erroInfo !== undefined){
            Swal.fire(
              'Falha Interna no servidor, tente novamente mais tarde',
              '',
              'error'
            )
            return false
          }
          this.state.ArrayProcesso = response.data.original.processo
    
          let arrayId = {id: 0};
          arrayId.id = this.state.ArrayProcesso.id


          let process = {
            processo: this.state.ArrayProcesso.id
          }    

          let headers = { headers: {
            'Authorization': tokenPrime
          }}

          this.setState({ArrayProponentes: response.data.original.proponente})

          api.post('/documentos', process, headers).then(res => {
           
            this.setState({ ArrayDocumentos : res.data})
              
          }) .catch(error => {
              Swal.fire(
                  'Não foi possível carregar os documentos',
                  '',
                  'error'
              )
          })
         
          api.post('/processo-completo', arrayId, headers).then(response => {
              
              const data = response.data
            
              localStorage.setItem('dadosProcesso', JSON.stringify(data))
             
          }) .catch(error => {
              Swal.fire(
                  'Não foi possível carregar os dados do processo',
                  '',
                  'error'
              )
          })
          Swal.fire({
            icon: 'success',
            title: this.state.msgSwal,
            showConfirmButton: false,
            timer: 1500
          })

          this.stepper.next()
          this.setState({erro: ''})
          // dadosProcesso.processo.map( (itemProcesso, i) => (
          //    process.processo = itemProcesso.id
          // ))
        }else{
          Swal.fire(
            'Falha ao enviar dados!',
            'Tente novamente ou entre em contato com o administrador do sistema.',
            'error'
          )
          return false
        }
      }))
      
  }

  exibeRendaFormal = (controle, index) => {

      let itens = []

      switch(controle) {
        case 'nao':
          itens[index] = false
          //this.setState({exibeRendaFormal: itens})
          this.setState({
              erro: ''
          })
          break;
        case '':
          itens[index] = false
          this.setState({
            erro: '* Preencha todos os campos obrigatórios (*) para prosseguir.'
          })
          // this.setState({
          //   exibeRendaFormal: itens
          // })
          break;
        default:
          itens[index] = true
         // this.setState({ exibeRendaFormal : itens })
          this.setState({
            erro: ''
          })
      }
  }

  exibeRendaInformal = (controle, index) => {

    let itens = []

    if(controle === 'sim'){
      itens[index] = true
      this.setState({exibeRendaInformal: itens})
    }else{
      itens[index] = false
      this.setState({exibeRendaInformal: itens })
    }
  }

  //percorre os arrays de contato, renda
  editeProponenteParametro = (indexProponente, parametro, indexParametro, campo, valor ) => {
    const arrayAuxParametro = this.state.ArrayProponentes.slice()

    arrayAuxParametro[indexProponente][parametro][indexParametro][campo] = valor

    this.setState({ArrayProponentes: arrayAuxParametro})
  }

  editeProponente = (indexProponente, parametro, key, value) =>{

    const arrayAux = this.state.ArrayProponentes.slice()
    
    arrayAux[indexProponente][parametro][key] = value
    
    this.setState({ArrayProponentes: arrayAux})

  } 

  addNovoComprador(){

    let itens = this.state.ArrayProponentes

    if(itens.length > 2){
      //return alert("Máximo de 3 compradores!")
      return Swal.fire({
        title: 'Opss ... Máximo de 3 compradores!',
        icon: 'error'
      })
    }else{
      Swal.fire({
        title: 'Deseja levar o dados de endereço do 1º comprador?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          this.addItem("levaEnderço")
        }else{
          this.addItem("")
        }
      })
    }

  }

  validaAnexos = (index, key, value) => {
      const auxAnexo = this.state.AnexosValidados.slice()
      
      auxAnexo[index][key] = value

      this.setState({ AnexosValidados: auxAnexo })
  }

  criaDocumentacao = () => {
    let documentacao = []

    if(this.state.ArrayProcesso.id !== 0){
      let obj = Object.keys(this.state.ArrayDocumentos)
    
      let arrayDocumentos = Object.entries(this.state.ArrayDocumentos)
  
      arrayDocumentos.map((item, index) => (
        documentacao.push(
          <Tab className="tabsEstiloDocumentacao"    
               key={index}
               eventKey={"documentacao" + (index)} 
               title={
                  <span> 
                    {index+1}º Comprador 
                  </span> 
                }>
              <Documentacao key={index} editeDocumentos={this.editeDocumentos} item={item} obj={index+1} processo={this.state.ArrayProcesso} index={index} />
          </Tab>
        )
      ))
    }else{
        this.state.ArrayProponentes.map((item, index) => 
        documentacao.push(
          <Tab className="tabsEstiloDocumentacao"    
              key={index}
              eventKey={"documentacao" + (index)} 
              title={
                  <span> 
                    {index+1}º Comprador 
                  </span> 
                }>
              <Documentacao key={index} editeDocumentos={this.editeDocumentos} documentos={this.state.ArrayDocumentos} processo={this.state.ArrayProcesso} index={index} />
          </Tab> 
        )
      )
    }

    return documentacao;
  }
   
  handleSelect(key) {
    // if(this.state.controlHandle){
    //   this.setState({ nvKey: key });
    // }else{
    //   return false
    // }
    this.setState({ nvKey: key });
  }


  criaProponente = () => {
    
    let proponentes = []

    this.state.ArrayProponentes.map((item, index) => 
      proponentes.push(
        <Tab className="tabsEstilo"     
            key={index}
            eventKey={index} 
            title={          
              index+1 === 1 ?
              <span> 
                <p style={{paddingBottom: '4px'}}></p>
                {index+1}º Comprador 
              </span> :
              <span> 
                {index+1}º Comprador 

                <button className="btn">
                  <FontAwesomeIcon 
                      onClick={() => this.removerProponente(index)}
                      color="#dc3545" 
                      icon={faTimesCircle} />
                </button>
              </span> 
            }>
            <Proponente proponente={item} 
                        dadosSelect={this.props}
                        index={index}
                        key={index}
                        ref={this.childRefDadosCadastrais}
                        //refEndereco={this.childRefEndereco}
                        processo={this.state.ArrayProcesso}
                        elementoFoco={this.elementoFoco} 
                        addNovoComprador={this.addNovoComprador}
                        editeProponente={this.editeProponente} 
                        editeProponenteParametro = {this.editeProponenteParametro}
                        exibeRendaInformal={this.exibeRendaInformal}/>
        </Tab> 
      )
    )
    return proponentes;
  }

  validaProponente = () => {
    
    let proponente = this.state.ArrayProponentes
    let verificacao = false
    let cpfValido

    
    proponente.map((item, index) => {
      cpfValido = this.validacaoCpf(item.dados_cadastrais.cpf)
  
      if(item.dados_cadastrais.nome === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('nome', '')

      if(item.dados_cadastrais.cpf === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('cpf', '')
      
      if(item.dados_cadastrais.data_nascimento === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('data_nascimento', '')
      
      if(item.dados_cadastrais.genero === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('genero', '')

      // if(item.conjuge.estado_civil === 0)
      //   this.childRefDadosCadastrais.current.refVericaDadosCadastrais('estado_civil', '')

      if(item.dados_cadastrais.tipo_documento === 0)
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('tipo_documento', '')

      if(item.dados_cadastrais.numero_documento === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('numero_documento', '')
      
      if(item.info.grau_instrucao === 0)
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('grau_instrucao', '')

      // if(item.conjuge.estado_civil === 2 || item.conjuge.estado_civil === 3){
      //   if(item.conjuge.regime_comunhao === 0)
      //     this.childRefDadosCadastrais.current.refVericaDadosCadastrais('regime_comunhao', '')
      // }

      if(item.contato[0].tipo_contato === 'E' && item.contato[0].valor === ''){
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('email', '')
      }else{
        if(item.contato[0].valor === '')
          this.childRefDadosCadastrais.current.refVericaDadosCadastrais('celular', '')
      }

      if(item.contato[1].tipo_contato === 'S' && item.contato[0].valor === ''){
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('celular', '')
      }else{
        if(item.contato[0].valor === '')
          this.childRefDadosCadastrais.current.refVericaDadosCadastrais('email', '')
      }

      if(item.endereco.cep === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('cep', '')

      if(item.endereco.tipo_logradouro === 0)
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('tipo_logradouro', '')

      if(item.endereco.logradouro === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('logradouro', '')

      if(item.endereco.numero === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('numero', '')
      
      if(item.endereco.bairro === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('bairro', '')

      if(item.endereco.cidade === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('cidade', '')
      
      if(item.endereco.uf === '')
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('uf', '')

      if(item.dados_cadastrais.nome === '' || item.dados_cadastrais.cpf === '' || 
        item.dados_cadastrais.data_nascimento === '' || item.dados_cadastrais.genero === '' ||
        item.dados_cadastrais.tipo_documento === 0 || item.dados_cadastrais.tipo_documento === 0){
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('dados_cadastrais', '')
      }

      if(item.endereco.cep === '' || item.endereco.numero === '' || 
      item.endereco.logradouro === '' || item.endereco.tipo_logradouro === 0 ||
        item.endereco.bairro === '' || item.endereco.cidade === '' || item.endereco.uf === ''){
        this.childRefDadosCadastrais.current.refVericaDadosCadastrais('endereco', '')
      }

      if(     cpfValido && 
              item.dados_cadastrais.nome !== '' && 
              item.dados_cadastrais.data_nascimento !== '' &&
              item.dados_cadastrais.genero !== '' &&
              item.dados_cadastrais.tipo_documento !== 0 &&
              item.dados_cadastrais.numero_documento !== '' &&
              item.info.grau_instrucao !== 0 &&
              item.endereco.cep !== '' &&
              item.endereco.cep.length >= 8 &&
              item.endereco.tipo_logradouro !== 0 &&
              item.endereco.logradouro !== '' &&
              item.endereco.numero !== '' &&
              item.endereco.bairro !== '' &&
              item.endereco.cidade !== '' &&
              item.endereco.uf !== '' &&
              item.contato[0].valor !== '' &&
              item.contato[1].valor !== ''    
        ){
          if(item.renda[0].tipo_renda !== 5){
            if(item.renda[0].tipo_renda == 6){
              verificacao = true
            }else{
  
              if(item.renda[0].cargo !== '' &&
                item.renda[0].data_inicio !== '' &&
                item.renda[0].valor !== '' &&
                item.renda[0].utilizacao_fgts !== ''){
                verificacao = true
              }else{
                verificacao = false
              }  
            }
          }else{
            if(item.renda[1].tipo_renda == 6){
              verificacao = true
            }else{
              if(item.renda[1].cargo !== '' &&
                item.renda[1].data_inicio !== '' &&
                item.renda[1].valor !== '' &&
                item.renda[1].utilizacao_fgts !== ''){
                verificacao = true
              }else{
                verificacao = false
              }  
            }
          }
          
          if (this.state.exibeRendaInformal[index]){
            let i;
            if(item.renda[0].tipo_renda === 5){
              i = 0
            }else{
              i = 1
            }

            if(item.renda[i].outra_profissao !== '' &&
              item.renda[i].data_inicio !== '' &&
              item.renda[i].valor !== ''){
              verificacao = true
            }else{
              verificacao = false
            }
          }

        }else{
          verificacao = false
        }
      }
      
      )

      if(verificacao){
        this.setState({
          exibeDocumentacao: true
        })
      }else{
        this.setState({
          exibeDocumentacao: false
        })
      }
      return verificacao
  }

  removerProponente = (index) => {

    Swal.fire({
      title: "Deseja remover esse comprador ?",
      text: 'Você não poderá reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, remover!'
    }).then((result) => {
      if (result.value) {
        let auxArray = this.state.ArrayProponentes.slice()

        let verifica = this.validaProponente()
        let verificaRegime = this.validaRegimeComunhao()

        this.setState({controlHandle: false})
            
        this.setState({msgSwal: 'Comprador Removido!'})

        for (let i = 0; i < this.state.ArrayProponentes.length; i++) {
        
          if(this.state.ArrayProponentes[index].dados_cadastrais.id !== 0){
            auxArray.splice(index, 1)
            
            this.setState({ArrayProponentes: auxArray.slice()})
            
            let keyAux = this.state.nvKey

            keyAux = Number(keyAux)

            keyAux = (keyAux) - 1;

            this.handleSelect(keyAux)

            if(verificaRegime && verifica){
              this.salvarDadosProponente()
            }

            return false
          }else{
            auxArray.splice(index, 1)
        
            this.setState({ArrayProponentes: auxArray.slice()})
            
            let keyAux = this.state.nvKey

            keyAux = Number(keyAux)

            keyAux = (keyAux) - 1;

            this.handleSelect(keyAux)

            Swal.fire(
              {
                icon: 'success',
                title: 'Comprador Removido',
                showConfirmButton: false,
                timer: 1500
              }
            )

            return false
          }
          
        }
        
      }
    })
   
  }

  // criaTabAddRemove = () => {
  //   let tab = []

  //   tab.push(
  //     <>
  //       <Tabs>
  //         <Tab title={
  //             <button className="btn btn-sm LinkTabCard" onClick={() => this.addItem("")}>
  //               <FontAwesomeIcon icon={faPlus} />
  //             </button> 
  //           }>
  //         </Tab>
  //         <Tab className="tabsEstilo" title={ 
  //             <span className="no-drop SpanAddComprador" disabled> 
  //               <FontAwesomeIcon style={{marginRight: 10}} icon={faLevelUpAlt} rotation={270} />
  //               Adicionar Comprador
  //             </span>
  //         }>
  //         </Tab>
  //       </Tabs>
  //     </>
  //   )
  //   return tab;
  // }

  addItem = (ondeVeio) => {
    
    //e.preventDefault()
    let itens = this.state.ArrayProponentes
 

    let itensAnexosV = this.state.AnexosValidados

    if(itens.length > 2){
      //return alert("Máximo de 3 compradores!")
      return Swal.fire({
        title: 'Opss ... Máximo de 3 compradores!',
        icon: 'error'
      })
    }

    // this.setState({
    //   exibeDocumentacao: false
    // })

    if(ondeVeio === ''){
      itens.push({
        dados_cadastrais: {   
          id: 0,
          cpf: "",
          nome: "",
          data_nascimento: "",
          genero: "",
          tipo_documento: 0,
          numero_documento: "",
        },
        conjuge: {
          id: 0,
          proponente1: 0,
          proponente2: 0,
          estado_civil: 0,
          regime_comunhao: 0,
        },
        endereco: {
          id: 0,
          proponente: 0,
          numero: "",
          cep: "",
          tipo_logradouro: 0,
          logradouro: "",
          complemento: "",
          bairro: "",
          cidade: "",
          uf: "",
        },
        contato: [{
            id: 0,
            proponente: 0,
            tipo_contato: 'E',
            valor: ''
          },
          {
            id: 0,
            proponente: 0,
            tipo_contato: 'S',
            valor: ''
          }
        ],
        info: {
          id: 0,
          proponente: 0,
          grau_instrucao: 0,
          utilizacao_fgts: ""
        },
        renda: [{
          id: 0,
          proponente: 0,
          tipo_renda: 0,
          cargo: "",
          outra_profissao: "",
          data_inicio: "",
          valor: ""
        },
        {
          id: 0,
          proponente: 0,
          tipo_renda: 5,
          cargo: "",
          outra_profissao: "",
          data_inicio: "",
          valor: ""
        }],
  
        flagCpf: false
  
      })
    }else{
        itens.push({
          dados_cadastrais: {   
            id: 0,
            cpf: "",
            nome: "",
            data_nascimento: "",
            genero: "",
            tipo_documento: 0,
            numero_documento: "",
          },
          conjuge: {
            id: 0,
            proponente1: 0,
            proponente2: 0,
            estado_civil: 0,
            regime_comunhao: 0,
          },
          endereco: {
            id: 0,
            proponente: 0,
            numero: itens[0].endereco.numero, 
            cep: itens[0].endereco.cep,
            tipo_logradouro: itens[0].endereco.tipo_logradouro,
            logradouro: itens[0].endereco.logradouro,
            complemento: itens[0].endereco.complemento,
            bairro: itens[0].endereco.bairro,
            cidade: itens[0].endereco.cidade,
            uf: itens[0].endereco.uf
          },
          contato: [{
              id: 0,
              proponente: 0,
              tipo_contato: 'E',
              valor: ''
            },
            {
              id: 0,
              proponente: 0,
              tipo_contato: 'S',
              valor: ''
            }
          ],
          info: {
            id: 0,
            proponente: 0,
            grau_instrucao: 0,
            utilizacao_fgts: ""
          },
          renda: [{
            id: 0,
            proponente: 0,
            tipo_renda: 0,
            cargo: "",
            outra_profissao: "",
            data_inicio: "",
            valor: ""
          },
          {
            id: 0,
            proponente: 0,
            tipo_renda: 5,
            cargo: "",
            outra_profissao: "",
            data_inicio: "",
            valor: ""
          }],
    
          flagCpf: false
    
        })
    }
    
    itensAnexosV.push({
      rgCpf: false,
      certidao: false,
      residencia: false,
      ctps: false,
      rendaFormal: false,
      rendaInformal: false,
      iR: false,
      fgts: false
    })

    //this.setState({ArrayProponentes: [ ...this.state.ArrayProponentes, itens]})

    // this.setState({ exibeRendaFormal: false })
    this.setState({ exibeRendaInformal: false })
     
    this.setState({ArrayProponentes: itens})

    this.setState({ AnexosValidados: itensAnexosV })
  
    let keyAux = this.state.nvKey

    keyAux = Number(keyAux)

    keyAux = (keyAux) + 1;

    this.handleSelect(keyAux)  

  }

  passagemStep = (a) => {
    let verifica
    verifica = this.proximoPasso()

    if(verifica){
      this.setState({erro: ''})
      return "#test-l-" + a 
    }else{
      let docId = document.getElementById('ProponenteId')
      docId.style.backgroundColor = '#9400D3'
      this.setState({ erro: '* Preencha todos os campos obrigatórios para prosseguir.' })
      this.setState({elementoFoco: true })
    }
  }

  editeDocumentos(processo){
    let process = {
      processo: processo
    }

    let headers = {
      headers: {
        'Authorization': tokenPrime
      }
    }

    trackPromise(api.post('/documentos', process, headers).then(response => {
      this.setState({ ArrayDocumentos : response.data})
       
    }) .catch(error => {
        Swal.fire(
            'Não foi possível carregar os documentos',
            '',
            'error'
        )
    }))
  }

  //retorna o processo completo 
  processoCompleto(id){
    let headers = {
      headers: {
        'Authorization': tokenPrime
      }
    }

    let arrayId = {
      id: id
    }

    api.post('/processo-completo', arrayId, headers).then(response => {
        let data = response.data
      
        localStorage.setItem('dadosProcesso', JSON.stringify(data))

        let dadosProcesso = [JSON.parse(localStorage.getItem('dadosProcesso'))]
        
        // dadosProcesso = async function dados() {

        //   return await JSON.parse(localStorage.getItem('dadosProcesso'))
        // }

        let ArrayProcesso;

        dadosProcesso.map( (itemProcesso, i) => {
          ArrayProcesso = {
            id: 0 || itemProcesso.processo.id,
            proponente1: 0 || itemProcesso.processo.proponente1,
            proponente2: 0 || itemProcesso.processo.proponente2,
            proponente3: 0 || itemProcesso.processo.proponente3,
            empreendimento: 0 || itemProcesso.processo.empreendimento,
            tipo_unidade: 0 || itemProcesso.processo.tipo_unidade,
            unidade: 0 || itemProcesso.processo.unidade,
            evolucao: 0 || itemProcesso.processo.evolucao,
            etapa_workflow: 0 || itemProcesso.processo.etapa_workflow,
            workflow: 0 || itemProcesso.processo.workflow
      
          }
        })

        this.setState({ArrayProcesso})

    }).catch(error => {
        Swal.fire(
            'Não foi possível atualizar os dados do processo acesse novamente',
            '',
            'error'
        )
    })
  }

  btnEnviarProposta(){
    this.setState({openModalFluxoPgto: true})
  }

  render() {

    return (
      <div className="stepPrincipal">
        <div id="stepper1" className="bs-stepper">
          <div className="bs-stepper-header">
            <div className="step" data-target="#test-l-1">
              <button className="step-trigger">
                <span className="bs-stepper-circle">1</span>
                <span className="bs-stepper-label">Dados Cadastrais</span>
              </button>
            </div>
            <div className="line"></div>
            { this.state.exibeDocumentacao  ? 
              <div className="step" data-target="#test-l-2" >
                <button className="step-trigger">
                  <span className="bs-stepper-circle">2</span>
                  <span className="bs-stepper-label">Documentação</span>
                </button>
              </div> :

              <div className="step" data-target="#test-l-2">
                <button className="step-trigger" disabled
                >
                  <span className="bs-stepper-circle">2</span>
                  <span className="bs-stepper-label">Documentação</span>
                </button>
              </div>
            }
            <div className="line line3"></div>
            { this.state.ArrayProcesso.evolucao >= 4 ? 

              <div className="step" data-target="#test-l-3">
                <button className="step-trigger">
                  <span className="bs-stepper-circle">3</span>
                  <span className="bs-stepper-label">Fluxo de Pagamento</span>
                </button>
              </div> :

              <div className="step" data-target="#test-l-3">
                <button className="step-trigger" disabled
                >
                  <span className="bs-stepper-circle">3</span>
                  <span className="bs-stepper-label">Fluxo de Pagamento</span>
                </button>
              </div>
            }
            <div className="line"></div>
            <div className="step" data-target="#test-l-4">
              <button className="step-trigger" disabled
              >
                <span className="bs-stepper-circle">4</span>
                <span className="bs-stepper-label">Contrato e Boleto</span>
              </button>
            </div>
          </div>
          <div className="bs-stepper-content">
            <form onSubmit={this.onSubmit}>
              <div id="test-l-1" className="content">
                <> 
                  <Tabs activeKey={this.state.nvKey} 
                        onSelect={this.handleSelect} 
                        defaultActiveKey={0} 
                        id="ProponenteId">     
                      {
                        this.criaProponente()
                      }
                      {
                         this.state.ArrayProcesso.evolucao === 0 || this.state.ArrayProcesso.evolucao === 2 ?
                            <Tab title={
                                <button className="btn btn-sm LinkTabCard" onClick={() => this.addNovoComprador()   
                                }>
                                  <FontAwesomeIcon icon={faPlus} />
                                </button> 
                              }>
                            </Tab> 
                          : <></>
                      }
                      {
                        this.state.ArrayProcesso.evolucao === 0 || this.state.ArrayProcesso.evolucao === 2 ?
                        <Tab className="tabsEstilo" title={ 
                              <span className="no-drop SpanAddComprador" disabled> 
                                <FontAwesomeIcon style={{marginRight: 10}} icon={faLevelUpAlt} rotation={270} />
                                Adicionar Comprador
                              </span>
                          }>
                        </Tab> :  <></> 
                      }
                  </Tabs>
                </>
                <br/>
                <div className="col-xs-12">
                  <p className="paragrafoErro"> {this.state.erro} 
                    {
                      this.state.ArrayProcesso.evolucao === 0 || this.state.ArrayProcesso.evolucao === 2 ?

                      <button className="btn btn-primary float-right" 
                              onClick={
                                e => {
                                // Proponente.validaInfoRenda()
                                this.proximoPasso(e)}}>
                          <span>
                              Próximo Passo
                              <FontAwesomeIcon style={{ marginLeft: 10 }} 
                                              icon={faAngleDoubleRight} 
                                              color="white">
                              </FontAwesomeIcon>    
                          </span> 
                      </button> 
                      : <></>
                    }
                  </p>
                </div>
                <ModalDommus open={this.state.openModalRegimeComunhao}
                         close={() => this.setState({openModalRegimeComunhao: false})}
                         content={
                            this.state.ArrayProponentes.map((item, i) => (
                              <RegimeComunhaoModal 
                                dadosSelect={this.props}
                                proponente={item}
                                proponentes={this.state.ArrayProponentes}
                                key={i}
                                index={i}
                                editeProponente={this.editeProponente}
                                salvarDadosProponente={this.salvarDadosProponente}
                                validaRegimeComunhao={this.validaRegimeComunhao}
                                addNovoComprador={this.addNovoComprador}
                                //props={props}
                                closeModal={() => {this.setState({openModalRegimeComunhao: false})}}
                              />
                            ))
                        }
                         titulo="Informe estado civil dos compradores:"/>
              </div>
              <div id="test-l-2" className="content">
                {
                  this.state.ArrayProcesso.evolucao === 1 ?
                  <>
                    <br/>
                    <div className="alert alert-primary" role="alert">
                      <h4 className="alert-heading text-center">Documentação em Análise</h4>
                    </div>
                    <div className="alert alert-primary" role="alert">
                      <h5 className="alert-heading text-center">Agora seus documentos serão analisados 
                      por um analisa de crédito, você será notificado por email assim que a análise for
                      concluída esse processo pode demorar em média 2 dias úteis. 
                      </h5>
                    </div>
                  </>
                  : <> 
                    {
                      this.state.ArrayProcesso.evolucao === 3 ?
                      <>
                        <br/>
                        <div className="alert alert-success" role="alert">
                          <h4 className="alert-heading text-center"> Documentação Aprovada, Aguardando Análise de Crédito</h4>
                          <p className="text-center">Tudo certo com seus dados e documentos, aguarde a análise de crédito junto a instituição financeira.</p>
                        </div>
                      </>
                      : 
                      <>
                        {
                          this.state.ArrayProcesso.evolucao === 2 ?
                          <>
                             <br/>
                            <div className="alert alert-danger" role="alert">
                              <h4 className="alert-heading text-center"> Documentação Reprovada</h4>
                              <p className="text-center">Regarregue os documentos ou altere os dados devidamente solicitados e envie novamente.</p>
                              <hr />
                              <p className="mb-0">Mensagem do Correspondente Bancário: </p>
                            </div>
                          </> :

                          this.state.ArrayProcesso.evolucao === 4 ?

                           <>
                              <br/>
                              <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading text-center"> Documentação Aprovada.</h4>
                                <p className="text-center">Processo enviado para a instituição financeira, aguarde a análise de crédito.</p>
                              </div>
                           </> : 

                           this.state.ArrayProcesso.evolucao > 4 ?

                           <>
                              <br/>
                              <div className="alert alert-success" role="alert">
                                <h4 className="alert-heading text-center"> Documentação Aprovada.</h4>
                                <p className="text-center">Acesse o Fluxo de Pagamento e/ou Contrato e Boleto.</p>
                              </div>
                           </> :
                            
                           <> <p>Informe os dados abaixo solicitados:</p> <br/> </>
                          
                        }
                      </>
                    }
                  </>
                }
                
                <Tabs defaultActiveKey="documentacao0" id="documentacaoId">
                  {
                    this.state.ArrayProcesso.evolucao === 0 || this.state.ArrayProcesso.evolucao === 2 ?
                    this.criaDocumentacao() :  <></>
                  }
                </Tabs>
                <p className="paragrafoErro"> {this.state.erro} 

                  {
                    this.state.ArrayProcesso.evolucao === 0 || this.state.ArrayProcesso.evolucao === 2 ?

                    <button className="btn btn-primary float-right proxPassoDoc" onClick={e => {
                      this.enviarDocumentacao(e)
                    }}>
                        <span>
                            Enviar Documentação 
                            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faAngleDoubleRight} color="white">
                            </FontAwesomeIcon>    
                        </span>    
                    </button> : <></>

                  }

                </p>
              </div>
              <div id="test-l-3" className="content">
                <br/>
                <FluxoPagamento evoluirContrato={this.evoluirContrato} processo={this.state.ArrayProcesso} />   
              {/*                 
                <button className="btn btn-primary float-right" onClick={() => this.stepper.next()}>
                    <span>
                        Próximo Passo
                        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faAngleDoubleRight} color="white">
                        </FontAwesomeIcon>    
                    </span>  
                </button> */}
              </div>
              <br/>
              <div id="test-l-4" className="content text-center">
                <Contrato />
              </div>
                {/* <button className="btn btn-info float-left" 
                onClick={() => this.setState({openModalChat: true})}
                >
                    <span>
                        Posso Ajudar?
                        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faHeadset} color="white">
                        </FontAwesomeIcon>    
                    </span>  
                </button> */}

                <ModalDommus open={this.state.openModalChat}
                         close={() => this.setState({openModalChat: false})}
                         content={
                          <ChatDommus id={null} />
                         }
                         //size={"lg"}
                         size={"hg"}
                         titulo="Central de Atendimento:"/>
                
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Step;
