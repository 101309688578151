import React, {useEffect, useState} from 'react'
import './style.css'
import api from '../../../../data/dadosEspelho.json'

export default function EscolhaUnidade({onCheck}){

    const [bloco, setBloco] = useState([])

    const [indiceAnterior, setIndiceAnterior] = useState()

    useEffect(() => {
        
        setBloco(api.unidades.blocos)
        
    }, [])


    function cliqueCheck(index, unidades, indicePavimento, blocos, indiceBloco) {  
    
        let unidadeSelecionada = [{
            "nomeBloco" : blocos[indiceBloco].nomeBloco, 
            "unidade" : blocos[indiceBloco].pavimentos[indicePavimento].unidades[index] 
        }]

        blocos.map((bloco, j) => {

            bloco.pavimentos.map((pav, indPav) => {

                pav.unidades.forEach(function(value, i){
                    if(value.disponibilidade == 'D'){
                        let div = document.getElementById('und' + i + 'pavimento' + indPav + 'bloco' + j);
                        div.style.backgroundColor = 'white' 
                    }else{
                        let div = document.getElementById('und' + i + 'pavimento' + indPav + 'bloco' + j);
                        div.style.backgroundColor = 'grey' 
                    }
                })
            })
        })


        // if(document.getElementById('pavimento' + indicePavimento)){
        // }
        unidades.forEach(function(value, i){
            if(value.disponibilidade == 'D'){
                let div = document.getElementById('und' + i + 'pavimento' + indicePavimento + 'bloco' + indiceBloco);
                div.style.backgroundColor = 'white' 
            }else{
                let div = document.getElementById('und' + i + 'pavimento' + indicePavimento + 'bloco' + indiceBloco);
                div.style.backgroundColor = 'grey' 
            }
            // let div = document.getElementById('und' + i + 'pavimento' + indicePavimento + 'bloco' + indiceBloco);
            // div.style.backgroundColor = 'white' 
        })
      
        // if(document.getElementById('pavimento' + indicePavimento)){
        // } 
        
        let div = document.getElementById('und' + index + 'pavimento' + indicePavimento + 'bloco' + indiceBloco);

        if (div.style.backgroundColor == 'white') {
            div.style.backgroundColor = '#28a745' 
        } else {
            div.style.backgroundColor = 'white'
        } 

        onCheck(unidadeSelecionada)
    }

    return(
        <>
            {
                bloco.map((item, i) => 
                    <div key={i} className="unidades-lista">    
                        <div className="bloco modulo-1 modulos">
                            <div className="roof"></div>
                            <div className="telhado">
                                <h3> {item.nomeBloco} 
                                    (
                                     <span className="totalbloco-1">
                                        {item.totalUnidades} unidades 
                                     </span>
                                    )
                                </h3>
                            </div>
                            <div className="nbloco-1 w-100">
                            {
                                item.pavimentos.map((pavimento, index) => 
                                    <div key={index} id={'pavimento' + index} className="pavimento">
                                        <div className="row pavFixo">
                                        
                                            <div className="col-lg-11 w-100 d-flex pavimento-wrapper justify-content-between">
                                                {
                                                    pavimento.unidades.map( (unidades, indice) =>
                                                        
                                                        unidades.disponibilidade == 'D' ?
                                                       
                                                        <div key={indice} id={'und' + indice + 'pavimento' + index + 'bloco' + i} 
                                                             onClick={() => cliqueCheck(indice, pavimento.unidades, index, bloco, i)} 
                                                             className="janela disponivel h-100"> 
                                                           {unidades.numero} 
                                                        </div> :
                                                        
                                                        <div key={indice} id={'und' + indice + 'pavimento' + index + 'bloco' + i} 
                                                             disabled
                                                             className="janela disponivel h-100"
                                                             style={{backgroundColor: 'grey', cursor: 'no-drop'}}> 
                                                           {unidades.numero} 
                                                        </div>
                                                        
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } 
                            </div>
                            <div className="portaria">
                                <div className="porta"></div>
                            </div>
                            <div className="rodape-portaria"></div>
                        </div>
                    </div>
                
                )
            }
        
        </>
    )
}

