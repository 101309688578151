import React from 'react';
import {Modal} from 'react-bootstrap';
import ModalDialog from 'react-bootstrap/ModalDialog'
import './style.css';

export default function ModalDommus({ ...props}) {

	return <Modal show={props.open} onHide={props.close} size={props.size || "lg"}
								centered
								style={{
									zIndex: '1040',
									// paddingRight: '17px',
									display: 'block',
									padding: '15px'
								}}>
		<Modal.Header closeButton>
			<Modal.Title>{props.titulo}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className={"conteudo"}>
				{props.content}
			</div>
		</Modal.Body>
	</Modal>;
}
