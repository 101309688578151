import React, {useState, useEffect} from 'react'
import './style.css'


export default function Callout(props){

    const [colloutSelect, setcolloutSelect] = useState(false)

    const [totalCollout, settotalCollout] = useState(4)


    function cliqueCheck(index) {
        
        // emp.forEach(function(value, index){
        //     let div = document.getElementById('card-' + index);
        //     div.style.backgroundColor = 'white'
        // })
        // let div = document.getElementById('card-' + index);
        // if (div.style.backgroundColor === 'white') {
        //     div.style.backgroundColor = 'grey'
        // } else {
        //     div.style.backgroundColor = 'white'
        // } 
        
        
        for (let i = 0; i < totalCollout; i++) {
            let div = document.getElementById('callout-' + i);
            div.style.borderLeftColor = 'rgb(238, 238, 238)'
        }
        let div = document.getElementById('callout-' + index);
        
        if (div.style.borderLeftColor === '' || div.style.borderLeftColor === 'rgb(238, 238, 238)') {
            div.style.borderLeftColor = 'var(--dommus-color-2)'
        } else {
            div.style.borderLeftColor = 'rgb(238, 238, 238)'
        } 
    
        //onCheck(index)
    }

    return(
        <>
            
            <div id={'callout-' + props.index} className="bs-callout col-xs-12 mt-1 p-1" 
                onClick={() => cliqueCheck(props.index)}
            >
                {
                    props.passo ?
                    <p className="passo-a-passo">{props.passo}</p> : 
                    <></>
                    // <p className="passo-a-passo">{props.index + 1}</p>
                }
                <strong style={{color: props.color || 'var(--dommus-color-2)'}}>{props.titulo}</strong>
                <div className="conteudo">{props.conteudo}</div>
                {

                    props.rodape ? 
                    <>
                        <hr/>
                        <div>
                            {props.rodape}
                        </div>
                    </>
                    : <></>
                }
                
            </div>
            
        </>
    )
}