import React, {useState, useRef} from 'react'
import './style.css'
import DommusTabelaPaginada from '../../../../components/DommusTabelaPaginada'

export default function Negociacao(){

    const thead = (
        <>
            <tr>
                <th className="text-center" colspan="1" rowspan="2">#</th>
                <th className="text-center" colspan="1" rowspan="2">TIPO PARCELA</th>
                <th className="text-center" colspan="1" rowspan="2">VENCIMENTO</th>
                <th className="text-center" colspan="2">IMÓVEL</th>
                <th className="text-center" colspan="2"
                    //data-tip="Leads Descartados"
                >DEPESAS DE TRANSFERÊNCIA
                </th>
                <th className="text-center" colspan="1" rowspan="2">TOTAL(R$)</th>
                {/* <th className="text-center" style={{width: '10%'}}>Ações</th> */}
            </tr>
            <tr>
                <th className="text-center" scope="col" colspan="1">
                    VALOR(R$)
                </th>
                <th className="text-center" scope="col" colspan="1">
                    CORREÇÃO
                </th>

                <th className="text-center" scope="col" colspan="1">
                    VALOR(R$)
                </th>
                <th className="text-center" scope="col" colspan="1">
                    CORREÇÃO
                </th>
            </tr>
        </>
    );

    const linhas = (
        <tr>
            <td className="text-center" scope="row">1</td>
            <td className="text-center" scope="row">SINAL</td>
            <td className="text-center" scope="row">28/12/2020</td>
            <td className="text-center">1.300,00</td>
            <td className="text-center" rowspan="0">
                <p>INCC até a entrega das chaves. <br/>
                Após a entrega das chaves, IGPM + 1%</p>  
            </td>
            <td className="text-center" rowspan="0">
                <p>Sem correção</p>  
            </td>
            <td className="text-center"> - </td>
            <td className="text-center">
                130.000,00
            </td>
        </tr>
    );

    return(
        <>  
        <DommusTabelaPaginada 
            linhasCabecalho={thead} optionsTabela={{}} 
                linhasCorpo={linhas} customClasses={'min-height-350'}
            />
        </>
    )
}

